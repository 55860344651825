<template>
    <div class="box">
        <div class="box-a">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="原用户名" prop="userNmae">
                    <el-input v-model="ruleForm.userNmae"></el-input>
                </el-form-item>
                <el-form-item label="原密码" prop="password">
                    <el-input type="password" maxlength="12" v-model="ruleForm.password"></el-input>
                </el-form-item>
                <el-form-item label="新用户名" prop="newUserNmae">
                    <el-input v-model="ruleForm.newUserNmae"></el-input>
                </el-form-item>
                <el-form-item label="新密码" prop="newPassword">
                    <el-input type="password" maxlength="12" v-model="ruleForm.newPassword"></el-input>
                </el-form-item>
                <el-form-item label="确认新密码" prop="qnewPassword">
                    <el-input type="password" v-model="ruleForm.qnewPassword"></el-input>
                </el-form-item>
                <el-form-item class="text">
                    <el-button type="primary" @click="submitForm('ruleForm')">确认</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        /* 判断两次输入密码是否一致 */
        var pdpassword = (rule, value, callback) => {
          if (value === '') {
            callback(new Error('请输入确认新密码'));
          } else {
              if (value != this.ruleForm.newPassword) {
                callback(new Error('两次输入的密码不一致请重新输入'));
              } else {
                callback();
              }
          }
          callback();
        };
        // 验证密码是否包含大小写数字特殊字符其中三者组合且长度在八位数以上
        var password = (rule, value, callback) => {
          if (value === '') {
            callback(new Error('请输入密码'));
          } else {
            var passwordreg = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~()-+=]+$)(?![0-9\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\W_!@#$%^&*`~()-+=]{8,12}$/
            var isValid = passwordreg.test(value);
            if(isValid != true) {
              callback(new Error('必须是大小写字母，数字，特殊字符中三种的组合组，且长度为8~12位'));
            }
            callback();
          }
        };
        // 验证密码是否包含大小写数字特殊字符其中三者组合且长度在八位数以上
        var passwords = (rule, value, callback) => {
          if (value === '') {
            callback(new Error('请输入新密码'));
          } else {
            var passwordreg = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~()-+=]+$)(?![0-9\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\W_!@#$%^&*`~()-+=]{8,12}$/
            var isValid = passwordreg.test(value);
            if(isValid != true) {
              callback(new Error('必须是大小写字母，数字，特殊字符中三种的组合组，且长度为8~12位'));
            }
            callback();
          }
        };
        return {
            ruleForm: {
                userNmae: '',
                password: '',
                newUserNmae: '',
                newPassword: '',
                qnewPassword: ''
            },
            rules: {
                userNmae: [
                    { required: true, message: '请输入原用户名', trigger: 'blur' }
                ],
                password: [
                    { required: true,message: '请输入原密码', trigger: 'blur'}
                ],
                newUserNmae: [
                    { required: true, message: '请输入新用户名', trigger: 'blur' }
                ],
                newPassword: [
                    { required: true,validator: passwords, trigger: 'blur'}
                ],
                qnewPassword: [
                    { required: true,validator: pdpassword, trigger: 'blur'}
                ]
            }
        }
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate(async(valid) => {
                if (valid) {
                    let res = await this.$http.post('/lg/updateAdmin.do', {
                        userName: this.ruleForm.newUserNmae,
                        password: this.ruleForm.newPassword,
                        oldUserName: this.ruleForm.userNmae,
                        oldPassword: this.ruleForm.password,
                    })
                    if(res.data.code == 200) {
                        this.$message({
                          type: 'success',
                          message: '修改成功！请重新登录',
                          center: true,
                          offset: 50
                        })
                        setTimeout(async() => {
                            let resa = await this.$http.post('/lg/logout.do')
                            if(resa.data.code == 200) {
                                window.localStorage.clear()
                                window.sessionStorage.clear()
                                this.$router.push({ name: 'login' })
                            }
                        }, 2000)
                    }
                } else {
                    return false;
                }
            });
        },
    }
}
</script>

<style lang="less" scoped>
.box{ 
    padding: 10px;
}
.box-a{
    width:30%;
    margin:0 auto;
    margin-top: 150px;
}
.text{
    text-align: center;
}
</style>