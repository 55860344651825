<template>
  <div class="box">
      <!-- 搜索 -->
    <div class="search">
      <el-input v-model="yhmxm" class="width" clearable placeholder="用户名/姓名"></el-input>
      <el-button type="primary" class="left" @click="list">查询</el-button>
      <el-button type="primary" @click="xzcbl = true">新增用户</el-button>
    </div>
    <div class="table">
        <!-- 列表 -->
      <el-table
        ref="multipleTable"
        :data="tableData"
        border
        v-loading="loading"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="{ color: '#1c1f26', background: '#f6f6f6' }">
        <el-table-column label="编号">
          <template slot-scope="scope">{{ scope.row.u_id }}</template>
        </el-table-column>
        <el-table-column prop="username" label="用户名"></el-table-column>
        <el-table-column prop="peoplename" label="姓名" show-overflow-tooltip></el-table-column>
        <el-table-column label="公司/物业" show-overflow-tooltip>
           <template slot-scope="scope">
               {{ scope.row.gsmc == null ? scope.row.wymc : scope.row.gsmc }}
           </template>
        </el-table-column>
        <el-table-column prop="gwmc" label="岗位" show-overflow-tooltip></el-table-column>
        <el-table-column prop="rolename" label="角色" show-overflow-tooltip></el-table-column>
        <el-table-column label="级别">
            <template slot-scope="scope">
                <span v-if="scope.row.levelNum == 1">一级</span>
                <span v-else-if="scope.row.levelNum == 2">二级</span>
                <span v-else>三级</span>
            </template>
        </el-table-column>
        <el-table-column prop="userphone" label="手机号码" show-overflow-tooltip></el-table-column>
        <el-table-column prop="addtime" label="创建时间" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="handleEdit(scope.row.levelNum, scope.row.u_id)">编辑</el-button>
              <el-popconfirm title="确定删除吗？" @confirm="dele(scope.row.u_id, scope.row.username, scope.row.levelNum)">
                <el-button class="left" size="mini" type="danger" slot="reference">删除</el-button>
              </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <div class="fy">
        <!-- 翻页 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          background
          layout="prev, pager, next"
          :total="total">
        </el-pagination>
      </div>
    </div>
    <!-- 新增信息 -->
    <el-dialog
        title="新增信息"
        :visible.sync="xzcbl"
        :close-on-click-modal="false"
        width="28%"
        center>
        <el-tabs v-model="activeName">
          <el-tab-pane label="公司" name="gs">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
              <el-form-item label="用户名" prop="name">
                <el-input class="widthd" v-model="ruleForm.name"></el-input>
              </el-form-item>
              <el-form-item label="密码" prop="mima">
                <el-input class="widthd" maxlength="12" type="password" v-model="ruleForm.mima"></el-input>
              </el-form-item>
              <el-form-item label="手机号" prop="Phone">
                <el-input class="widthd" v-model="ruleForm.Phone" maxlength="11"></el-input>
              </el-form-item>
              <el-form-item label="用户姓名" prop="yhxm">
                <el-input class="widthd" v-model="ruleForm.yhxm"></el-input>
              </el-form-item>
              <el-form-item label="所属公司" prop="ssgs">
                <el-select class="widthd" v-model="ruleForm.ssgs" placeholder="">
                  <el-option
                    v-for="item in gslist"
                    :key="item.wyId"
                    :label="item.wymc"
                    :value="item.wyId">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="角色" prop="js">
                <el-select class="widthd" v-model="ruleForm.js" placeholder="">
                  <el-option
                    v-for="item in jslist"
                    :key="item.rId"
                    :label="item.rName"
                    :value="item.rId">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button @click="xzcbl = false">取 消</el-button>
                <el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="物业" name="wy">
            <el-form :model="Form" :rules="rulesa" ref="Form" label-width="100px" class="demo-ruleForm">
              <el-form-item label="用户名" prop="name">
                <el-input class="widthd" v-model="Form.name"></el-input>
              </el-form-item>
              <el-form-item label="密码" prop="mima">
                <el-input class="widthd" maxlength="12" type="password" v-model="Form.mima"></el-input>
              </el-form-item>
              <el-form-item label="手机号" prop="Phone">
                <el-input class="widthd" v-model="Form.Phone" maxlength="11"></el-input>
              </el-form-item>
              <el-form-item label="用户姓名" prop="yhxm">
                <el-input class="widthd" v-model="Form.yhxm"></el-input>
              </el-form-item>
              <el-form-item label="所属公司" prop="ssgs">
                <el-select class="widthd" v-model="Form.ssgs" @change="wsywy" placeholder="">
                 <el-option
                   v-for="item in gslistw"
                   :key="item.wyId"
                   :label="item.wymc"
                   :value="item.wyId">
                 </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="所属物业" prop="sswy">
                <el-select class="widthd" v-model="Form.sswy" placeholder="">
                  <el-option
                    v-for="item in wyw"
                    :key="item.wyId"
                    :label="item.wymc"
                    :value="item.wyId">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="角色" prop="js">
                <el-select class="widthd" v-model="Form.js" placeholder="">
                  <el-option
                    v-for="item in jslist"
                    :key="item.rId"
                    :label="item.rName"
                    :value="item.rId">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button @click="xzcbl = false">取 消</el-button>
                <el-button type="primary" @click="submitForma()">确定</el-button>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="员工" name="yg">
            <el-form :model="Formyg" :rules="rulesb" ref="Formyg" label-width="100px" class="demo-ruleForm">
              <el-form-item label="用户名" prop="name">
                <el-input class="widthd" v-model="Formyg.name"></el-input>
              </el-form-item>
              <el-form-item label="密码" prop="mima">
                <el-input class="widthd" maxlength="12" type="password" v-model="Formyg.mima"></el-input>
              </el-form-item>
              <el-form-item label="手机号" prop="Phone">
                <el-input class="widthd" v-model="Formyg.Phone" maxlength="11"></el-input>
              </el-form-item>
              <el-form-item label="用户姓名" prop="yhxm">
                <el-input class="widthd" v-model="Formyg.yhxm"></el-input>
              </el-form-item>
              <el-form-item label="所属公司" prop="ssgs">
                <el-select class="widthd" v-model="Formyg.ssgs" @change="yggs" placeholder="">
                  <el-option
                    v-for="item in gslistw"
                    :key="item.wyId"
                    :label="item.wymc"
                    :value="item.wyId">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="所属物业" prop="sswy">
                <el-select class="widthd" v-model="Formyg.sswy" @change="ygsswy" placeholder="">
                  <el-option
                    v-for="item in ygwylist"
                    :key="item.wyId"
                    :label="item.wymc"
                    :value="item.wyId">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="所属岗位">
                <el-select class="widthd" v-model="Formyg.ssgw" placeholder="">
                  <el-option
                    v-for="item in yggwlist"
                    :key="item.gwId"
                    :label="item.gwmc"
                    :value="item.gwId">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="角色" prop="js">
                <el-select class="widthd" v-model="Formyg.js" placeholder="">
                  <el-option
                    v-for="item in jslist"
                    :key="item.rId"
                    :label="item.rName"
                    :value="item.rId">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button @click="xzcbl = false">取 消</el-button>
                <el-button type="primary" @click="submitFormb()">确定</el-button>
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </el-dialog>
      <!-- 编辑公司 -->
      <el-dialog
        title="编辑公司"
        :visible.sync="bjgs"
        width="28%"
        :close-on-click-modal="false"
        center>
        <el-form :model="bjgsfrom" :rules="bjgsrules" ref="bjgsfrom" label-width="100px" class="demo-ruleForm">
          <el-form-item label="用户名" prop="name">
            <el-input class="widthd" v-model="bjgsfrom.name"></el-input>
          </el-form-item>
          <el-form-item label="是否修改密码">
              <el-switch
                v-model="open"
                active-color="#13ce66"
                inactive-color="#ff4949">
              </el-switch>
          </el-form-item>
          <el-form-item label="密码" prop="mima" v-if="open">
            <el-input class="widthd" maxlength="12" type="password" v-model="bjgsfrom.mima"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="Phone">
            <el-input class="widthd" v-model="bjgsfrom.Phone" maxlength="11"></el-input>
          </el-form-item>
          <el-form-item label="用户姓名" prop="yhxm">
            <el-input class="widthd" v-model="bjgsfrom.yhxm"></el-input>
          </el-form-item>
          <el-form-item label="角色" prop="js">
            <el-select class="widthd" v-model="bjgsfrom.js" placeholder="">
              <el-option
                v-for="item in jslist"
                :key="item.rId"
                :label="item.rName"
                :value="item.rId">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="bjgs = false">取 消</el-button>
          <el-button type="primary" @click="gsbjgsup">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 编辑物业 -->
      <el-dialog
        title="编辑物业"
        :visible.sync="bjwy"
        width="28%"
        :close-on-click-modal="false"
        center>
        <el-form :model="bjwyfrom" :rules="bjgsrules" ref="bjwyfrom" label-width="100px" class="demo-ruleForm">
          <el-form-item label="用户名" prop="name">
            <el-input class="widthd" v-model="bjwyfrom.name"></el-input>
          </el-form-item>
          <el-form-item label="是否修改密码">
              <el-switch
                v-model="open"
                active-color="#13ce66"
                inactive-color="#ff4949">
              </el-switch>
          </el-form-item>
          <el-form-item label="密码" prop="mima" v-if="open">
            <el-input class="widthd" maxlength="12" type="password" v-model="bjwyfrom.mima"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="Phone">
            <el-input class="widthd" v-model="bjwyfrom.Phone" maxlength="11"></el-input>
          </el-form-item>
          <el-form-item label="用户姓名" prop="yhxm">
            <el-input class="widthd" v-model="bjwyfrom.yhxm"></el-input>
          </el-form-item>
          <el-form-item label="角色" prop="js">
            <el-select class="widthd" v-model="bjwyfrom.js" placeholder="">
              <el-option
                v-for="item in jslist"
                :key="item.rId"
                :label="item.rName"
                :value="item.rId">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="bjwy = false">取 消</el-button>
          <el-button type="primary" @click="wybjgsup">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 编辑员工 -->
      <el-dialog
        title="编辑员工"
        :visible.sync="bjyg"
        width="28%"
        :close-on-click-modal="false"
        center>
        <el-form :model="bjygfrom" :rules="bjgsrules" ref="bjygfrom" label-width="100px" class="demo-ruleForm">
          <el-form-item label="用户名" prop="name">
            <el-input class="widthd" v-model="bjygfrom.name"></el-input>
          </el-form-item>
          <el-form-item label="是否修改密码">
              <el-switch
                v-model="open"
                active-color="#13ce66"
                inactive-color="#ff4949">
              </el-switch>
          </el-form-item>
          <el-form-item label="密码" prop="mima" v-if="open">
            <el-input class="widthd" maxlength="12" type="password" v-model="bjygfrom.mima"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="Phone">
            <el-input class="widthd" v-model="bjygfrom.Phone" maxlength="11"></el-input>
          </el-form-item>
          <el-form-item label="用户姓名" prop="yhxm">
            <el-input class="widthd" v-model="bjygfrom.yhxm"></el-input>
          </el-form-item>
          <el-form-item label="角色" prop="js">
            <el-select class="widthd" v-model="bjygfrom.js" placeholder="">
              <el-option
                v-for="item in jslist"
                :key="item.rId"
                :label="item.rName"
                :value="item.rId">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属岗位">
            <el-select class="widthd" v-model="bjygfrom.ssgw" placeholder="">
              <el-option
                v-for="item in yggwlist"
                :key="item.gwId"
                :label="item.gwmc"
                :value="item.gwId">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="bjyg = false">取 消</el-button>
          <el-button type="primary" @click="bjygup">确 定</el-button>
        </span>
      </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    /* 手机号码验证*/
    var validateMobilePhone = (rule, value, callback) => {
        if (value === '') {
            callback(new Error('手机号不可为空'));
        } else {
            if (value !== '') { 
                var reg=/^1[3456789]\d{9}$/;
                if(!reg.test(value)){
                    callback(new Error('请输入有效的手机号码'));
                }
            }
        callback();
      }
    };
    /* 不能输入汉字*/
    var checkData = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('用户名不能为空'));
      } else {
          if (/[\u4E00-\u9FA5]/g.test(value)) {
            callback(new Error('输入框不允许输入汉字'));
          } else {
            callback();
          }
      }
      callback();
    };
    // 验证密码是否包含大小写数字特殊字符其中三者组合且长度在八位数以上
    var password = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新密码'));
      } else {
        var passwordreg = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~()-+=]+$)(?![0-9\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\W_!@#$%^&*`~()-+=]{8,12}$/
        var isValid = passwordreg.test(value);
        if(isValid != true) {
          callback(new Error('必须是大小写字母，数字，特殊字符中三种的组合组，且长度为8~12位'));
        }
        callback();
      }
    };
    return {
        bjgs: false,
        bjwy: false,
        bjyg: false,
        wyid: '',
        uid: '',
        rid: '',
        gwid: '',
        levelNum: '',
        oldUserName: '',
        open: false,
      yhmxm: null,
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      Select: [],
      activeName: 'gs',
      xzcbl: false,
      bjygfrom: {   //编辑员工表单    
        name: '',
        mima: '',
        Phone: '',
        yhxm: '',
        js: '',
        ssgw: ''
      },
      bjgsfrom: {   //编辑公司表单    
        name: '',
        mima: '',
        Phone: '',
        yhxm: '',
        js: ''
      },
      bjgsrules: {      //编辑公司表单验证
        name: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        mima: [
          { required: true, validator: password, trigger: 'blur' }
        ],
        Phone: [
          { required: true,validator: validateMobilePhone, trigger: 'blur'}
        ],
        yhxm: [
          { required: true, message: '请输入用户姓名', trigger: 'blur' }
        ],
        js: [
          { required: true, message: '请选择角色', trigger: 'blur' }
        ]
      },
      bjwyfrom: {   //编辑公司表单
        name: '',
        mima: '',
        Phone: '',
        yhxm: '',
        js: ''
      },
      ruleForm: {
        name: '',
        mima: '',
        Phone: '',
        yhxm: '',
        ssgs: '',
        js: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入公司名称', trigger: 'blur' }
        ],
        mima: [
           { required: true, validator: password, trigger: 'blur' }
        ],
        Phone: [
          { required: true,validator: validateMobilePhone, trigger: 'blur'}
        ],
        yhxm: [
          { required: true, message: '请输入用户姓名', trigger: 'blur' }
        ],
        ssgs: [
          { required: true, message: '请选择所属公司', trigger: 'blur' }
        ],
        js: [
          { required: true, message: '请选择角色', trigger: 'blur' }
        ]
      },
      Form: {
        name: '',
        mima: '',
        Phone: '',
        yhxm: '',
        ssgs: '',
        js: '',
        sswy: ''
      },
      rulesa: {
        name: [
          { required: true,validator: checkData, trigger: 'blur'}
        ],
        mima: [
           { required: true, validator: password, trigger: 'blur' }
        ],
        Phone: [
          { required: true,validator: validateMobilePhone, trigger: 'blur'}
        ],
        yhxm: [
          { required: true, message: '请输入用户姓名', trigger: 'blur' }
        ],
        ssgs: [
          { required: true, message: '请选择所属公司', trigger: 'blur' }
        ],
        js: [
          { required: true, message: '请选择角色', trigger: 'blur' }
        ],
        sswy: [
          { required: true, message: '请选择物业', trigger: 'blur' }
        ]
      },
     Formyg: {
        name: '',
        mima: '',
        Phone: '',
        yhxm: '',
        ssgs: '',
        js: '',
        sswy: '',
        ssgw: ''
     },
     rulesb: {
        name: [
          { required: true,validator: checkData, trigger: 'blur'}
        ],
        mima: [
          { required: true, validator: password, trigger: 'blur' }
        ],
        Phone: [
          { required: true, validator: validateMobilePhone, trigger: 'blur'}
        ],
        yhxm: [
          { required: true, message: '请输入用户姓名', trigger: 'blur' }
        ],
        ssgs: [
          { required: true, message: '请选择所属公司', trigger: 'blur' }
        ],
        js: [
          { required: true, message: '请选择角色', trigger: 'blur' }
        ],
        sswy: [
          { required: true, message: '请选择物业', trigger: 'blur' }
        ]
     },
    loading: false,
    gslist: [],    //未使用公司数据
    jslist: [],    //角色数据
    gslistw: [],    //已使用公司数据
    wyw: [],    //未使用的物业
    ygwylist: [],      //员工物业数据
    yggwlist: [],      //员工岗位数据
    }
},
  created() {
    this.list()
    this.js()
  },
  methods: {
    // 列表数据
    async list() {
      this.loading = true
      let res = await this.$http.post('/uc/getUser.do', {
        name: this.yhmxm == '' ? null : this.yhmxm,
        currPageNo: this.currentPage,
        pageSize: this.pageSize
      })
      if(res.data.code == 200) {
        this.tableData = res.data.data.roleInfo
        this.total = res.data.data.totalCount
      }
      this.loading = false
    },
    // qkmima() {
    //   this.bjygfrom.mima = ''
    //   this.bjgsfrom.mima = ''
    //   this.bjwyfrom.mima = ''
    // },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize
      this.list()
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage
      this.list()
    },
    // 新增物业选择公司出发物业数据
    async wsywy() {
        this.Form.sswy = ''
        let res = await this.$http.post('/wy/getRestWyInfo.do', {
            gsId: this.Form.ssgs
        })
        if(res.data.code == 200) {
            this.wyw = res.data.data.wyInfo
        }
    },
    // 新增元选择公司出发物业数据
    async yggs() {
        this.Formyg.sswy = ''
        this.Formyg.ssgw = ''
        let res = await this.$http.post('/wy/getUsedWyInfo.do', {
            gsId: this.Formyg.ssgs
        })
        if(res.data.code == 200) {
            this.ygwylist = res.data.data
        }
    },
    // 新增元选择物业出发员工数据
    async ygsswy() {
        this.Formyg.ssgw = ''
        let res = await this.$http.post('/uc/getGwInfo.do', {
            wyId: this.Formyg.sswy
        })
        if(res.data.code == 200) {
            this.yggwlist = res.data.data
        }
    },
    // 公司提交
    submitForm(formName) {
      this.$refs.ruleForm.validate(async(valid) => {
        if (valid) {
            let res = await this.$http.post('/uc/insUser.do', {
                userName: this.ruleForm.name,
                peopleName: this.ruleForm.yhxm,
                userPhone: this.ruleForm.Phone,
                password: this.ruleForm.mima,
                optUser: window.sessionStorage.getItem('userName'),
                gsId: this.ruleForm.ssgs,
                rId: this.ruleForm.js,
                levelNum: 1
            })
            if(res.data.code == 200) {
                this.$message({
                  type: 'success',
                  message: '新增成功',
                  center: true,
                  offset: 50
                })
                this.xzcbl = false
                this.list()
            }
        } else {
          return false;
        }
      })
    },
    // 物业提交
    submitForma() {
      this.$refs.Form.validate(async(valid) => {
        if (valid) {
            let res = await this.$http.post('/uc/insUser.do', {
                userName: this.Form.name,
                peopleName: this.Form.yhxm,
                userPhone: this.Form.Phone,
                password: this.Form.mima,
                optUser: window.sessionStorage.getItem('userName'),
                gsId: this.Form.ssgs,
                wyId: this.Form.sswy,
                rId: this.Form.js,
                levelNum: 2
            })
            if(res.data.code == 200) {
                this.$message({
                    type: 'success',
                    message: '新增成功',
                    center: true,
                    offset: 50
                })
                this.xzcbl = false
                this.list()
            }
        } else {
          return false;
        }
      })
    },
    // 员工提交
    submitFormb() {
      this.$refs.Formyg.validate(async(valid) => {
        if (valid) {
          let res = await this.$http.post('/uc/insUser.do', {
              userName: this.Formyg.name,
              peopleName: this.Formyg.yhxm,
              userPhone: this.Formyg.Phone,
              password: this.Formyg.mima,
              optUser: window.sessionStorage.getItem('userName'),
              gsId: this.Formyg.ssgs,
              wyId: this.Formyg.sswy,
              rId: this.Formyg.js,
              gwId: this.Formyg.ssgw == '' ? null : this.Formyg.ssgw,
              levelNum: 3
          })
          if(res.data.code == 200) {
              this.$message({
                  type: 'success',
                  message: '新增成功',
                  center: true,
                  offset: 50
              })
              this.xzcbl = false
              this.list()
          }
        } else {
          return false;
        }
      })
    },
    // 删除
    async dele(uid, username, levelNum) {
        let res = await this.$http.post('/uc/delUser.do', {
            uId: uid,
            userName: username,
            levelNum: levelNum
        })
        if(res.data.code == 200) {
            this.$message({
                type: 'success',
                message: '删除成功',
                center: true,
                offset: 50
            })
            this.list()
        }
    },
    //角色数据
    async js() {
        let resa = await this.$http.post('/role/getRoleList.do')
        if(resa.data.code == 200) {
          this.jslist = resa.data.data
        }
    },
    // 点击编辑
    async handleEdit(jibie, uid) {
        let res = await this.$http.post('/uc/getUser.do', {
          uId: uid,
          currPageNo: 1,
          pageSize: 10
        })
        this.uid = uid
        this.oldUserName = res.data.data.roleInfo[0].username
        this.levelNum = res.data.data.roleInfo[0].levelNum
        this.rid = res.data.data.roleInfo[0].rId
        this.gwid = res.data.data.roleInfo[0].gwId
        if(res.data.code == 200) {
            if(jibie == 1) {
                this.bjgsfrom.name = res.data.data.roleInfo[0].username
                this.bjgsfrom.Phone = res.data.data.roleInfo[0].userphone
                this.bjgsfrom.yhxm = res.data.data.roleInfo[0].peoplename
                this.bjgsfrom.js = res.data.data.roleInfo[0].rId
                this.bjgs = true
            }else if(jibie == 2) {
                this.bjwyfrom.name = res.data.data.roleInfo[0].username
                this.bjwyfrom.Phone = res.data.data.roleInfo[0].userphone
                this.bjwyfrom.yhxm = res.data.data.roleInfo[0].peoplename
                this.bjwyfrom.js = res.data.data.roleInfo[0].rId
                this.bjwy = true
            }else{
                if(res.data.data.roleInfo[0].wyId == null) {
                    this.wyid = ''
                }else{
                    this.wyid = res.data.data.roleInfo[0].wyId
                }
                this.bjygfrom.name = res.data.data.roleInfo[0].username
                this.bjygfrom.Phone = res.data.data.roleInfo[0].userphone
                this.bjygfrom.yhxm = res.data.data.roleInfo[0].peoplename
                this.bjygfrom.js = res.data.data.roleInfo[0].rId
                this.bjygfrom.ssgw = res.data.data.roleInfo[0].gwId
                this.bjyg = true
            }
        }
    },
    // 编辑公司提交
    gsbjgsup() {
        this.$refs.bjgsfrom.validate(async(valid) => {
            if (valid) {
                let res = await this.$http.post('/uc/updUser.do', {
                    userName: this.bjgsfrom.name,
                    peopleName: this.bjgsfrom.yhxm,
                    phone: this.bjgsfrom.Phone,
                    password: this.bjgsfrom.mima == '191919' ? null : this.bjgsfrom.mima,
                    optUser: window.sessionStorage.getItem('userName'),
                    oldUserName: this.oldUserName,
                    uId: this.uid,
                    gwId: this.gwid,
                    rId: this.rid,
                    levelNum: this.levelNum
                })
                if(res.data.code == 200) {
                    this.$message({
                        type: 'success',
                        message: '编辑成功',
                        center: true,
                        offset: 50
                    })
                    this.list()
                    this.bjgs = false
                }
            }
        })
    },
    // 编辑物业提交
    wybjgsup() {
        this.$refs.bjwyfrom.validate(async(valid) => {
            if (valid) {
                let res = await this.$http.post('/uc/updUser.do', {
                    userName: this.bjwyfrom.name,
                    peopleName: this.bjwyfrom.yhxm,
                    phone: this.bjwyfrom.Phone,
                    password: this.bjwyfrom.mima == '191919' ? null : this.bjwyfrom.mima,
                    optUser: window.sessionStorage.getItem('userName'),
                    oldUserName: this.oldUserName,
                    uId: this.uid,
                    gwId: this.gwid,
                    rId: this.rid,
                    levelNum: this.levelNum
                })
                if(res.data.code == 200) {
                    this.$message({
                        type: 'success',
                        message: '编辑成功',
                        center: true,
                        offset: 50
                    })
                    this.list()
                    this.bjwy = false
                }
            }
        })
    },
    // 编辑员工提交
    bjygup() {
        this.$refs.bjygfrom.validate(async(valid) => {
            if (valid) {
                let res = await this.$http.post('/uc/updUser.do', {
                    userName: this.bjygfrom.name,
                    peopleName: this.bjygfrom.yhxm,
                    phone: this.bjygfrom.Phone,
                    password: this.bjygfrom.mima == '191919' ? null : this.bjygfrom.mima,
                    optUser: window.sessionStorage.getItem('userName'),
                    oldUserName: this.oldUserName,
                    uId: this.uid,
                    gwId: this.gwid,
                    rId: this.rid,
                    levelNum: this.levelNum
                })
                if(res.data.code == 200) {
                    this.$message({
                        type: 'success',
                        message: '编辑成功',
                        center: true,
                        offset: 50
                    })
                    this.list()
                    this.bjyg = false
                }
            }
        })
    }
  },
  watch: {
    // 监听新增显示隐藏
    xzcbl: {
      async handler() {
        if(this.xzcbl == false) {
          this.$refs.ruleForm.resetFields()
          this.$refs.Form.resetFields()
          this.$refs.Formyg.resetFields()
          this.activeName = 'gs'
        }else{
          let res = await this.$http.post('/wy/getRestCompany.do')
          if(res.data.code == 200) {
            this.gslist = res.data.data.company
          }
          let gs = await this.$http.post('/wy/getUsedCompany.do')
          if(gs.data.code == 200) {
              this.gslistw = gs.data.data
          }
        }
      }
    },
    bjgs: {
        handler() {
            if(this.bjgs == false) {
                this.$refs.bjgsfrom.resetFields()
                this.bjgsfrom.name = ''
                this.bjgsfrom.mima = ''
                this.bjgsfrom.Phone = ''
                this.bjgsfrom.yhxm = ''
                this.bjgsfrom.js = ''
                this.open = false
            }
        }
    },
    bjwy: {
        handler() {
            if(this.bjwy == false) {
                this.$refs.bjwyfrom.resetFields()
                this.bjwyfrom.name = ''
                this.bjwyfrom.mima = ''
                this.bjwyfrom.Phone = ''
                this.bjwyfrom.yhxm = ''
                this.bjwyfrom.js = ''
                this.open = false
            }
        }
    },
    bjyg: {
        handler() {
            if(this.bjyg == false) {
                this.$refs.bjygfrom.resetFields()
                this.wyid = ''
                this.bjygfrom.name = ''
                this.bjygfrom.mima = ''
                this.bjygfrom.Phone = ''
                this.bjygfrom.yhxm = ''
                this.bjygfrom.js = ''
                this.bjygfrom.ssgw = ''
                this.open = false
            }
        }
    },
    wyid: {
        async handler() {
            if(this.wyid != '') {
                let res = await this.$http.post('/uc/getGwInfo.do', {
                    wyId: this.wyid
                })
                if(res.data.code == 200) {
                    this.yggwlist = res.data.data
                }
            }
        }
    },
    'bjgsfrom.name': {
        handler() {
            if(this.oldUserName != this.bjgsfrom.name) {
                this.bjgsfrom.mima = ''
            }
        }
    },
    'bjwyfrom.name': {
        handler() {
            if(this.oldUserName != this.bjwyfrom.name) {
                this.bjwyfrom.mima = ''
            }
        }
    },
    'bjygfrom.name': {
        handler() {
            if(this.oldUserName != this.bjygfrom.name) {
                this.bjygfrom.mima = ''
            }
        }
    },
    open: {
        handler() {
            if(this.open == false) {
                this.bjgsfrom.mima = ''
                this.bjwyfrom.mima = ''
                this.bjygfrom.mima = ''
            }
        }
    }
  }
}
</script>

<style lang="less" scoped>
.box{
  padding: 10px;
}
</style>