<template>
    <div class="box">
        <div class="search">
            <el-input v-model="sbxlh" class="widthc" clearable placeholder="设备序列号"></el-input>
            <el-input v-model="hzxlh" class="widthc left" clearable placeholder="盒子序列号"></el-input>
            <el-button type="primary" class="left" @click="list">查询</el-button>
            <!-- <el-button type="primary" class="left" @click="xzsb = true">新增设备</el-button> -->
        </div>
        <div class="table">
            <el-table
                ref="multipleTable"
                :data="tableData"
                border
                v-loading="loading"
                tooltip-effect="dark"
                style="width: 100%"
                :header-cell-style="{ color: '#1c1f26', background: '#f6f6f6' }">
                <el-table-column
                label="编号">
                <template slot-scope="scope">{{ scope.row.bfdId }}</template>
                </el-table-column>
                <el-table-column prop="bfdSbxlh" label="设备序列号"></el-table-column>
                <el-table-column prop="bfdBoxxlh" label="盒子序列号" show-overflow-tooltip></el-table-column>
                <el-table-column label="设备类型">
                    <template slot-scope="scope">
                        <span v-if="scope.row.bfdType == 1">人脸抓拍球机</span>
                        <span v-else-if="scope.row.bfdType == 2">人脸抓拍枪机</span>
                        <span v-else-if="scope.row.bfdType == 3">网络摄像机</span>
                        <span v-else-if="scope.row.bfdType == 4">车辆识别摄像机</span>
                        <span v-else-if="scope.row.bfdType == 5">wifi 探针摄像机</span>
                        <span v-else-if="scope.row.bfdType == 6">门禁设备</span>
                    </template>
                </el-table-column>
                <el-table-column label="是否在线">
                    <template slot-scope="scope">
                        <span v-if="scope.row.isOnline == 0">离线</span>
                        <span v-else-if="scope.row.isOnline == 1">在线</span>
                    </template>
                </el-table-column>
                <el-table-column label="是否已发送短信">
                    <template slot-scope="scope">
                        <span v-if="scope.row.isSendSMS == 0">未发送</span>
                        <span v-else-if="scope.row.isSendSMS == 1">已发送</span>
                    </template>
                </el-table-column>
                <el-table-column label="编辑">
                <template slot-scope="scope">
                    <el-button
                    size="mini"
                    @click="handleEdit(scope.row.bfdId)">编辑</el-button>
                    <!-- <el-popconfirm title="确定删除吗？" @confirm="dele(scope.row.bfdId)">
                      <el-button class="left" size="mini" type="danger" slot="reference">删除</el-button>
                    </el-popconfirm> -->
                </template>
                </el-table-column>
            </el-table>
      </div>
      <div class="fy">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          background
          layout="prev, pager, next"
          :total="total">
        </el-pagination>
      </div>
      <!-- 新增设备 -->
      <el-dialog
        title="新增设备"
        :visible.sync="xzsb"
        :close-on-click-modal="false"
        width="25%"
        center>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="设备序列号" prop="sbxlh">
              <el-input v-model="ruleForm.sbxlh"></el-input>
            </el-form-item>
            <el-form-item label="盒子序列号" prop="hzxlh">
                <el-select v-model="ruleForm.hzxlh" class="widthd" placeholder="">
                    <el-option
                    v-for="item in sbxlhList"
                    :key="item"
                    :label="item"
                    :value="item">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="设备名称" prop="sbmc">
              <el-input v-model="ruleForm.sbmc"></el-input>
            </el-form-item>
            <el-form-item label="设备ip地址" prop="ipdz">
              <el-input v-model="ruleForm.ipdz"></el-input>
            </el-form-item>
            <el-form-item label="设备类型" prop="sblx">
                <el-select v-model="ruleForm.sblx" class="widthd" placeholder="">
                    <el-option
                    v-for="item in sblx"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="是否在线">
                <el-radio-group v-model="ruleForm.sfzx">
                    <el-radio label="1">是</el-radio>
                    <el-radio label="0">否</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="短信是否发送">
                <el-radio-group v-model="ruleForm.sfqydx">
                    <el-radio label="1">是</el-radio>
                    <el-radio label="0">否</el-radio>
                </el-radio-group>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="xzsb = false">取 消</el-button>
          <el-button type="primary" @click="xzshebie">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 编辑设备 -->
      <el-dialog
        title="编辑设备"
        :visible.sync="bjsb"
        :close-on-click-modal="false"
        width="25%"
        center>
        <el-form :model="bjForm" :rules="bjrules" ref="bjForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="设备序列号" prop="sbxlh">
              <el-input v-model="bjForm.sbxlh"></el-input>
            </el-form-item>
            <el-form-item label="盒子序列号" prop="hzxlh">
                <el-select v-model="bjForm.hzxlh" class="widthd" placeholder="">
                    <el-option
                    v-for="item in sbxlhList"
                    :key="item"
                    :label="item"
                    :value="item">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="设备名称" prop="sbmc">
              <el-input v-model="bjForm.sbmc"></el-input>
            </el-form-item>
            <el-form-item label="设备ip地址" prop="ipdz">
              <el-input v-model="bjForm.ipdz"></el-input>
            </el-form-item>
            <el-form-item label="设备类型" prop="sblx">
                <el-select v-model="bjForm.sblx" class="widthd" placeholder="">
                    <el-option
                    v-for="item in sblx"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="是否在线">
                <el-radio-group v-model="bjForm.sfzx">
                    <el-radio label="1">是</el-radio>
                    <el-radio label="0">否</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="短信是否发送">
                <el-radio-group v-model="bjForm.sfqydx">
                    <el-radio label="1">是</el-radio>
                    <el-radio label="0">否</el-radio>
                </el-radio-group>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="bjsb = false">取 消</el-button>
          <el-button type="primary" @click="bjshebie">确 定</el-button>
        </span>
      </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        /* ip地址验证*/
        var ipdzyz = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入ip地址'));
            } else {
                if (value !== '') { 
                    var reg = /^((2[0-4]\d|25[0-5]|[01]?\d\d?)\.){3}(2[0-4]\d|25[0-5]|[01]?\d\d?)$/;
                    if(!reg.test(value)){
                        callback(new Error('请输入正确的ip地址'));
                    }
                }
            callback();
          }
        };
        return {
            loading: false,
            bjsb: false,
            sbxlhList: [],
            id: '',
            xqList: [],
            xzsb: false,
            tableData: [],
            sbxlh: '',
            hzxlh: '',
            sblx: [     //设备类型
                { id: 1, name: '人脸抓拍球机' },
                { id: 2, name: '人脸抓拍枪机' },
                { id: 3, name: '网络摄像机' },
                { id: 4, name: '车辆识别摄像机' },
                { id: 5, name: 'wifi 探针摄像机' },
                { id: 6, name: '门禁设备' },
            ],
            currentPage: 1,
            pageSize: 10,
            total: 0,
            bjForm: {
              sbxlh: '',
              hzxlh: '',
              sblx: '',
              sfzx: '1',
              sfqydx: '1',
              sbmc: '',
              ipdz: ''
            },
            bjrules: {
                sbmc: [
                { required: true, message: '请输入设备名称', trigger: 'blur' }
              ],
              ipdz: [
                { required: true,validator: ipdzyz, trigger: 'blur'}
              ],
              sbxlh: [
                { required: true, message: '请输入设备序列号', trigger: 'blur' }
              ],
              hzxlh: [
                { required: true, message: '请选择盒子序列号', trigger: 'blur' }
              ],
              sblx: [
                { required: true, message: '请选择设备类型', trigger: 'blur' }
              ]
            },
            ruleForm: {
              sbxlh: '',
              hzxlh: '',
              sblx: '',
              sfzx: '1',
              sfqydx: '1',
              sbmc: '',
              ipdz: ''
            },
            rules: {
                sbmc: [
                { required: true, message: '请输入设备名称', trigger: 'blur' }
              ],
              ipdz: [
                { required: true,validator: ipdzyz, trigger: 'blur'}
              ],
              sbxlh: [
                { required: true, message: '请输入设备序列号', trigger: 'blur' }
              ],
              hzxlh: [
                { required: true, message: '请选择盒子序列号', trigger: 'blur' }
              ],
              sblx: [
                { required: true, message: '请选择设备类型', trigger: 'blur' }
              ]
            }
        }
    },
    created() {
        this.list()
        this.sbxulh()
    },
    methods: {
        // 列表数据
        async list() {
            this.loading = true
            let res = await this.$http.post('/dev/getDeviceInfo.do', {
                bfdId: null,
                sbxlh: this.sbxlh,
                boxXlh: this.hzxlh,
                currPageNo: this.currentPage,
                pageSize: this.pageSize
            })
            if(res.data.code == 200) {
                this.tableData = res.data.data.boxInfo
                this.total = res.data.data.totalCount
            }
            this.loading = false
        },
        // 设备序列号数据
        async sbxulh() {
            let res = await this.$http.post('/dev/getBoxInfo.do')
            if(res.data.code == 200) {
                this.sbxlhList = res.data.data
            }
        },
        handleSizeChange(pageSize) {
            this.pageSize = pageSize
            this.list()
        },
        handleCurrentChange(currentPage) {
            this.currentPage = currentPage
            this.list()
        },
        // 新增设备提交
        xzshebie() {
            this.$refs.ruleForm.validate(async(valid) => {
                if (valid) {
                    let res = await this.$http.post('/dev/addDeviceInfo.do', {
                        sbXlh: this.ruleForm.sbxlh,
                        boxXlh: this.ruleForm.hzxlh,
                        type: this.ruleForm.sblx,
                        isOnline: this.ruleForm.sfzx,
                        isSendSMS: this.ruleForm.sfqydx,
                        bfdName: this.ruleForm.sbmc,
                        bfdIpAddress: this.ruleForm.ipdz
                    })
                    if(res.data.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '新增成功',
                            center: true,
                            offset: 50
                        })
                        this.list()
                        this.xzsb = false
                    }
                }
            })
        },
        // 点击编辑
        async handleEdit(id) {
            this.id = id
            this.bjsb = true
            let res = await this.$http.post('/dev/getDeviceInfo.do', {
                bfdId: id
            })
            if(res.data.code == 200) {
                this.bjForm.sbxlh = res.data.data.boxInfo[0].bfdSbxlh
                this.bjForm.hzxlh = res.data.data.boxInfo[0].bfdBoxxlh
                this.bjForm.sblx = res.data.data.boxInfo[0].bfdType
                this.bjForm.sbmc = res.data.data.boxInfo[0].sbName
                this.bjForm.ipdz = res.data.data.boxInfo[0].bfdIpAddress
                this.bjForm.sfzx = String(res.data.data.boxInfo[0].isOnline)
                this.bjForm.sfqydx = String(res.data.data.boxInfo[0].isSendSMS)
            }
        },
        // 编辑设备提交
        bjshebie() {
            this.$refs.bjForm.validate(async(valid) => {
                if (valid) {
                    let res = await this.$http.post('/dev/updDeviceInfo.do', {
                        sbxlh: this.bjForm.sbxlh,
                        boxXlh: this.bjForm.hzxlh,
                        type: this.bjForm.sblx,
                        sbName: this.bjForm.sbmc,
                        sbIp: this.bjForm.ipdz,
                        isOnline: this.bjForm.sfzx,
                        isSendSMS: this.bjForm.sfqydx,
                        bfdId: this.id
                    })
                    if(res.data.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '编辑成功',
                            center: true,
                            offset: 50
                        })
                        this.list()
                        this.bjsb = false
                    }
                }
            })
        },
        // 删除
        async dele(id) {
            let res = await this.$http.post('/dev/delDeviceInfo.do', {
                bfdId: id
            })
            if(res.data.code == 200) {
                this.$message({
                    type: 'success',
                    message: '删除成功',
                    center: true,
                    offset: 50
                })
                this.list()
            }
        }
    },
    watch: {
        xzsb: {
            handler() {
                if(this.xzsb == false) {
                    this.$refs.ruleForm.resetFields()
                    this.ruleForm.sfqydx = '1'
                    this.ruleForm.sfzx = '1'
                }
            }
        },
        bjsb: {
            handler() {
                if(this.bjsb == false) {
                    this.$refs.bjForm.resetFields()
                    this.bjForm.sfqydx = '1'
                    this.bjForm.sfzx = '1'
                }
            }
        }
    }
}
</script>

<style lang="less" scoped>
.box{
    padding: 10px;
}
</style>