<template>
    <div class="box">
        <ul class="box_ul">
            <div class="switch">
                <el-input placeholder="图片名称" class="width" v-model="Swith.name" clearable></el-input>
                <el-input placeholder="图片宽度" class="width left" v-model="Swith.width" clearable></el-input>
                <el-input placeholder="图片高度" class="width left" v-model="Swith.height" clearable></el-input>
                <el-button type="primary" icon="el-icon-search" class="left" circle @click="list"></el-button>
                <el-button type="primary" icon="el-icon-plus" class="left" circle @click="neir = true"></el-button>
            </div>
            <li v-for="(item, i) in lists" :key="i" @click="picUrl(item.id, item.image)">
                <img :src="item.image" />
                <i class="el-icon-success icon" v-if="item.id == index"></i>
            </li>
            <h3 class="top" v-if="lists.length == 0">暂无数据</h3>
        </ul>
        <el-dialog
        title="新增"
        :show-close="false"
        :visible.sync="neir"
        :close-on-click-modal="false"
        width="30%">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm">
                <el-form-item label="图片名称" prop="name">
                    <el-input v-model="ruleForm.name"></el-input>
                </el-form-item>
                <el-form-item label="图片" prop="pic">
                    <el-upload
                        action=""
                        ref="pic"
                        :auto-upload="false"
                        v-model="ruleForm.pic"
                        list-type="picture-card"
                        :limit="1"
                        :on-change="getFile"
                        :before-remove="remove">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="neir = false">取 消</el-button>
                <el-button type="primary" @click="update">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'publicpic',
    data() {
        return {
            Swith: {
                name: '',
                width: '',
                height: ''
            },
            ruleForm: {
                name: '',
                pic: ''
            },
            rules: {
                name: [{ required: true, message: '请输入设备名称', trigger: 'blur' }],
                pic: [{ required: true, message: '请上传图片', trigger: 'change' }]
            },
            lists: [],
            index: null,
            width: '',
            height: '',
            neir: false
        }
    },
    props: {
        propC: {        //选中的图片
            type: null,
            required: false
        }
    },
    async created() {
        let res = await this.$http.post('/picLibrary/showPicLibrary', { name: null, width: null, height: null })
        if(res.data.code == 200) {
            this.lists = res.data.data
        }
    },
    methods: {
        async list() {
            let res = await this.$http.post('/picLibrary/showPicLibrary', {
                name: this.Swith.name ? this.Swith.name : null,
                width: this.Swith.width ? this.Swith.width : null,
                height: this.Swith.height ? this.Swith.height : null
            })
            if(res.data.code == 200 && res.data.data.length > 0) {
                if (this.propC) {
                    let obj = {}
                    for( let i = 0; i < res.data.data.length; i++ ) {
                        if( res.data.data[i].image == this.propC ) {
                            obj = res.data.data[i]
                            this.index = res.data.data[i].id
                            res.data.data.splice(i, 1)
                            break;
                        }
                    }
                    res.data.data.unshift(obj)
                    this.lists = res.data.data
                }else{
                    this.lists = res.data.data
                }
            }else{
                this.lists = []
            }
        },
        remove() {
            this.ruleForm.pic = ''
        },
        getBase64(file) {   // 公共方法 -> 获取图片转base64
            return new Promise(function (resolve, reject) {
                const reader = new FileReader()
                let imgResult = ''
                reader.readAsDataURL(file)
                reader.onload = function () {
                    imgResult = reader.result
                }
                reader.onerror = function (error) {
                    reject(error)
                }
                reader.onloadend = function () {
                    resolve(imgResult)
                }
            })
        },
        getFile(file) {
            this.getBase64(file.raw).then((res) => {
                const params = res.split(',')
                this.ruleForm.pic = params[1]
                if (params.length > 0) {
                    this.strimagebase64 = params[1]
                }
            })
        },
        update() {
            this.$refs.ruleForm.validate(async (valid) => {
                if (valid) {
                    let res = await this.$http.post('/picLibrary/saveOrUpdate', {
                        name: this.ruleForm.name,
                        image: this.ruleForm.pic
                    })
                    if(res.data.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '添加成功',
                            center: true,
                            offset: 50
                        })
                        this.list()
                        this.neir = false
                    }
                } else {
                    return false;
                }
            })
        },
        picUrl(id, url) {
            this.index = id
            this.$emit("picUrl", url)
        }
    },
    watch: {
        propC: {
            immediate: true,
            handler(news, old) {
                if(news) {
                    this.list()
                    this.open = false
                }
            }
        },
        neir: {
            handler(news, old) {
                if(news == false) {
                    this.$refs.pic.clearFiles()
                    this.$refs.ruleForm.resetFields()
                    this.ruleForm.name = ''
                    this.ruleForm.pic = ''
                }
            }
        }
    }
}
</script>

<style scoped>
.box {
    width: 100%;
    height: 400px;
    overflow-y: scroll;
    overflow-x: none;
    border: 1px solid #c0c0c0;
}
.box_ul {
    width: 100%;
    height: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
}
.box_ul li{
    width: 100px;
    height: 100px;
    float: left;
    border: 1px solid #ececec;
    box-sizing: border-box;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding: 5px;
}
.box_ul li img{
    width: 100%;
}
.top {
    text-align: center;
    margin-top: 150px;
}
.icon {
    font-size: 24px;
    color: green;
    position: absolute;
    top: 0;
    right: 0;
}
.switch {
    width: 100%;
    background-color: #FFF;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.width {
    width: 200px !important;
}
.left {
    margin-left: 10px !important;
}
.el-form-item .el-form-item {
    margin-bottom: 22px;
}
</style>