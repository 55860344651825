<template>
  <div class="box">
    <div class="search">
      <el-select v-model="Switch.lx" clearable placeholder="请选择类型">
        <el-option v-for="item in cates" :key="item.value" :label="item.name" :value="item.value"></el-option>
      </el-select>
      <el-input v-model="Switch.title" class="width left" clearable placeholder="请输入内容"></el-input>
      <el-button type="primary" class="left" @click="list">查询</el-button>
      <el-button type="danger" class="left" @click="del">删除</el-button>
    </div>
    <div class="table">
      <el-table
        :data="tableData"
        v-loading="loading"
        @selection-change="handleSelectionChange"
        border>
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="id" label="序号" width="80"></el-table-column>
        <el-table-column prop="typeName" label="类别" show-overflow-tooltip></el-table-column>
        <el-table-column prop="company" label="单位名称" show-overflow-tooltip></el-table-column>
        <el-table-column prop="city" label="所在城市" show-overflow-tooltip></el-table-column>
        <el-table-column prop="name" label="联系人" show-overflow-tooltip></el-table-column>
        <el-table-column prop="phone" label="手机号" show-overflow-tooltip></el-table-column>
        <el-table-column prop="telephone" label="电话号" show-overflow-tooltip></el-table-column>
        <el-table-column prop="email" label="邮箱号" show-overflow-tooltip></el-table-column>
        <el-table-column prop="qq" label="QQ号" show-overflow-tooltip></el-table-column>
        <el-table-column prop="content" label="情况概述" show-overflow-tooltip>
          <template slot-scope="scope">
            <span @click="xq(scope.row.content)" style="cursor: pointer;">{{scope.row.content}}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="fy">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          background
          layout="prev, pager, next"
          :total="total">
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="回复内容"
      :visible.sync="neir"
      :close-on-click-modal="false"
      width="30%">
      <span>{{centent}}</span>
    </el-dialog>
    <el-dialog
      title="情况概述"
      :visible.sync="qkgs"
      :close-on-click-modal="false"
      width="30%"
      center>
      <span>{{content}}</span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      Switch: {
        title: '',
        lx: ''
      },
      neir: false,
      centent: '',
      Select: [],
      loading: false,
      tableData: [],
      currentPage: 1,
      total: 0,
      cates: [],
      qkgs: false,
      content: ''
    }
  },
  created() {
    this.list()
    this.cate()
  },
  methods: {
    xq(content) {
      this.content = content
      this.qkgs = true
    },
    async list() {  // 列表数据
      this.loading = true
      let res = await this.$http.post('/message/getMessage', {
        content: this.Switch.title ? this.Switch.title : null,
        type: this.Switch.lx ? this.Switch.lx : null,
        current: this.currentPage,
        size: 10
      })
      this.loading = false
      if(res.data.code == 200) {
        this.tableData = res.data.data
        this.total = res.data.count
      }
    },
    async cate() {
      let res = await this.$http.post('/dict/getDictByType', {
        type: 'sys_message'
      })
      if(res.data.code == 200) {
        this.cates = res.data.data
      }
    },
    handleSelectionChange(news) {
      var arr = []
      var newData
      var newData = news.map(item => {
        return item.id += arr
      })
      arr = newData;
      this.Select = arr;
    },
    open(centent) {
      this.centent = centent
      this.neir = true
    },
    async del() {
      if(this.Select.length > 0) {
        this.$confirm('是否删除此条数据？', '提醒', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确认',
          cancelButtonText: '取消'
        }).then(async () => {
          let res = await this.$http.post('/message/delete', {
            ids: this.Select
          })
          if(res.data.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功',
              center: true,
              offset: 50
            })
            this.list()
          }
        })
        .catch(action => {
          return
        })
      } else {
        this.$message({
          type: 'warning',
          message: '至少选择一项内容',
          center: true,
          offset: 50
        })
      }
    },
    async handleEdit(id) {
      this.$prompt('请输入回复内容', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /\S/,
        inputErrorMessage: '内容不能为空'
      }).then(async ({ value }) => {
        let res = await this.$http.post('/message/update', {
          id: id,
          reply: value
        })
        if(res.data.code == 200) {
          this.$message({
            type: 'success',
            message: '回复成功',
            center: true,
            offset: 50
          })
          this.list()
        }
      }).catch(() => {
        return false;     
      })
    },
    add() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let res = await this.$http.post('/productCategory/saveOrUpdate', {
            id: this.id ? this.id : null,
            pcName: this.ruleForm.name,
            parentId: this.ruleForm.falb ? this.ruleForm.falb : null,
            outline: this.ruleForm.outline,
            sort: this.ruleForm.sort
          })
          if(res.data.code == 200) {
            this.$message({
              type: 'success',
              message: this.id ? '编辑成功' : '添加成功',
              center: true,
              offset: 50
            })
            this.list()
            this.newsadd = false;
          }
        } else {
          return false;
        }
      })
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage
      this.list()
    }
  },
  watch: {
    newsadd: {
      handler(news, old) {
        if(news == false) {
          this.$refs.ruleForm.resetFields()
          this.ruleForm.name = ''
          this.ruleForm.sort = ''
          this.ruleForm.leve = ''
          this.ruleForm.falb = ''
          this.ruleForm.outline = ''
          this.id = null
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.box{
  padding: 10px;
  background-color: #fff;
}
</style>