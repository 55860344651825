<template>
    <div class="box">
        <div class="search">
            <el-select v-model="xqbm" clearable class="width" placeholder="请选择小区">
                <el-option
                v-for="item in xqList"
                :key="item.xqbm"
                :label="item.xqmc"
                :value="item.xqbm">
                </el-option>
            </el-select>
            <el-input v-model="hzxlh" clearable class="widthc left" placeholder="盒子序列号"></el-input>
            <el-button type="primary" class="left" @click="list">查询</el-button>
            <el-button type="primary" class="left" @click="xzzdxx = true">新增终端信息</el-button>
        </div>
        <div class="table">
            <el-table
                ref="multipleTable"
                :data="tableData"
                border
                v-loading="loading"
                tooltip-effect="dark"
                style="width: 100%"
                :header-cell-style="{ color: '#1c1f26', background: '#f6f6f6' }">
                <el-table-column label="编号" show-overflow-tooltip>
                    <template slot-scope="scope">{{ scope.row.bId }}</template>
                </el-table-column>
                <el-table-column prop="axqmc" label="小区名称" show-overflow-tooltip></el-table-column>
                <el-table-column prop="bXlh" width="190" label="盒子序列号" show-overflow-tooltip></el-table-column>
                <el-table-column prop="bLs" label="路数" show-overflow-tooltip></el-table-column>
                <el-table-column label="是否启用短信功能" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-if="scope.row.isopen == 0">未启用</span>
                    <span v-else>已启用</span>
                </template>
                </el-table-column>
                <el-table-column prop="smsnum" label="剩余短信条数" show-overflow-tooltip></el-table-column>
                <el-table-column prop="smsphone" label="短信接送人手机号" show-overflow-tooltip></el-table-column>
                <el-table-column label="编辑" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-button size="mini" @click="handleEdit(scope.row.bId)">编辑</el-button>
                    <el-popconfirm title="确定删除吗？" @confirm="dele(scope.row.bId)">
                      <el-button class="left" size="mini" type="danger" slot="reference">删除</el-button>
                    </el-popconfirm>
                </template>
                </el-table-column>
            </el-table>
      </div>
      <div class="fy">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          background
          layout="prev, pager, next"
          :total="total">
        </el-pagination>
      </div>
      <!-- 新增终端信息 -->
      <el-dialog
        title="新增终端信息"
        :visible.sync="xzzdxx"
        :close-on-click-modal="false"
        width="25%"
        center>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
            <el-form-item label="盒子序列号" prop="hzxlh">
              <el-input v-model="ruleForm.hzxlh"></el-input>
            </el-form-item>
            <el-form-item label="可接入路数" prop="kjrls">
              <el-input v-model="ruleForm.kjrls"></el-input>
            </el-form-item>
            <el-form-item label="小区名称" prop="xqmc">
                <el-select v-model="ruleForm.xqmc" class="widthd" placeholder="">
                    <el-option
                    v-for="item in xqList"
                    :key="item.xqbm"
                    :label="item.xqmc"
                    :value="item.xqbm">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="是否启用短信">
                <el-radio-group v-model="ruleForm.sfqy">
                    <el-radio label="0">否</el-radio>
                    <el-radio label="1">是</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="短信接收手机号" prop="phone" v-if="ruleForm.sfqy == 1">
              <el-input v-model="ruleForm.phone" placeholder="多个手机号之间用英文逗号隔开"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="xzzdxx = false">取 消</el-button>
          <el-button type="primary" @click="xzUp">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 编辑终端信息 -->
      <el-dialog
        title="编辑终端信息"
        :visible.sync="bjzdxx"
        :close-on-click-modal="false"
        width="25%"
        center>
        <el-form :model="bjForm" :rules="bjrules" ref="bjForm" label-width="120px" class="demo-ruleForm">
            <el-form-item label="盒子序列号" prop="hzxlh">
              <el-input v-model="bjForm.hzxlh"></el-input>
            </el-form-item>
            <el-form-item label="可接入路数" prop="kjrls">
              <el-input v-model="bjForm.kjrls"></el-input>
            </el-form-item>
            <el-form-item label="小区名称" prop="xqmc">
                <el-select v-model="bjForm.xqmc" class="widthd" placeholder="">
                    <el-option
                    v-for="item in xqList"
                    :key="item.xqbm"
                    :label="item.xqmc"
                    :value="item.xqbm">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="是否启用短信">
                <el-radio-group v-model="bjForm.sfqy">
                    <el-radio label="0">否</el-radio>
                    <el-radio label="1">是</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="短信接收手机号" prop="phone" v-if="bjForm.sfqy == 1">
              <el-input v-model="bjForm.phone" placeholder="多个手机号之间用英文逗号隔开"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="bjzdxx = false">取 消</el-button>
          <el-button type="primary" @click="bjUp">确 定</el-button>
        </span>
      </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        /* 只能输入数字 */
        var validateMobilePhone = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入可接入路数'));
            } else {
                if (value !== '') { 
                    var reg = /^\d+$|^\d+[.]?\d+$/;
                    if(!reg.test(value)){
                        callback(new Error('该输入框只能数字值'));
                    }
                }
            callback();
          }
        };
        /* 多个手机号码之间只能用英文逗号隔开 */
        var sjhmyz = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入手机号码'));
            } else {
                if (value !== '') { 
                    var regs = /^1[3578][0-9]{9}(,1[3578][0-9]{9})*$/;
                    if(!regs.test(value)){
                        callback(new Error('输入的格式有误请重新输入'));
                    }
                }
            callback();
          }
        };
        return {
            bid: '',
            bjzdxx: false,
            loading: false,
            xqList: [],
            xzzdxx: false,
            tableData: [],
            options: [],
            xqbm: '',
            hzxlh: '',
            currentPage: 1,
            pageSize: 10,
            total: 0,
            bjForm: {
              hzxlh: '',
              kjrls: '',
              xqmc: '',
              sfqy: '0',
              phone: ''
            },
            bjrules: {
                phone: [
                { required: true,validator: sjhmyz, trigger: 'blur'}
              ],
              hzxlh: [
                { required: true, message: '请输入盒子序列号', trigger: 'blur' }
              ],
              kjrls: [
                  { required: true,validator: validateMobilePhone, trigger: 'blur'}
              ],
              xqmc: [
                { required: true, message: '请选择小区', trigger: 'blur' }
              ]
            },
            ruleForm: {
              hzxlh: '',
              kjrls: '',
              xqmc: '',
              sfqy: '0',
              phone: ''
            },
            rules: {
                phone: [
                { required: true,validator: sjhmyz, trigger: 'blur'}
              ],
              hzxlh: [
                { required: true, message: '请输入盒子序列号', trigger: 'blur' }
              ],
              kjrls: [
                  { required: true,validator: validateMobilePhone, trigger: 'blur'}
              ],
              xqmc: [
                { required: true, message: '请选择小区', trigger: 'blur' }
              ]
            }
        }
    },
    created() {
        this.list()  
        this.xqshuju()
    },
    methods: {
        // 列表数据
        async list() {
            this.loading = true
            let res = await this.$http.post('/bc/getBoxInfo.do', {
                xqbm: this.xqbm == '' ? null : this.xqbm,
                boxXlh: this.hzxlh == '' ? null : this.hzxlh,
                currPageNo: this.currentPage,
                pageSize: this.pageSize
            })
            if(res.data.code == 200) {
                this.tableData = res.data.data.boxInfo
                this.total = res.data.data.totalCount
            }
            this.loading = false
        },
        // 小区数据
        async xqshuju() {
            let res = await this.$http.post('/bc/getXqInfo.do')
            if(res.data.code == 200) {
                this.xqList = res.data.data
            }
        },
        handleSizeChange(pageSize) {
            this.pageSize = pageSize
            this.list()
        },
        handleCurrentChange(currentPage) {
            this.currentPage = currentPage
            this.list()
        },
        // 新增提交
        xzUp() {
            this.$refs.ruleForm.validate(async(valid) => {
                if (valid) {
                    let res = await this.$http.post('/bc/addBoxInfo.do', {
                        bXlh: this.ruleForm.hzxlh,
                        bLs: this.ruleForm.kjrls,
                        xqbm: this.ruleForm.xqmc,
                        isOpen: this.ruleForm.sfqy,
                        SMSPhone: this.ruleForm.phone == '' ? null : this.ruleForm.phone
                    })
                    if(res.data.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '新增成功',
                            center: true,
                            offset: 50
                        })
                        this.list()
                        this.xzzdxx = false
                    }
                }
            })
        },
        // 点击编辑
        async handleEdit(bid) {
            this.bid = bid
            this.bjzdxx = true
            let res = await this.$http.post('/bc/getBoxInfo.do', {
                bId: bid
            })
            if(res.data.code == 200) {
                this.bjForm.hzxlh = res.data.data.boxInfo[0].bXlh
                this.bjForm.kjrls = res.data.data.boxInfo[0].bLs
                this.bjForm.xqmc = res.data.data.boxInfo[0].xqbm
                this.bjForm.sfqy = String(res.data.data.boxInfo[0].isopen)
                if(res.data.data.boxInfo[0].smsphone != null && res.data.data.boxInfo[0].smsphone != '') {
                    this.bjForm.phone = res.data.data.boxInfo[0].smsphone
                }
            }
        },
        // 编辑提交
        bjUp() {
            this.$refs.bjForm.validate(async(valid) => {
                if (valid) {
                    let res = await this.$http.post('/bc/updBoxInfo.do', {
                        bXlh: this.bjForm.hzxlh,
                        bLs: this.bjForm.kjrls,
                        xqbm: this.bjForm.xqmc,
                        isOpen: this.bjForm.sfqy,
                        phone: this.bjForm.sfqy == '0' ? '' : this.bjForm.phone,
                        bId: this.bid
                    })
                    if(res.data.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '编辑成功',
                            center: true,
                            offset: 50
                        })
                        this.list()
                        this.bjzdxx = false
                    }
                }
            })
        },
        // 删除
        async dele(bId) {
            let res = await this.$http.post('/bc/delBoxInfo.do', {
                bId: bId
            })
            if(res.data.code == 200) {
                this.$message({
                    type: 'success',
                    message: '删除成功',
                    center: true,
                    offset: 50
                })
                this.list()
            }
        }
    },
    watch: {
        xzzdxx: {
            handler() {
                if(this.xzzdxx == false) {
                    this.$refs.ruleForm.resetFields()
                    this.ruleForm.sfqy = '0'
                }
            }
        },
        bjzdxx: {
            handler() {
                if(this.bjzdxx == false) {
                    this.$refs.bjForm.resetFields()
                    this.bjForm.sfqy = '0'
                    this.bjForm.phone = ''
                }
            }
        },
    }
}
</script>

<style lang="less" scoped>
.box{
    padding: 10px;
}
</style>