<template>
    <div class="box">
        <div class="search">
            <el-button type="primary" @click="banner = true">新增轮播图</el-button>
        </div>
        <div class="table">
            <el-table ref="multipleTable" :data="tableData" border v-loading="loading" tooltip-effect="dark"
                style="width: 100%" :header-cell-style="{ color: '#1c1f26', background: '#f6f6f6' }">
                <el-table-column prop="id" label="ID" show-overflow-tooltip></el-table-column>
                <el-table-column prop="sort" label="次序" show-overflow-tooltip></el-table-column>
                <el-table-column prop="createTime" label="创建日期" show-overflow-tooltip></el-table-column>
                <el-table-column prop="updateTime" label="更新日期" show-overflow-tooltip></el-table-column>
                <el-table-column prop="addTime" label="图片" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <img class="imgSize" :src="scope.row.pic" />
                    </template>
                </el-table-column>
                <el-table-column label="操作" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-button size="mini" @click="handleEdit(scope.row.id, scope.row.pic, scope.row.sort)">编辑</el-button>
                        <el-popconfirm title="确定删除吗？" @confirm="dele(scope.row.id)">
                            <el-button class="left" size="mini" type="danger" slot="reference">删除</el-button>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
            <div class="fy">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="currentPage" :page-size="pageSize" background layout="prev, pager, next"
                    :total="total">
                </el-pagination>
            </div>
        </div>
        <!-- 增加轮播图 -->
        <el-dialog
        title="新增轮播图"
        :visible.sync="banner"
        :close-on-click-modal="false"
        center
        width="26%">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="70px" class="demo-ruleForm">
            <el-form-item label="次序" prop="cx">
                <el-input v-model="ruleForm.cx"></el-input>
            </el-form-item>
            <el-form-item label="轮播图" prop="pic">
                <el-upload
                    action=""
                    :auto-upload="false"
                    ref="imgs"
                    v-model="ruleForm.pic"
                    list-type="picture-card"
                    :on-change="getFile"
                    :on-remove="handleRemove"
                    :limit="1">
                    <i class="el-icon-plus"></i>
                </el-upload>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="banner = false">取 消</el-button>
            <el-button type="primary" @click="add">确 定</el-button>
        </span>
        </el-dialog>
        <!-- 编辑轮播图 -->
        <el-dialog
        title="编辑轮播图"
        :visible.sync="bjbanner"
        :close-on-click-modal="false"
        center
        width="27%">
        <el-form :model="bjForm" :rules="bjrules" ref="bjForm" label-width="70px" class="demo-ruleForm">
            <el-form-item label="次序" prop="cx">
                <el-input v-model="bjForm.cx"></el-input>
            </el-form-item>
            <el-form-item label="轮播图" prop="pic">
                <el-upload
                    action=""
                    :auto-upload="false"
                    ref="imgsSize"
                    v-model="bjForm.pic"
                    list-type="picture-card"
                    :on-change="getFiles"
                    :on-remove="handleRemoves"
                    :file-list="fileList"
                    :limit="1">
                    <i class="el-icon-plus"></i>
                </el-upload>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="bjbanner = false">取 消</el-button>
            <el-button type="primary" @click="update">确 定</el-button>
        </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                ruleForm: {
                    cx: '',
                    pic: ''
                },
                bjForm: {
                    cx: '',
                    pic: ''
                },
                bjrules: {
                    cx: [
                        { required: true, message: '请输入次序', trigger: 'blur' }
                    ],
                    pic: [
                        { required: true, message: '请上传轮播图', trigger: 'blur' }
                    ]
                },
                rules: {
                    cx: [
                        { required: true, message: '请输入次序', trigger: 'blur' }
                    ],
                    pic: [
                        { required: true, message: '请上传轮播图', trigger: 'blur' }
                    ]
                },
                id: '',
                yhm: '',
                cznr: '',
                tableData: [],
                currentPage: 1,
                pageSize: 10,
                total: 0,
                fileList: [],
                open: false,
                banner: false,
                bjbanner: false
            }
        },
        created() {
            this.list()
        },
        methods: {
            update() {
                this.$refs.bjForm.validate(async(valid) => {
                    if (valid) {
                        let res = await this.$http.post('/banner/saveOrUpdate.do', {
                            id: this.id,
                            pic: this.bjForm.pic ? this.bjForm.pic : null,
                            sort: this.bjForm.cx
                        })
                        if(res.data.code == 200) {
                            this.$message({
                                type: 'success',
                                message: '编辑成功',
                                center: true,
                                offset: 50
                            })
                            this.bjbanner = false
                            this.list()
                        }
                    } else {
                        return false;
                    }
                });
            },
            handleRemove() {
                this.ruleForm.pic = ''
            },
            handleRemoves() {
                this.bjForm.pic = ''
            },
            getBase64(file) {   // 公共方法 -> 获取图片转base64
                return new Promise(function (resolve, reject) {
                    const reader = new FileReader()
                    let imgResult = ''
                    reader.readAsDataURL(file)
                    reader.onload = function () {
                        imgResult = reader.result
                    }
                    reader.onerror = function (error) {
                        reject(error)
                    }
                    reader.onloadend = function () {
                        resolve(imgResult)
                    }
                })
            },
            getFile(file) {
                this.getBase64(file.raw).then((res) => {
                    const params = res.split(',')
                    this.ruleForm.pic = params[1]
                    if (params.length > 0) {
                        this.strimagebase64 = params[1]
                    }
                })
            },
            getFiles(file) {
                this.getBase64(file.raw).then((res) => {
                    const params = res.split(',')
                    this.bjForm.pic = params[1]
                    if (params.length > 0) {
                        this.strimagebase64 = params[1]
                    }
                })
            },
            add() {     //新增轮播图
                this.$refs.ruleForm.validate(async(valid) => {
                    if (valid) {
                        var res = await this.$http.post('/banner/saveOrUpdate.do', {
                            pic: this.ruleForm.pic,
                            sort: this.ruleForm.cx
                        })
                        if(res.data.code == 200) {
                            this.$message({
                                type: 'success',
                                message: '添加成功',
                                center: true,
                                offset: 50
                            })
                            this.banner = false
                            this.list()
                        }
                    } else {
                        return false;
                    }
                });
            },
            async list() {      // 列表数据
                this.loading = true
                var res = await this.$http.post('/banner/list.do', {
                    current: this.currentPage,
                    size: this.pageSize
                })
                this.loading = false
                if (res.data.code == 200) {
                    if (res.data.data != undefined) {
                        this.tableData = res.data.data
                        this.total = res.data.count
                    } else {
                        this.tableData = []
                        this.total = 0
                    }
                }
            },
            handleSizeChange(pageSize) {
                this.pageSize = pageSize
                this.list()
            },
            handleCurrentChange(currentPage) {
                this.currentPage = currentPage
                this.list()
            },
            async handleEdit(id, pic, sort) {
                this.fileList = []
                this.id = id
                this.bjForm.cx = sort
                this.bjForm.pic = pic
                let obj = {
                    url: pic,
                    name: 'img'
                }
                this.fileList.push(obj)
                this.bjbanner = true
            },
            async dele(id) { //删除
                let res = await this.$http.post('/banner/delete.do', {
                    id: id
                })
                if (res.data.code == 200) {
                    this.$message({
                        type: 'success',
                        message: '删除成功',
                        center: true,
                        offset: 50
                    })
                    this.list()
                }
            }
        },
        watch: {
            banner: {
                handler() {
                    if(this.banner == false) {
                        this.$refs.ruleForm.resetFields();
                        this.$refs.imgs.clearFiles()
                    }
                }
            },
            bjbanner: {
                handler() {
                    if(this.bjbanner == false) {
                        this.$refs.bjForm.resetFields();
                        this.$refs.imgsSize.clearFiles()
                    }
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .box {
        padding: 10px;
    }
    .imgSize {
        width: 200px;
        height: 130px;
    }
</style>
