<template>
  <div class="box">
    <div class="search">
      <el-input v-model="info.lxmc" class="width" clearable placeholder="类型名称"></el-input>
      <el-input v-model="info.lxz" class="width left" clearable placeholder="类型值"></el-input>
      <el-button type="primary" class="left" @click="list">查询</el-button>
      <el-button type="primary" class="left" @click="feedback = true">增加</el-button>
    </div>
    <div class="table">
      <el-table
        ref="multipleTable"
        :data="tableData"
        border
        v-loading="loading"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="{ color: '#1c1f26', background: '#f6f6f6' }">
        <el-table-column prop="id" label="序号" width="80"></el-table-column>
        <el-table-column prop="name" label="类型名称" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="type" label="类型值" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="status" label="状态" show-overflow-tooltip>
            <template slot-scope="scope">
                <span v-if="scope.row.status == '0'">正常</span>
                <span v-else-if="scope.row.status == '1'">禁用</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
            <template slot-scope="scope">
                <el-button size="mini" @click="handleEdit(scope.row.id)">编辑</el-button>
                <el-button class="left" size="mini" type="danger" @click="del(scope.row.id)">删除</el-button>
            </template>
        </el-table-column>
      </el-table>
      <div class="fy">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          background
          layout="prev, pager, next"
          :total="total">
        </el-pagination>
      </div>
    </div>
    <!-- 增加类型 -->
    <el-dialog
        title="增加"
        center
        :close-on-click-modal="false"
        :show-close="false"
        :visible.sync="feedback"
        width="26%">
        <el-form ref="form" :model="form" :rules="rules" label-width="100px">
            <el-form-item label="类型名称" prop="name">
                <el-input v-model="form.name" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="类型值" prop="type">
                <el-input v-model="form.type" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="是否禁用" prop="sfjy">
                <el-radio v-model="form.sfjy" label="0">正常</el-radio>
                <el-radio v-model="form.sfjy" label="1">禁用</el-radio>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="feedback = false">取 消</el-button>
            <el-button type="primary" @click="feedbackAdd">确 定</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
        form: {
            name: '',
            type: '',
            sfjy: '0'
        },
        rules: {
            name: [
                { required: true, message: '请输入类型名称', trigger: 'blur' }
            ],
            type: [
                { required: true, message: '请输入类型值', trigger: 'blur' }
            ]
        },
        info: {
            lxmc: '',
            lxz: ''
        },
        loading: false,
        tableData: [],
        currentPage: 1,
        total: 0,
        Select: [],
        value: "",
        options: [],
        id: '',
        feedback: false,
        xqlist: []
    };
  },
  created() {
    this.list()
  },
  methods: {
    async list() {
      this.loading = true;
      let res = await this.$http.post("/dictType/getDictType", {
        name: this.info.lxmc ? this.info.lxmc : null,
        type: this.info.lxz ? this.info.lxz : null,
        current: this.currentPage,
        size: 10
      });
      if (res.data.code == 200) {
        this.total = res.data.count
        this.tableData = res.data.data
      }
      this.loading = false;
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.list();
    },
    async handleEdit(id) {
        this.id = id
        let res = await this.$http.post('/dictType/getDictTypeById', {
            id: id
        })
        if(res.data.code == 200) {
            this.form.name = res.data.data.name
            this.form.type = res.data.data.type
            this.form.sfjy = String(res.data.data.status)
            this.feedback = true
        }
    },
    async del(id) {
        this.$confirm('是否删除此条数据？', '提醒', {
            distinguishCancelAndClose: true,
            confirmButtonText: '确认',
            cancelButtonText: '取消'
        }).then(async () => {
            let res = await this.$http.post('/dictType/delete', {
                id: id
            })
            if(res.data.code == 200) {
                this.$message({
                    type: 'success',
                    message: '删除成功',
                    center: true,
                    offset: 50
                })
                this.list()
            }
        })
        .catch(action => {
            return false;
        })
    },
    feedbackAdd() {
        this.$refs.form.validate(async(valid) => {
            if (valid) {
                let res = await this.$http.post('/dictType/saveOrUpdate', {
                    id: this.id ? this.id : null,
                    name: this.form.name,
                    status: this.form.sfjy,
                    type: this.form.type
                })
                if(res.data.code == 200) {
                    this.$message({
                        type: 'success',
                        message: this.id ? '编辑成功' : '添加成功',
                        center: true,
                        offset: 50
                    })
                    this.feedback = false
                    this.list()
                }
            } else {
                return false;
            }
        });
    }
  },
  watch: {
    feedback: {
        handler() {
            if(this.feedback == false) {
                this.$refs.form.resetFields();
                this.form.name = ''
                this.form.type = ''
                this.form.sfjy = '0'
                this.id = ''
            }
        }
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  padding: 10px;
  background-color: #fff;
}
</style>