import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../pages/Home.vue'
import Index from '../pages/Index.vue'
import login from '../pages/login.vue'
import wygl from '../pages/two/wygl.vue'
import jsgl from '../pages/two/jsgl.vue'
import yhgl from '../pages/two/yhgl.vue'
import bmgl from '../pages/two/bmgl.vue'
import gwgl from '../pages/two/gwgl.vue'
import dlrz from '../pages/two/dlrz.vue'
import czrz from '../pages/two/czrz.vue'
import cjzdpz from '../pages/two/cjzdpz.vue'
import jrsbgl from '../pages/two/jrsbgl.vue'
import glyxxxg from '../pages/two/glyxxxg.vue'
import sjtb from '../pages/two/sjtb.vue'
import xwgl from '../pages/two/xwgl.vue'
import newsAdd from '../pages/two/newsAdd.vue'
import xwglEdit from '../pages/two/xwglEdit.vue'
import banner from '../pages/two/banner.vue'
import fkgl from '../pages/two/fkgl.vue'
import hzgl from '../pages/two/hzgl.vue'
import piclibrary from '../pages/two/piclibrary.vue'
import customer from '../pages/two/customer.vue'
import sbgl from '../pages/two/sbgl.vue'
import indexpic from '../pages/two/indexpic.vue'
import page from '../pages/two/page.vue'
import pageadd from '../pages/two/pageAdd.vue'
import Journalism from '../pages/two/Journalism.vue'
import Journalismadd from '../pages/two/Journalismadd.vue'
import links from '../pages/two/links.vue'
import jjfaadd from '../pages/two/jjfaadd.vue'
import product from '../pages/two/product.vue'
import productadd from '../pages/two/productadd.vue'
import download from '../pages/two/download.vue'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  { path: '/', redirect: '/login' },
  { path: '/login', name: 'login', component: login },
  { path: '/home', name: 'home', component: Home, redirect: '/index',
    children: [
      { path: '/index', name: 'index', component: Index },
      { path: '/wygl', name: 'wygl', component: wygl },
      { path: '/jsgl', name: 'jsgl', component: jsgl },
      { path: '/yhgl', name: 'yhgl', component: yhgl },
      { path: '/bmgl', name: 'bmgl', component: bmgl },
      { path: '/gwgl', name: 'gwgl', component: gwgl },
      { path: '/dlrz', name: 'dlrz', component: dlrz },
      { path: '/czrz', name: 'czrz', component: czrz },
      { path: '/cjzdpz', name: 'cjzdpz', component: cjzdpz },
      { path: '/jrsbgl', name: 'jrsbgl', component: jrsbgl },
      { path: '/glyxxxg', name: 'glyxxxg', component: glyxxxg },
      { path: '/sjtb', name: 'sjtb', component: sjtb },
      { path: '/xwgl', name: 'xwgl', component: xwgl },
      { path: '/newsAdd', name: 'newsAdd', component: newsAdd },
      { path: '/xwglEdit', name: 'xwglEdit', component: xwglEdit },
      { path: '/banner', name: 'banner', component: banner },
      { path: '/fkgl', name: 'fkgl', component: fkgl },
      { path: '/hzgl', name: 'hzgl', component: hzgl },
      { path: '/piclibrary', name: 'piclibrary', component: piclibrary },
      { path: '/customer', name: 'customer', component: customer },
      { path: '/sbgl', name: 'sbgl', component: sbgl },
      { path: '/indexpic', name: 'indexpic', component: indexpic },
      { path: '/page', name: 'page', component: page },
      { path: '/pageadd', name: 'pageadd', component: pageadd },
      { path: '/Journalism', name: 'Journalism', component: Journalism },
      { path: '/Journalismadd', name: 'Journalismadd', component: Journalismadd },
      { path: '/links', name: 'links', component: links },
      { path: '/jjfaadd', name: 'jjfaadd', component: jjfaadd },
      { path: '/product', name: 'product', component: product },
      { path: '/productadd', name: 'productadd', component: productadd },
      { path: '/download', name: 'download', component: download }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

//配置全局路由导航守卫
router.beforeEach((to, from, next)=>{
	if(to.path == '/login'){
		next();
	}else{
		if(window.sessionStorage.getItem('userName') != null && window.sessionStorage.getItem('userName') != undefined){
			next();
		}else{
		  next('/login');
		}
	}
})
export default router