import { render, staticRenderFns } from "./sbgl.vue?vue&type=template&id=2e06c5cd&scoped=true&"
import script from "./sbgl.vue?vue&type=script&lang=js&"
export * from "./sbgl.vue?vue&type=script&lang=js&"
import style0 from "./sbgl.vue?vue&type=style&index=0&id=2e06c5cd&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e06c5cd",
  null
  
)

export default component.exports