<template>
    <div class="box">
        <!-- 搜索区域 -->
        <div class="search">
            <el-input v-model="yqmc" class="width" clearable placeholder="园区名称"></el-input>
            <el-button type="primary" class="left" @click="list">查询</el-button>
            <el-button type="primary" @click="xzcbl = true">新增</el-button>
        </div>
        <div class="table">
            <!-- 表格区域 -->
            <el-table ref="multipleTable" v-loading="loading" :data="tableData" tooltip-effect="dark"
                style="width: 100%" :header-cell-style="{ color: '#1c1f26', background: '#f6f6f6' }">
                <el-table-column label="序号" min-width='5%'>
                    <template slot-scope="scope">
                        {{ scope.row.yqbm.substring(12) }}
                    </template>
                </el-table-column>
                <el-table-column prop="yqmc" min-width='10%' label="园区名称"></el-table-column>
                <el-table-column prop="yqbm" min-width='10%' label="园区编码"></el-table-column>
                <el-table-column prop="count" min-width='10%' label="最大公司数量"></el-table-column>
                <el-table-column prop="starttime" min-width='10%' label="有效期起始时间"></el-table-column>
                <el-table-column prop="endtime" min-width='10%' label="有效期截止时间"></el-table-column>
                <el-table-column prop="username" min-width='10%' label="管理员名称"></el-table-column>
                <el-table-column prop="phone" min-width='10%' label="管理员手机号码"></el-table-column>
                <el-table-column prop="flg" min-width='10%' label="是否下拉"></el-table-column>
                <el-table-column label="操作" min-width='15%'>
                    <template slot-scope="scope">
                        <el-button size="mini" @click="handleEdit(scope.row.yqbm, scope.row.yq_id, scope.row.q_id)">编辑</el-button>
                        <el-button size="mini" type="primary" @click="see(scope.row.yqbm, scope.row.secretAgreement, scope.row.phone)">查看</el-button>
                        <el-popconfirm title="确定删除吗？" @confirm="handleDelete(scope.row.yqbm)">
                            <el-button class="left" size="mini" type="danger" slot="reference">删除</el-button>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
            <div class="fy">
                <!-- 翻页组件 -->
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="currentPage" :page-size="pageSize" background layout="prev, pager, next"
                    :total="total">
                </el-pagination>
            </div>
            <!-- 新增园区 -->
            <el-dialog title="新增园区" :visible.sync="xzcbl" :show-close="false" :close-on-click-modal="false" width="30%"
                center>
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="130px" class="demo-ruleForm">
                    <el-form-item label="园区名称" prop="name">
                        <el-input class="widthd" v-model="ruleForm.name" placeholder="请输入园区名称"></el-input>
                    </el-form-item>
                    <el-form-item label="园区编码" prop="yqbm">
                        <el-input class="widthd" disabled v-model="ruleForm.yqbm"></el-input>
                    </el-form-item>
                    <el-form-item label="社区代码:" prop="sqdm">
                        <el-select class="widthd" filterable :loading="SQDMloading" remote v-model="ruleForm.sqdm"
                            :remote-method="SQDMremoteMethod" placeholder="请输入社区名称">
                            <el-option v-for="item in SQDMoptions" :key="item.zdx" :label="item.zdz" :value="item.zdx">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="管理员手机号" prop="phone">
                        <el-input class="widthd" v-model="ruleForm.phone" placeholder="请输入手机号码"></el-input>
                    </el-form-item>
                    <el-form-item label="最大公司数量" prop="count">
                        <el-input class="widthd" v-model="ruleForm.count" placeholder="请输入公司数量"></el-input>
                    </el-form-item>
                    <el-form-item label="有效期起始时间" prop="qsTime">
                        <el-date-picker class="widthd" v-model="ruleForm.qsTime" type="daterange" :clearable="false"
                            value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期"
                            end-placeholder="结束日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="xzcbl = false">取 消</el-button>
                        <el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
                    </el-form-item>
                </el-form>
            </el-dialog>
            <!-- 编辑园区 -->
            <el-dialog title="编辑园区" :visible.sync="bjgs" :close-on-click-modal="false" width="30%" center>
                <el-form :model="bjgsForm" v-loading="bjopen" :rules="gsbjrules" ref="bjgsForm" label-width="130px"
                    class="demo-ruleForm">
                    <el-form-item label="园区名称" prop="name">
                        <el-input class="widthd" v-model="bjgsForm.name" placeholder="请输入园区名称"></el-input>
                    </el-form-item>
                    <el-form-item label="园区编码" prop="yqbm">
                        <el-input class="widthd" disabled v-model="bjgsForm.yqbm"></el-input>
                    </el-form-item>
                    <el-form-item label="社区代码:" prop="sqdm">
                        <el-select class="widthd" filterable @change="sqdmb" v-model="bjgsForm.sqdm" placeholder="请输入社区名称">
                            <el-option v-for="item in sqdmList" :key="item.zdx" :label="item.zdz" :value="item.zdx">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="管理员手机号码" prop="phone">
                        <el-input class="widthd" v-model="bjgsForm.phone" placeholder="请输入手机号码"></el-input>
                    </el-form-item>
                    <el-form-item label="最大公司数量" prop="count">
                        <el-input class="widthd" v-model="bjgsForm.count" placeholder="请输入公司数量"></el-input>
                    </el-form-item>
                    <el-form-item label="有效期起始时间" prop="qsTime">
                        <el-date-picker class="widthd" v-model="bjgsForm.qsTime" type="daterange" :clearable="false"
                            value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期"
                            end-placeholder="结束日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="是否下拉">
                        <el-select v-model="bjgsForm.sfxl" style="width: 100%;" placeholder="请选择">
                            <el-option label="已下拉" value="1"></el-option>
                            <el-option label="未下拉" value="0"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="bjgs = false">取 消</el-button>
                        <el-button type="primary" @click="bjgstj">确定</el-button>
                    </el-form-item>
                </el-form>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    //只能输入数字验证
    var number = (rule, value, callback) => {
        if (value == '') {
            callback(new Error('请输入公司数量'))
        } else {
            var reg = /^\d+$|^\d+[.]?\d+$/;
            if (reg.test(value)) {
                callback()
            } else {
                callback(new Error('公司数量只能输入数字!'))
            }
            callback()
        }
    }
    //只能输入数字验证
    var phone = (rule, value, callback) => {
        if (value == '') {
            callback(new Error('请输入手机号码'))
        } else {
            var reg = /^1\d{10}$/;
            if (reg.test(value)) {
                callback()
            } else {
                callback(new Error('格式不正确请重新输入'))
            }
            callback()
        }
    }
    export default {
        data() {
            return {
                yqmc: '',
                q_id: '',
                yqid: '',
                bjopen: false,
                loading: false,
                tableData: [],
                currentPage: 1,
                pageSize: 10,
                total: 0,
                xzcbl: false,
                ruleForm: {
                    name: '',
                    qsTime: [],
                    yqbm: '',
                    sqdm: '',
                    count: '',
                    phone: ''
                },
                rules: {
                    name: [{
                        required: true,
                        message: '请输入公司名称',
                        trigger: 'blur'
                    }],
                    yqbm: [{
                        required: true,
                        message: '请输入园区编码',
                        trigger: 'blur'
                    }],
                    qsTime: [{
                        required: true,
                        message: '请选择有效期起始日期',
                        trigger: 'blur'
                    }],
                    sqdm: [{
                        required: true,
                        message: '请选择社区名称',
                        trigger: 'blur'
                    }],
                    count: [{
                        required: true,
                        validator: number,
                        trigger: 'blur'
                    }],
                    phone: [{
                        required: true,
                        validator: phone,
                        trigger: 'blur'
                    }]
                },
                bjgsForm: { // 编辑公司表单数据
                    name: '',
                    qsTime: [],
                    yqbm: '',
                    sqdm: '',
                    count: '',
                    phone: '',
                    sfxl: ''
                },
                gsbjrules: { // 编辑公司预验证
                    name: [{
                        required: true,
                        message: '请输入公司名称',
                        trigger: 'blur'
                    }],
                    yqbm: [{
                        required: true,
                        message: '请输入园区编码',
                        trigger: 'blur'
                    }],
                    qsTime: [{
                        required: true,
                        message: '请选择有效期起始日期',
                        trigger: 'blur'
                    }],
                    sqdm: [{
                        required: true,
                        message: '请选择社区名称',
                        trigger: 'blur'
                    }],
                    count: [{
                        required: true,
                        validator: number,
                        trigger: 'blur'
                    }],
                    phone: [{
                        required: true,
                        validator: phone,
                        trigger: 'blur'
                    }]
                },
                gslist: [],
                bjgs: false,
                sqdmList: '',
                // 社区代码的loading
                SQDMloading: false,
                // 社区代码的options
                SQDMoptions: [],
            }
        },
        created() {
            this.list()
            this.handleSQDMList()
        },
        methods: {
            async list() { //列表数据
                this.loading = true
                let res = await this.$http.post('/yq/getYqInfo.do', {
                    yqmc: this.yqmc == '' ? null : this.yqmc,
                    currPageNo: this.currentPage,
                    pageSize: this.pageSize
                })
                if (res.data.code == 200) {
                    this.tableData = res.data.list[0].records
                    this.total = res.data.list[0].total
                }
                this.loading = false
            },
            handleSizeChange(pageSize) {
                this.pageSize = pageSize
                this.list()
            },
            handleCurrentChange(currentPage) {
                this.currentPage = currentPage
                this.list()
            },
            see(yqbm, yqmy, phone) {
                const h = this.$createElement;
                this.$msgbox({
                title: '信息',
                message: h('p', null, [
                    h('p', null, `园区编码：${yqbm}`),
                    h('p', null, `园区秘钥：${yqmy}`),
                    h('p', null, `管理员手机号：${phone}`)
                ]),
                showCancelButton: true,
                confirmButtonText: '发送',
                cancelButtonText: '取消',
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        instance.confirmButtonText = '发送中...';
                        setTimeout(() => {
                            done();
                            setTimeout(async() => {
                                let res = await this.$http.post('/yq/senMsg.do', {
                                    yqbm: yqbm,
                                    secretAgreement: yqmy,
                                    phone: phone
                                })
                                if(res.data.code == 200) {
                                    return instance.confirmButtonLoading = false;
                                }
                                instance.confirmButtonLoading = false;
                                this.$message({
                                    type: 'warning',
                                    message: '发送失败',
                                    center: true,
                                    offset: 50
                                })
                            }, 300);
                        }, 2000);
                    } else {
                        done();
                    }
                }
                }).then(action => {
                    
                });
            },
            async sqdmb() {
                let res = await this.$http.post('/yq/generateYqbm.do', {
                    sqdm: this.bjgsForm.sqdm,
                    yq_id: this.yqid
                })
                if (res.data.code == 200) {
                    this.bjgsForm.yqbm = res.data.list[0]
                } else {
                    return;
                }
            },
            // 获取社区代码列表
            async handleSQDMList() {
                let res = await this.$http.get('/yq/selSqdm.do')
                this.sqdmList = res.data.list
            },
            // 社区代码搜索
            SQDMremoteMethod(query) {
                if (query !== '') {
                    this.SQDMloading = true
                    setTimeout(() => {
                        this.SQDMloading = false
                        this.SQDMoptions = this.sqdmList.filter((item) => {
                            return item.zdz.toLowerCase().indexOf(query.toLowerCase()) > -1
                        })
                    }, 200)
                } else {
                    this.SQDMoptions = []
                }
            },
            // 园区提交
            submitForm(formName) {
                this.$refs.ruleForm.validate(async (valid) => {
                    if (valid) {
                        let res = await this.$http.post('/yq/insertYqInfo.do', {
                            optUser: window.sessionStorage.getItem('userName'),
                            gsbm: '',
                            yqmc: this.ruleForm.name,
                            yqbm: this.ruleForm.yqbm,
                            sqdm: this.ruleForm.sqdm,
                            count: this.ruleForm.count,
                            starttime: this.ruleForm.qsTime[0],
                            endtime: this.ruleForm.qsTime[1],
                            phone: this.ruleForm.phone
                        })
                        if (res.data.code == 200) {
                            var info = res.data.errMsg
                            this.$message({
                                type: 'success',
                                message: info,
                                center: true,
                                offset: 50
                            })
                            this.xzcbl = false
                            this.list()
                        }
                    } else {
                        return false;
                    }
                })
            },
            // 编辑园区提交
            bjgstj() {
                this.$refs.bjgsForm.validate(async (valid) => {
                    if (valid) {
                        let res = await this.$http.post('/yq/updateYqInfo.do', {
                            optUser: window.sessionStorage.getItem('userName'),
                            gsbm: '',
                            yq_id: this.yqid,
                            q_id: this.q_id,
                            yqmc: this.bjgsForm.name,
                            yqbm: this.bjgsForm.yqbm,
                            sqdm: this.bjgsForm.sqdm,
                            count: this.bjgsForm.count,
                            phone: this.bjgsForm.phone,
                            flg: this.bjgsForm.sfxl ? this.bjgsForm.sfxl : null,
                            starttime: this.bjgsForm.qsTime[0],
                            endtime: this.bjgsForm.qsTime[1]
                        })
                        if (res.data.code == 200) {
                            var info = res.data.errMsg
                            this.$message({
                                type: 'success',
                                message: info,
                                center: true,
                                offset: 50
                            })
                            this.bjgs = false
                            this.list()
                        }
                    }
                })
            },
            // 点击编辑
            async handleEdit(yqbm, yq_id, q_id) {
                this.bjopen = true
                this.bjgs = true
                this.yqid = yq_id
                this.q_id = q_id
                let res = await this.$http.post('/yq/getYqInfo.do', {
                    yqbm: yqbm,
                    currPageNo: 1,
                    pageSize: 10
                })
                if (res.data.code == 200) {
                    this.bjgsForm.name = res.data.list[0].records[0].yqmc
                    this.bjgsForm.phone = res.data.list[0].records[0].phone
                    this.bjgsForm.sqdm = res.data.list[0].records[0].yqbm.substring(0, 12)
                    this.bjgsForm.count = res.data.list[0].records[0].count
                    this.bjgsForm.sfxl = res.data.list[0].records[0].flgCode == 1 ? res.data.list[0].records[0].flgCode.toString() : '0'
                    this.bjgsForm.yqbm = res.data.list[0].records[0].yqbm.substring(12, 15)
                    this.bjgsForm.qsTime = [res.data.list[0].records[0].starttime, res.data.list[0].records[0].endtime]
                    let resa = await this.$http.post('/yq/generateYqbm.do', {
                        sqdm: this.bjgsForm.sqdm,
                        yq_id: yq_id
                    })
                    if (resa.data.code == 200) {
                        this.bjgsForm.yqbm = resa.data.list[0]
                        this.bjopen = false
                    } else {
                        return this.bjopen = false;
                    }
                }
                this.bjopen = false
            },
            // 删除
            async handleDelete(yqbm) {
                let res = await this.$http.post('/yq/delYqInfo.do', {
                    yqbm: yqbm
                })
                if (res.data.code == 200) {
                    var info = res.data.errMsg
                    this.$message({
                        type: 'success',
                        message: info,
                        center: true,
                        offset: 50
                    })
                    this.list()
                }
            }
        },
        watch: {
            xzcbl: {
                async handler() {
                    if (this.xzcbl == false) {
                        this.$refs.ruleForm.resetFields()
                        // this.activeName = 'gs'
                    } else {
                        let res = await this.$http.post('/yq/generateYqbm.do', {
                            sqdm: this.ruleForm.sqdm
                        })
                        if (res.data.code == 200) {
                            this.ruleForm.yqbm = res.data.list[0]
                        } else {
                            return;
                        }
                        // let resa = await this.$http.post('/yq/getYqInfo.do', {
                        //     wyType: 0,
                        //     currPageNo: this.currentPage,
                        //     pageSize: this.pageSize
                        // })
                        // if (resa.data.code == 200) {
                        //     this.gslist = resa.data.list[0].wyInfo
                        // }
                    }
                }
            },
            bjgs: {
                handler() {
                    if (this.bjgs == false) {
                        this.$refs.bjgsForm.resetFields()
                    }
                }
            },
            'ruleForm.sqdm': {
                async handler() {
                    if(this.ruleForm.sqdm) {
                        let res = await this.$http.post('/yq/generateYqbm.do', {
                            sqdm: this.ruleForm.sqdm
                        })
                        if (res.data.code == 200) {
                            this.ruleForm.yqbm = res.data.list[0]
                        } else {
                            return;
                        }
                    }
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .box {
        padding: 10px;
    }
</style>
