<template>
    <div class="box">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="方案名称" prop="solutionName">
                        <el-input v-model="ruleForm.solutionName"></el-input>
                    </el-form-item>
                    <el-form-item label="方案类别" prop="type">
                        <el-select v-model="ruleForm.type" placeholder="">
                            <el-option
                            v-for="item in falbList"
                            :key="item.id"
                            :label="item.scName"
                            :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="方案名称" prop="radio">
                        <el-radio v-model="ruleForm.radio" label="0">不推荐</el-radio>
                        <el-radio v-model="ruleForm.radio" label="1">推荐</el-radio>
                    </el-form-item>
                    <el-form-item label="关键字" prop="keyWord">
                        <el-input v-model="ruleForm.keyWord" placeholder="多个关键字请使用英文逗号隔开"></el-input>
                    </el-form-item>
                    <el-form-item label="摘要" prop="outline">
                        <el-input type="textarea" v-model="ruleForm.outline"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-form-item label="首页展示图" prop="pic">
                <publicpic @picUrl="picurl" :propC="ruleForm.pic"></publicpic>
            </el-form-item>
            <el-form-item label="频道展示图" prop="smallpic">
                <publicpic @picUrl="smallpicpicurl" :propC="ruleForm.smallpic"></publicpic>
            </el-form-item>
            <el-form-item label="页面内容" prop="content">
                <editor v-model="ruleForm.content"></editor>
            </el-form-item>
        </el-form>
        <div class="footer">
            <el-button @click="$router.push({ name: 'jsgl' })">返回</el-button>
            <el-button type="primary" @click="update">提交</el-button>
        </div>
    </div>
</template>

<script>
import publicpic from '../../components/publicpic.vue'
import Editor from '../../components/imcoder-tinymce.vue'

export default {
    data() {
        return {
            id: this.$route.query.id ? this.$route.query.id : null,
            options: [],
            ruleForm: {
                solutionName: '',
                outline: '',
                pic: '',
                smallpic: '',
                content: '',
                type: '',
                radio: '0',
                keyWord: ''
            },
            rules: {
                solutionName: [{ required: true, message: '请输入方案名称', trigger: 'blur' }],
                outline: [{ required: true, message: '请输入摘要', trigger: 'blur' }],
                pic: [{ required: true, message: '请选择缩略图', trigger: 'change' }],
                smallpic: [{ required: true, message: '请选择首页小图', trigger: 'change' }],
                type: [{ required: true, message: '请选择方案类别', trigger: 'change' }],
                content: [{ required: true, message: '请输入内容', trigger: 'blur' }],
                keyWord: [{ required: true, message: '请输入关键字', trigger: 'blur' }]
            },
            fileList: [],
            pic: '',
            falbList: []
        }
    },
    components: {
        Editor,         //注册富文本组件
        publicpic
    },
    created() {
        this.falbs()
    },
    methods: {
        picurl(url) {
            this.ruleForm.pic = url
        },
        smallpicpicurl(url) {
            this.ruleForm.smallpic = url
        },
        async falbs() {
            let res = await this.$http.post('/schemeCategory/showSchemeCategory')
            if(res.data.code == 200) {
                this.falbList = res.data.data
            }
        },
        async menu() {
            let res = await this.$http.get('/webMenu/dropbox')
            if(res.data.code == 200) {
                this.options = res.data.data
            }
        },
        update() {
            this.$refs.ruleForm.validate(async (valid) => {
                if (valid) {
                    let res = await this.$http.post('/scheme/saveOrUpdate', {
                        id: this.id ? this.id : null,
                        solutionName: this.ruleForm.solutionName,
                        pic: this.ruleForm.pic,
                        type: this.ruleForm.type,
                        outline: this.ruleForm.outline,
                        content: this.ruleForm.content,
                        isRecommend: Number(this.ruleForm.radio),
                        smallPic: this.ruleForm.smallpic,
                        keyWord: this.ruleForm.keyWord
                    })
                    if(res.data.code == 200) {
                        this.$message({
                            type: 'success',
                            message: this.id ? '编辑成功' : '添加成功',
                            center: true,
                            offset: 50
                        })
                        this.$router.push({ name: 'jsgl' })
                    }
                } else {
                    return false;
                }
            })
        }
    },
    watch: {
        id: {
            immediate: true,
            async handler(news, old) {
                if(news) {
                    let res = await this.$http.post('/scheme/list', {
                        id: news
                    })
                    if(res.data.code == 200) {
                        this.ruleForm.solutionName = res.data.data[0].solutionName
                        this.ruleForm.outline = res.data.data[0].outline
                        this.ruleForm.pic = res.data.data[0].pic
                        this.ruleForm.smallpic = res.data.data[0].smallPic
                        this.ruleForm.content = res.data.data[0].content
                        this.ruleForm.type = res.data.data[0].type
                        this.ruleForm.radio = res.data.data[0].isRecommend.toString()
                        this.ruleForm.keyWord = res.data.data[0].keyWord
                    }
                }
            }
        }
    }
}
</script>

<style scoped>
.box{
  padding: 10px;
  width: 100%;
  background-color: #fff;
}
.box>>>.ql-editor{
    height: 500px !important;
}
.width {
    width: 300px !important;
}
.footer {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
}
</style>