<template>
    <div class="news">
        <div class="news_box">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="auto" class="demo-ruleForm">
                <el-form-item label="资讯标题" prop="name">
                    <el-input v-model="ruleForm.name"></el-input>
                </el-form-item>
                <el-form-item label="标题颜色" prop="color">
                    <el-color-picker v-model="ruleForm.color"></el-color-picker>
                </el-form-item>
                <el-form-item label="资讯图片">
                    <el-upload action="" :limit="1" ref="xzupload" v-model="ruleForm.imgbase" :auto-upload="false"
                        list-type="picture-card" :on-change="getFilea" :on-remove="handleRemove">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="资讯内容" prop="content">
                    <quill-editor v-model="ruleForm.content" ref="myQuillEditor" :options="editorOption"></quill-editor>
                </el-form-item>
                <el-form-item label="是否热门" prop="sfrm">
                    <el-radio-group v-model="ruleForm.sfrm">
                        <el-radio label="0">否</el-radio>
                        <el-radio label="1">是</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <div class="footer">
                <el-button @click="$router.push({ name: 'xwgl' })">返 回</el-button>
                <el-button type="primary" @click="newsUp">确 定</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { quillEditor } from "vue-quill-editor"; //调用编辑器
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
export default {
    data() {
        return {
            editorOption: {},
            ruleForm: { //新增数据
                name: '',
                color: '#000000',
                content: '',
                imgbase: '',
                sfrm: '0'
            },
            rules: { //新增验证
                name: [{
                    required: true,
                    message: '请输入资讯标题',
                    trigger: 'blur'
                }],
                content: [{
                    required: true,
                    message: '请输入资讯内容',
                    trigger: 'blur'
                }]
            },
        }
    },
    components: {
        quillEditor         //注册富文本组件
    },
    methods: {
        newsUp() { //新增提交
            this.$refs.ruleForm.validate(async (valid) => {
                if (valid) {
                    let res = await this.$http.post('/ad/insAdsin.do', {
                        optUser: window.sessionStorage.getItem('userName'),
                        iname: this.ruleForm.name,
                        fontColor: this.ruleForm.color,
                        ishot: this.ruleForm.sfrm,
                        picUrl: this.ruleForm.imgbase,
                        icotent: this.ruleForm.content
                    })
                    if (res.data.code == 200) {
                        let info = res.data.errMsg
                        this.$message({
                            type: 'success',
                            message: info,
                            center: true,
                            offset: 50
                        })
                        this.$router.push({ name: 'xwgl' })
                    }
                } else {
                    return false;
                }
            })
        },
        handleRemove(file, fileList) {
            this.ruleForm.imgbase = ''
        },
        // base64
        getFilea(file, fileList) {
            this.getBase64(file.raw).then((res) => {
                const params = res.split(',')
                this.ruleForm.imgbase = params[1]
                if (params.length > 0) {
                    this.strimagebase64 = params[1]
                }
            })
        },
        // 公共方法 -> 获取图片转base64
        getBase64(file) {
            return new Promise(function(resolve, reject) {
                const reader = new FileReader()
                let imgResult = ''
                reader.readAsDataURL(file)
                reader.onload = function() {
                    imgResult = reader.result
                }
                reader.onerror = function(error) {
                    reject(error)
                }
                reader.onloadend = function() {
                    resolve(imgResult)
                }
            })
        }
    }
}
</script>

<style scoped>
.news {
    padding: 50px;
}
.news_box {
    width: 60%;
}
.footer {
    width: 100%;
    text-align: center;
}
.news>>>.ql-editor{
    height: 500px !important;
}
</style>