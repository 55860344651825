import axios from 'axios'
import router from '../router'
import DonMessage from '../plugins/message'

// 添加请求拦截器
axios.interceptors.request.use(
    config => {
        return config;
    },
    error => {
        return Promise.reject(error)
    }
)

// 响应拦截器
axios.interceptors.response.use(
    response => {
        let codes = response.data.code
        switch(codes) {
            case 204:
                DonMessage.warning(response.data.msg)
                break;
            case 201:
                DonMessage.warning(response.data.msg)
                break;
            case 405:
                DonMessage.warning(response.data.msg)
                router.push({ name: 'login' })
                window.sessionStorage.clear()
                window.localStorage.clear()
                break;
            default:
        }
        return response;
    },
    error => {
        var code
        if(error.response == undefined) {
            code = 100
        }else{
            code = error.response.status
        }
        switch(code) {
            case 500:
                DonMessage.warning('服务器内部错误')
                break;
            case 100:
                DonMessage.warning('请求超时')
                break;
            case 404:
                DonMessage.warning('请求不存在')
                break;
            default:
        }
        return Promise.reject(error)
    }
)