import Vue from 'vue'
import App from './App.vue'
import router from './router'
//引入rem全局适配
import 'amfe-flexible'
//引入element ui
import './plugins/element.js'
//引入element样式
import 'element-ui/lib/theme-chalk/index.css'
//引入请求插件
import axios from 'axios'

//自定义
var instance = axios.create({
  baseURL:'/api',
  timeout: 9000,
  headers:{"Content-Type":"multipart/form-data"}
});

Vue.prototype.instance = instance;

//配置请求头
axios.defaults.baseURL = '/api'
//把axios挂载到vue原型
Vue.prototype.$http = axios

//引入全局请求拦截器
import './plugins/Interceptor.js'

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
