<template>
    <div class="box">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm">
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="标题" prop="title">
                        <el-input v-model="ruleForm.title"></el-input>
                    </el-form-item>
                    <el-form-item label="所属页面" prop="mid">
                        <el-cascader :options="options" class="width" v-model="ruleForm.mid" :props="{ checkStrictly: true }" placeholder=""></el-cascader>
                    </el-form-item>
                    <el-form-item label="序号" prop="sort">
                        <el-input v-model="ruleForm.sort" class="width"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="跳转地址" prop="url">
                        <el-input v-model="ruleForm.url" class="widthd"></el-input>
                    </el-form-item>
                    <el-form-item label="浏览量" prop="views">
                        <el-input v-model="ruleForm.views" class="width"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-form-item label="页面图片" prop="pic">
                <publicpic @picUrl="picurl" :propC="ruleForm.pic"></publicpic>
            </el-form-item>
            <el-form-item label="页面内容" prop="content">
                <editor v-model="ruleForm.content"></editor>
            </el-form-item>
        </el-form>
        <div class="footer">
            <el-button @click="$router.push({ name: 'page' })">返回</el-button>
            <el-button type="primary" @click="update">提交</el-button>
        </div>
    </div>
</template>

<script>
import publicpic from '../../components/publicpic.vue'
import Editor from "../../components/imcoder-tinymce.vue"
export default {
    data() {
        return {
            id: this.$route.query.id ? this.$route.query.id : null,
            myValue: '',
            options: [],
            ruleForm: {
                title: '',
                sort: '',
                url: '',
                pic: '',
                content: '',
                views: '0',
                mid: ''
            },
            rules: {
                mid: [{ required: true, message: '请选择所属页面', trigger: 'change' }],
                title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
                sort: [{ required: true, message: '请输入序号', trigger: 'blur' }]
            },
            fileList: [],
            pic: '',
        }
    },
    components: {
        publicpic,
        Editor
    },
    created() {
        this.menu()
    },
    methods: {
        picurl(url) {
            this.ruleForm.pic = url
        },
        async menu() {
            let res = await this.$http.get('/webMenu/dropbox')
            if(res.data.code == 200) {
                this.options = res.data.data
            }
        },
        update() {
            this.$refs.ruleForm.validate(async (valid) => {
                if (valid) {
                    let res = await this.$http.post('/pageData/saveOrUpdate', {
                        id: this.id ? this.id : null,
                        content: this.ruleForm.content,
                        // pagingType: this.ruleForm.pagingType,
                        pic: this.ruleForm.pic ? this.ruleForm.pic : null,
                        sort: this.ruleForm.sort,
                        title: this.ruleForm.title,
                        url: this.ruleForm.url,
                        views: this.ruleForm.views,
                        mid: this.ruleForm.mid.length > 1 ? this.ruleForm.mid.slice(-1).toString() : this.ruleForm.mid.toString()
                    })
                    if(res.data.code == 200) {
                        this.$message({
                            type: 'success',
                            message: this.id ? '编辑成功' : '添加成功',
                            center: true,
                            offset: 50
                        })
                        this.$router.push({ name: 'page' })
                    }
                } else {
                    return false;
                }
            })
        }
    },
    watch: {
        id: {
            immediate: true,
            async handler(news, old) {
                if(news) {
                    this.fileList = []
                    let res = await this.$http.post('/pageData/list', {
                        id: news
                    })
                    if(res.data.code == 200) {
                        this.ruleForm.title = res.data.data[0].title
                        // this.ruleForm.pagingType = String(res.data.data[0].pageType)
                        this.ruleForm.sort = res.data.data[0].sort
                        this.ruleForm.url = res.data.data[0].url
                        this.ruleForm.pic = res.data.data[0].pic
                        this.ruleForm.content = res.data.data[0].content
                        this.ruleForm.views = res.data.data[0].views
                        this.ruleForm.mid = res.data.data[0].dropboxValue.split(',').map(Number)
                    }
                }
            }
        }
    }
}
</script>

<style scoped>
.box{
  padding: 10px;
  width: 100%;
  background-color: #fff;
}
.box>>>.ql-editor{
    height: 500px !important;
}
.width {
    width: 300px !important;
}
.footer {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
}
</style>