<template>
    <div class="home">
        <el-container class="box">
            <el-header class="hander">
                <img src="../assets/img/logo.png" />
                <div class="zuoyou">
                    <div @click="format" style="cursor: pointer;">
                        <el-avatar icon="el-icon-user-solid" style="margin-right: 30px; background-color: #6ad5ff; font-size: 24px;" :size="50"></el-avatar>
                    </div>
                    <div @click="tuichu" style="cursor: pointer;">
                        <el-avatar icon="el-icon-right" style="background-color: #6ad5ff; font-size: 24px;" :size="50"></el-avatar>
                    </div>
                </div>
            </el-header>
            <el-container>
                <!-- 侧边栏部分 -->
                <el-aside class="hander_left">
                    <el-menu :default-active="active" class="el-menu-vertical-demo" @select="handleOpen"
                        background-color="#ffffff" :unique-opened="true" text-color="#000" active-text-color="#017ded">
                        <el-menu-item index="1" @click="$router.push({ name: 'index' })">
                            <i class="el-icon-menu" style="color: #017ded;"></i>
                            <span slot="title">系统首页</span>
                        </el-menu-item>
                        <el-menu-item index="8" @click="$router.push({ name: 'page' })">
                            <i class="el-icon-menu" style="color: #017ded;"></i>
                            <span slot="title">页面内容</span>
                        </el-menu-item>
                        <el-menu-item index="2" @click="$router.push({ name: 'Journalism' })">
                            <i class="el-icon-menu" style="color: #017ded;"></i>
                            <span slot="title">新闻资讯</span>
                        </el-menu-item>
                        <el-submenu index="35">
                            <template slot="title">
                                <i class="el-icon-menu" style="color: #017ded;"></i>
                                <span>产品服务</span>
                            </template>
                            <el-menu-item index="35-8" @click="$router.push({ name: 'product' })">
                                <i class="el-icon-menu" style="color: #017ded;"></i>
                                <span slot="title">产品列表</span>
                            </el-menu-item>
                            <el-menu-item index="35-13" @click="$router.push({ name: 'xwgl' })">
                                <i class="el-icon-menu" style="color: #017ded;"></i>
                                <span slot="title">产品类别</span>
                            </el-menu-item>
                        </el-submenu>
                        <el-submenu index="34">
                            <template slot="title">
                                <i class="el-icon-menu" style="color: #017ded;"></i>
                                <span>解决方案</span>
                            </template>
                            <el-menu-item index="34-3" @click="$router.push({ name: 'jsgl' })">
                                <i class="el-icon-menu" style="color: #017ded;"></i>
                                <span slot="title">解决方案</span>
                            </el-menu-item>
                            <el-menu-item index="34-6" @click="$router.push({ name: 'czrz' })">
                                <i class="el-icon-menu" style="color: #017ded;"></i>
                                <span slot="title">方案类别</span>
                            </el-menu-item>
                        </el-submenu>
                        <el-submenu index="33">
                            <template slot="title">
                                <i class="el-icon-menu" style="color: #017ded;"></i>
                                <span>图片管理</span>
                            </template>
                            <el-menu-item index="33-16" @click="$router.push({ name: 'indexpic' })">
                                <i class="el-icon-menu" style="color: #017ded;"></i>
                                <span slot="title">首页大图</span>
                            </el-menu-item>
                            <el-menu-item index="33-11" @click="$router.push({ name: 'piclibrary' })">
                                <i class="el-icon-menu" style="color: #017ded;"></i>
                                <span slot="title">图片库</span>
                            </el-menu-item>
                        </el-submenu>
                        <el-submenu index="14">
                            <template slot="title">
                                <i class="el-icon-menu" style="color: #017ded;"></i>
                                <span>字典管理</span>
                            </template>
                            <el-menu-item index="14-1" @click="$router.push({ name: 'hzgl' })">
                                <i class="el-icon-menu" style="color: #017ded;"></i>
                                <span>字典类型</span>
                            </el-menu-item>
                            <el-menu-item index="14-2" @click="$router.push({ name: 'fkgl' })">
                                <i class="el-icon-menu" style="color: #017ded;"></i>
                                <span>字典值</span>
                            </el-menu-item>
                        </el-submenu>
                        <el-submenu index="15">
                            <template slot="title">
                                <i class="el-icon-menu" style="color: #017ded;"></i>
                                <span>服务支持</span>
                            </template>
                            <el-menu-item index="15-1" @click="$router.push({ name: 'customer' })">
                                <i class="el-icon-menu" style="color: #017ded;"></i>
                                <span>客户管理</span>
                            </el-menu-item>
                            <el-menu-item index="15-2" @click="$router.push({ name: 'sbgl' })">
                                <i class="el-icon-menu" style="color: #017ded;"></i>
                                <span>维修查询</span>
                            </el-menu-item>
                            <el-menu-item index="15-5" @click="$router.push({ name: 'dlrz' })">
                                <i class="el-icon-menu" style="color: #017ded;"></i>
                                <span slot="title">投诉建议</span>
                            </el-menu-item>
                            <el-menu-item index="15-6" @click="$router.push({ name: 'download' })">
                                <i class="el-icon-menu" style="color: #017ded;"></i>
                                <span slot="title">资料下载</span>
                            </el-menu-item>
                        </el-submenu>
                        <el-menu-item index="10" @click="$router.push({ name: 'links' })">
                            <i class="el-icon-menu" style="color: #017ded;"></i>
                            <span slot="title">友情链接</span>
                        </el-menu-item>
                    </el-menu>
                </el-aside>
                <!-- 主题内容部分 -->
                <el-main class="hander_right">
                    <router-view></router-view>
                </el-main>
            </el-container>
        </el-container>
        <el-dialog title="修改密码" :visible.sync="aqjc" :close-on-click-modal="false" :show-close="false" width="25%">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="70px" class="demo-ruleForm">
                <el-form-item label="用户名" prop="username">
                    <el-input v-model="ruleForm.username"></el-input>
                </el-form-item>
                <el-form-item label="新密码" prop="password">
                    <el-input :show-password="true" type="password" v-model="ruleForm.password"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="aqjc = false">返 回</el-button>
                <el-button type="primary" @click="queding">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                active: window.sessionStorage.getItem('active'),
                userName: window.sessionStorage.getItem('userName'),
                aqjc: false,
                ruleForm: {
                    username: null,
                    password: null
                },
                rules: {
                    username: [{ required: true, message: '用户名不能为空', trigger: 'blur' }],
                    password: [{ required: true, message: '密码不能为空', trigger: 'blur' }]
                }
            }
        },
        methods: {
            handleOpen(key) {
                this.active = key
            },
            tuichu() {
                window.localStorage.clear()
                window.sessionStorage.clear()
                this.$router.push({ name: 'login' })
                this.$message({
                    type: 'success',
                    message: '退出成功',
                    center: true,
                    offset: 50
                })
            },
            handleCommand(command) {
                if(command == 'a') {
                    this.tuichu()
                }else if(command == 'b') {
                    this.format()
                }
            },
            format() {
                this.aqjc = true
                // this.$confirm('此操作将永久清空数据, 是否继续?', '提示', {
                //     confirmButtonText: '确定',
                //     cancelButtonText: '取消',
                //     type: 'warning'
                // }).then(async () => {
                //     let res = await this.$http.post('/lg/FormatData.do')
                //     if (res.data.code == 200) {
                //         this.$message({
                //             type: 'success',
                //             message: '格式化成功！',
                //             center: true,
                //             offset: 50
                //         })
                //     }
                // }).catch(() => {

                // });
            },
            queding() {
                this.$refs.ruleForm.validate(async(valid) => {
                    if (valid) {
                        let res = await this.$http.post('/login/update',{
                            username: this.ruleForm.username,
                            password: this.ruleForm.password
                        })
                        if (res.data.code == 200) {
                            this.$message({
                                type: 'success',
                                message: '修改成功',
                                center: true,
                                offset: 50
                            })
                            this.aqjc = false
                        }
                    } else {
                        return false;
                    }
                })
            }
        },
        watch: {
            active: {
                immediate: true,
                handler() {
                    window.sessionStorage.setItem('active', this.active)
                    if (this.active == null) {
                        this.active = '1'
                    }
                }
            },
            aqjc: {
                handler() {
                    if(this.aqjc == false) {
                        this.$refs.ruleForm.resetFields();
                        this.ruleForm.username = ''
                        this.ruleForm.password = ''
                    }
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .home {
        width: 100%;
        height: 100%;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .box {
        height: 100% !important;
    }

    .hander {
        width: 100%;
        height: 12% !important;
        background-color: #007cee; /* 不支持线性的时候显示 */
        background-image: linear-gradient(to right, #007cee , #36c9ff);
        display: flex;
        align-items: center;
        justify-content: space-between;
        img {
            width: 35%;
        }

        .btn_tca {
            position: absolute;
            right: 120px;
            bottom: 20px;
            font-size: 18px;
            border-bottom: 3px solid #ffffff;
            padding-bottom: 5px;
            cursor: pointer;
            color: #ffffff;
        }

        .btn_tc {
            position: absolute;
            right: 20px;
            bottom: 20px;
            font-size: 18px;
            border-bottom: 3px solid #ffffff;
            padding-bottom: 5px;
            cursor: pointer;
            color: #ffffff;
        }
    }

    .hander_left {
        width: 12% !important;
        background-color: #fff;
    }

    .hander_right {
        // width: 88% !important;
        // height: 100% !important;
        padding: 20px !important;
        background-color: #ececec;
    }

    .el-menu-item {
        background-color: #ffffff !important;
    }

    .el-menu-item:hover {
        background-color: #d0d0d0 !important;
    }

    .el-menu-item.is-active{
        background-color: #e8f4ff !important;
    }

    .el-main {
        padding: 15px !important;
    }

    .el-dropdown-link {
        color: #ffffff;
        font-size: 16px;
        cursor: pointer;
    }
    .zuoyou {
        display: flex;
        align-items: center;
        justify-items: center;
    }
</style>
