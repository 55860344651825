<template>
  <div class="login">
    <div class="box">
      <div class="box_centent">
        <div class="box_centent_input">
          <img src="../assets/img/a2.jpg" />
          <input
            class="input"
            type="text"
            placeholder="请输入用户名"
            v-model="form.user"
            id="zh"
          />
        </div>
        <div class="box_centent_input">
          <img src="../assets/img/a1.jpg" />
          <input
            class="input"
            type="password"
            placeholder="请输入密码"
            v-model="form.password"
            id="mm"
          />
        </div>
      </div>
      <div class="box_buttom">
        <button class="btn" @click="denglu">
          登录<i class="el-icon-loading" v-show="open"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        user: "",
        password: ""
      },
      open: false
    }
  },
  mounted() {
    //绑定事件
    window.addEventListener("keydown", this.keyDown);
  },
  methods: {
    //如果是回车则执行登录方法
    keyDown(e) {
      if (e.keyCode == 13) {
        this.denglu();
      }
    },
    // 登录
    async denglu() {
      if (this.form.user == "") {
        this.$message({
          type: "warning",
          message: "请输入用户名",
          center: true,
          offset: 50,
        });
      } else if (this.form.password == "") {
        this.$message({
          type: "warning",
          message: "请输入密码",
          center: true,
          offset: 50
        });
      } else {
        this.open = true
        const res = await this.$http.post('/login', {
          username: this.form.user,
          password: this.form.password
        })
        if(res.data.code == 200) {
          this.open = false
          window.sessionStorage.setItem('userName', res.data.data.username)
          this.$router.push({ name: 'home' })
        }else{
          this.open = false
        }
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.keyDown, false); //卸载键盘事件
  }
};
</script>

<style lang="less" scoped>
.login {
  width: 100%;
  height: 100%;
  background-image: url("../assets/img/1920 937.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  -webkit-background-size: 100%;;
  -o-background-size: 100%;;
  background-position: center 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
}
.js {
  height: auto;
  position: absolute;
  top: 50px;
  right: 50px;
  color: #fff;
  font-size: 18px;
}
.box_footer {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 80px;
  span {
    font-size: 16px;
    color: #fff;
  }
}
.box_title {
  width: 100%;
  text-align: center;
  padding-top: 100px;
  // position: absolute;
  // top: 50px;
  // left: 50px;
  font-size: 33px;
  color: #ffffff;
  .a {
    font-size: 80px;
    color: red;
  }
  .b {
    font-size: 50px;
  }
  .c {
    color: red;
  }
  strong {
    margin-left: 20px;
  }
}
.box {
  width: 300px;
  height: 300px;
  position: absolute;
  left: 690px;
  top: 540px;
  transform: translate(50%, -50%);
  .box_top {
    width: 100%;
    height: 60px;
    margin-top: 35px;
    font-size: 25px;
    color: #ffffff;
    font-weight: 700;
    font-family: "黑体";
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .box_centent {
    width: 100%;
    height: auto;
    .box_centent_input {
      // text-align: center;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      img {
        width: 44px;
        height: 44px;
        position: absolute;
        top: 0;
        left: -44px;
      }
    }
    .input {
      width: 100%;
      height: 40px;
      outline: none;
      font-size: 15px;
      color: #000;
      border-radius: 5px;
      padding: 0 10px 0 10px;
      border: 2px solid #ffffff;
      background-color:#fff;
    }
    /* Safari，Chrome WebKit browsers */ 
    .input::-webkit-input-placeholder {
      color: #000;
    }
    /* 火狐 Mozilla Firefox 4 to 18 */ 
    .input:-moz-placeholder {
      color: #000;
    }
    /* 火狐 Mozilla Firefox 19+ */ 
    .input::-moz-placeholder {
      color: #000;
    }
    /* Internet Explorer 10+ */ 
    .input:-ms-input-placeholder {
      color: #000;
    }
  }
  .box_buttom {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    span {
      color: #fff;
      font-size: 13px;
      margin-top: 10px;
    }
    .btn {
      width: 90%;
      height: 40px;
      border-radius: 5px;
      font-size: 15px;
      border: none;
      cursor: pointer;
      color: #ffffff;
      background-color: #009aec;
      margin-top: 30px;
      outline: none;
    }
    .btn:hover {
      background-color: #0094de;
    }
  }
}
.kuan {
  width: 50px;
  font-size: 15px;
  text-align: right;
  margin-right: 10px;
}
.el-icon-loading {
  margin-left: 5px;
}
.fnts {
  color: #656668;
  font-size: 16px;
}
</style>