<template>
  <div class="edit">
    <div class="edit_box">
        <el-form :model="bjruleForm" :rules="bjrules" ref="bjruleForm" label-width="auto" class="demo-ruleForm">
            <el-form-item label="资讯标题" prop="name">
                <el-input v-model="bjruleForm.name"></el-input>
            </el-form-item>
            <el-form-item label="标题颜色" prop="color">
                <el-color-picker v-model="bjruleForm.color"></el-color-picker>
            </el-form-item>
            <el-form-item label="资讯图片">
                <el-upload action="" :limit="1" ref="bjupdate" :file-list="fileList" v-model="bjruleForm.imgbase"
                    :auto-upload="false" list-type="picture-card" :on-change="bjgetFilea"
                    :on-remove="bjhandleRemove">
                    <i class="el-icon-plus"></i>
                </el-upload>
            </el-form-item>
            <el-form-item label="资讯内容" prop="content">
                <quill-editor class="quill" v-model="bjruleForm.content" ref="myQuillEditor" :options="editorOption">
                </quill-editor>
            </el-form-item>
            <el-form-item label="是否热门" prop="sfrm">
                <el-radio-group v-model="bjruleForm.sfrm">
                    <el-radio label="0">否</el-radio>
                    <el-radio label="1">是</el-radio>
                </el-radio-group>
            </el-form-item>
        </el-form>
        <div class="footer">
            <el-button @click="$router.push({ name: 'xwgl' })">返 回</el-button>
            <el-button type="primary" @click="update">确 定</el-button>
        </div>
    </div> 
  </div> 
</template> 

<script>
import { quillEditor } from "vue-quill-editor"; //调用编辑器
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
export default {
    data() {
        return {
            id: this.$route.query.id,
            editorOption: {},
            bjruleForm: { //编辑数据
                name: '',
                color: '#000000',
                content: '',
                imgbase: '',
                sfrm: '0'
            }, //编辑验证
            bjrules: {
                name: [{
                    required: true,
                    message: '请输入资讯标题',
                    trigger: 'blur'
                }],
                content: [{
                    required: true,
                    message: '请输入资讯内容',
                    trigger: 'blur'
                }]
            },
            fileList: [] //编辑标题图片数组
        }
    },
    components: {
        quillEditor         //注册富文本组件
    },
    created() {
        this.list()
    },
    mounted() {
        this.$refs.myQuillEditor.quill.enable(false); //取消vue-quill-editor富文本有值时自动获取焦点问题
        setTimeout(() => {  //两秒后富文本自动再获取到焦点
            this.$refs.myQuillEditor.quill.enable(true);
        }, 1000)
    },
    methods: {
        async list() {
            let res = await this.$http.post('/ad/getAdsin.do', {
                iId: this.id
            })
            if (res.data.code == 200) {
                let obj = {
                    url: ''
                }
                this.bjruleForm.name = res.data.list[0].records[0].iname
                this.bjruleForm.color = res.data.list[0].records[0].fontColor
                this.bjruleForm.content = res.data.list[0].records[0].icotent
                this.bjruleForm.sfrm = String(res.data.list[0].records[0].ishot)
                if (res.data.list[0].records[0].picUrl) {
                    obj.url = res.data.list[0].records[0].picUrl
                    this.bjruleForm.imgbase = res.data.list[0].records[0].picUrl
                    this.fileList.push(obj)
                }
            }
        },
        update() { //编辑提交
            this.$refs.bjruleForm.validate(async(valid) => {
                if (valid) {
                    let res = await this.$http.post('/ad/updateAdsin.do', {
                        iId: this.id,
                        optUser: window.sessionStorage.getItem('userName'),
                        iname: this.bjruleForm.name,
                        fontColor: this.bjruleForm.color,
                        ishot: this.bjruleForm.sfrm,
                        picUrl: this.bjruleForm.imgbase,
                        icotent: this.bjruleForm.content
                    })
                    if (res.data.code = 200) {
                        let info = res.data.errMsg
                        this.$message({
                            type: 'success',
                            message: info,
                            center: true,
                            offset: 50
                        })
                    }
                    this.$router.push({ name: 'xwgl' })
                } else {
                    return false;
                }
            })
        },
        bjgetFilea(file, fileList) {
            this.getBase64(file.raw).then((res) => {
                const params = res.split(',')
                this.bjruleForm.imgbase = params[1]
                if (params.length > 0) {
                    this.strimagebase64 = params[1]
                }
            })
        },
        bjhandleRemove(file, fileList) {
            this.bjruleForm.imgbase = ''
        },
        // 公共方法 -> 获取图片转base64
        getBase64(file) {
            return new Promise(function(resolve, reject) {
                const reader = new FileReader()
                let imgResult = ''
                reader.readAsDataURL(file)
                reader.onload = function() {
                    imgResult = reader.result
                }
                reader.onerror = function(error) {
                    reject(error)
                }
                reader.onloadend = function() {
                    resolve(imgResult)
                }
            })
        }
    }
}
</script>

<style scoped>
.edit {
    padding: 50px;
}
.edit_box {
    width: 60%;
}
.footer {
    width: 100%;
    text-align: center;
}
.el-form-item {
    margin-bottom: 10px;
}
.edit>>>.ql-editor{
    height: 500px !important;
}
</style>