<template>
  <div class="box">
    <div class="search">
        <el-input placeholder="请输入新闻标题" class="width" v-model="Switch.name" clearable></el-input>
        <el-select v-model="Switch.type" clearable placeholder="请选择新闻类别" class="width left">
          <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <el-button type="primary" class="left" @click="list">查询</el-button>
        <el-button type="primary" @click="$router.push({ name: 'Journalismadd' })" class="left">新增</el-button>
    </div>
    <div class="table">
      <el-table
        :data="tableData"
        v-loading="loading"
        border>
        <el-table-column prop="id" label="序号" min-width="5%"></el-table-column>
        <el-table-column prop="title" label="新闻标题" min-width="25%" show-overflow-tooltip>
          <template slot-scope="scope">
            <span style="cursor: pointer;" @click="Jump(scope.row.id, scope.row.typeName)">{{scope.row.title}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="outline" label="摘要" min-width="25%" show-overflow-tooltip></el-table-column>
        <el-table-column prop="views" label="浏览量" min-width="5%" show-overflow-tooltip></el-table-column>
        <el-table-column prop="keyWord" label="关键字" min-width="8%" show-overflow-tooltip></el-table-column>
        <el-table-column prop="typeName" label="类别" min-width="9" show-overflow-tooltip></el-table-column>
        <el-table-column prop="isRecommend" label="是否推荐" min-width="7%" show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="scope.row.isRecommend == 0 && scope.row.isRecommend == ''">不推荐</span>
            <span v-else-if="scope.row.isRecommend == 1">推荐</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="11%" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.row.id)">编辑</el-button>
            <el-button class="left" size="mini" type="danger" @click="del(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="fy">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          background
          layout="prev, pager, next"
          :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import encrytion from '../../plugins/encrytion'
import URL from '../../plugins/config'
export default {
  data() {
    return {
      Switch: {
        name: '',
        type: ''
      },
      options: [],
      loading: false,
      tableData: [],
      currentPage: 1,
      total: 0
    }
  },
  created() {
    this.list()
    this.leibie()
  },
  methods: {
    // 列表数据
    async list() {
      this.loading = true
      let res = await this.$http.post('/news/list', {
        title: this.Switch.name ? this.Switch.name : null,
        type: this.Switch.type ? this.Switch.type : null,
        current: this.currentPage,
        size: 10
      })
      this.loading = false
      if(res.data.code == 200) {
        this.tableData = res.data.data
        this.total = res.data.count
      }
    },
    async leibie() {
      let res = await this.$http.post('/dict/getDictByType', {
        type: 'sys_news'
      })
      if(res.data.code == 200) {
        this.options = res.data.data
      }
    },
    Jump(id, type) {
      var ids = encrytion.aesEncrypt(id.toString(), URL.jm)
      if(type == '公司新闻') {
        var types = encrytion.aesEncrypt('0', URL.jm)
      }else if(type == '业界资讯') {
        var types = encrytion.aesEncrypt('1', URL.jm)
      }else if(type == '成功应用') {
        var types = encrytion.aesEncrypt('2', URL.jm)
      }
      window.open(`${URL.URL}/newss?id=${ids}&typeValue=${types}`)
    },
    async del(id) {
      this.$confirm('是否删除此条数据？', '提醒', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确认',
        cancelButtonText: '取消'
      }).then(async () => {
        let res = await this.$http.post('/news/delete', {
          ids: id
        })
        if(res.data.code == 200) {
          this.$message({
            type: 'success',
            message: '删除成功',
            center: true,
            offset: 50
          })
          this.list()
        }
      })
      .catch(action => {
        return false;
      })
    },
    async handleEdit(id) {
      this.$router.push({ name: 'Journalismadd', query: { id: id } })
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage
      this.list()
    }
  }
}
</script>

<style lang="less" scoped>
.box{
  padding: 10px;
  background-color: #fff;
}
.width {
    width: 250px !important;
}
.left {
  margin-left: 10px;
}
</style>