<template>
  <div class="box">
    <!-- 搜索区域 -->
    <div class="search">
      <el-select v-model="Switch.wz" class="width" clearable placeholder="菜单位置">
        <el-option label="上方" value="1"></el-option>
        <el-option label="上下皆在" value="2"></el-option>
        <el-option label="下方" value="3"></el-option>
      </el-select>
      <el-select v-model="Switch.zt" class="left width" clearable placeholder="菜单状态">
        <el-option label="正常" value="0"></el-option>
        <el-option label="禁用" value="1"></el-option>
      </el-select>
      <el-input v-model="Switch.name" class="left width" clearable placeholder="菜单名称"></el-input>
      <el-button type="primary" class="left" @click="list">查询</el-button>
      <el-button type="primary" class="left" @click="xzcbl = true">新增</el-button>
    </div>
    <div class="table">
      <!-- 列表 -->
      <el-table
        :data="tableData"
        v-loading="loading"
        style="width: 100%;margin-bottom: 20px;"
        row-key="id"
        border
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
        <el-table-column prop="id" label="序号" width="80"></el-table-column>
        <el-table-column
          prop="name"
          label="菜单名称">
        </el-table-column>
        <el-table-column
          prop="url"
          label="菜单路径">
        </el-table-column>
        <el-table-column
          prop="sort"
          label="排序">
        </el-table-column>
        <el-table-column
          prop="position"
          label="菜单位置">
          <template slot-scope="scope">
            <span v-if="scope.row.position == '1'">上方</span>
            <span v-else-if="scope.row.position == '2'">上下皆在</span>
            <span v-else-if="scope.row.position == '3'">下方</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="status"
          label="状态">
          <template slot-scope="scope">
            <span v-if="scope.row.status == '0'">正常</span>
            <span v-else-if="scope.row.status == '1'">禁用</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.row.id)">编辑</el-button>
            <el-button class="left" size="mini" type="danger" @click="del(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 新增侧边栏 -->
      <el-dialog
        :title="id ? '编辑' : '新增'"
        :show-close="false"
        :visible.sync="xzcbl"
        :close-on-click-modal="false"
        width="25%"
        center>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="菜单名称" class="widthd" prop="name">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="关键字" class="widthd" prop="keywords">
            <el-input v-model="ruleForm.keywords"></el-input>
          </el-form-item>
          <el-form-item label="描述" class="widthd" prop="description">
            <el-input v-model="ruleForm.description"></el-input>
          </el-form-item>
          <el-form-item label="级别" prop="level">
            <el-select v-model="ruleForm.level" :disabled="id ? true : false" @change="update" class="widthd" placeholder="">
              <el-option label="父级" value="0"></el-option>
              <el-option label="子级" value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="父级" prop="parent" v-if="ruleForm.level == '1'">
            <el-select v-model="ruleForm.parent" class="widthd" placeholder="">
              <el-option v-for="item in parent" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="跳转路径" class="widthd" prop="url">
            <el-input v-model="ruleForm.url"></el-input>
          </el-form-item>
          <el-form-item label="菜单位置" prop="position">
            <el-select v-model="ruleForm.position" class="widthd" placeholder="">
              <el-option label="上方" value="1"></el-option>
              <el-option label="上下皆在" value="2"></el-option>
              <el-option label="下方" value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="排序" class="widthd" prop="sort">
            <el-input v-model="ruleForm.sort"></el-input>
          </el-form-item>
          <el-form-item label="状态" prop="status">
            <el-select v-model="ruleForm.status" class="widthd" placeholder="">
              <el-option label="正常" value="0"></el-option>
              <el-option label="禁用" value="1"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="xzcbl = false">取 消</el-button>
          <el-button type="primary" @click="queding">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: null,
      Switch: {
        zt: '',
        wz: '',
        name: ''
      },
      parent: [],
      xzcbl: false,
      tableData: [],
      loading: false,
      ruleForm: {
        name: "",
        level: "",
        url: "",
        position: "",
        sort: "",
        status: "",
        parent: "",
        keywords: "",
        description: ""
      },
      rules: {
        name: [{ required: true, message: "请输入菜单名称", trigger: "blur" }],
        level: [{ required: true, message: "请选择级别", trigger: "change" }],
        parent: [{ required: true, message: "请选择父级", trigger: "change" }],
        url: [{ required: true, message: "请输入跳转路径", trigger: "blur" }],
        position: [{ required: true, message: "请选择菜单位置", trigger: "change" }],
        sort: [{ required: true, message: "请输入排序", trigger: "blur" }],
        status: [{ required: true, message: "请选状态", trigger: "change" }]
      }
    }
  },
  created() {
    this.list()
    this.menu()
  },
  methods: {
    // 列表数据
    async list() {
      this.loading = true
      let res = await this.$http.post('/webMenu/list', {
        name: this.Switch.name ? this.Switch.name : null,
        status: this.Switch.zt ? this.Switch.zt : null,
        positionB: this.Switch.wz ? this.Switch.wz : null,
        currentPage: this.currentPage,
        size: 10
      })
      this.loading = false
      if(res.data.code == 200) {
        this.tableData = res.data.data
        this.total = res.data.count
      }
    },
    // 父级菜单数据
    async menu() {
      let res = await this.$http.post('/webMenu/list', {
        parentId: 0
      })
      if(res.data.code == 200) {
        this.parent = res.data.data
      }
    },
    // 获取编辑数据
    async handleEdit(id) {
      this.id = id
      let res = await this.$http.post('/webMenu/list', {
        id: id
      })
      if(res.data.code == 200) {
        this.ruleForm.name = res.data.data[0].name
        this.ruleForm.url = res.data.data[0].url
        this.ruleForm.position = String(res.data.data[0].position)
        this.ruleForm.sort = res.data.data[0].sort
        this.ruleForm.keywords = res.data.data[0].keyword
        this.ruleForm.description = res.data.data[0].describe
        this.ruleForm.status = String(res.data.data[0].status)
        this.ruleForm.parent = res.data.data[0].parentId
        res.data.data[0].parentId == 0 ? this.ruleForm.level = "0" : this.ruleForm.level = "1"
        this.xzcbl = true
      }
    },
    // 删除
    del(id) {
      this.$confirm('是否删除此条数据？', '提醒', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确认',
        cancelButtonText: '取消'
      }).then(async () => {
        let res = await this.$http.post('/webMenu/delete', {
          ids: id
        })
        if(res.data.code == 200) {
          this.$message({
            type: 'success',
            message: '删除成功',
            center: true,
            offset: 50
          })
          this.list()
        }
      })
      .catch(action => {
        return
      })
    },
    update() {
      this.ruleForm.parent = ""
    },
    // 新增侧边栏
    queding() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let res = await this.$http.post('/webMenu/saveOrUpdate', {
            id: this.id ? this.id : null,
            name: this.ruleForm.name,
            position: this.ruleForm.position,
            sort: this.ruleForm.sort,
            status: this.ruleForm.status,
            url: this.ruleForm.url,
            parentId: this.ruleForm.parent ? this.ruleForm.parent : 0,
            url: this.ruleForm.url,
            keyword: this.ruleForm.keywords,
            describe: this.ruleForm.description
          })
          if(res.data.code == 200) {
            this.$message({
              type: 'success',
              message: this.id ? '编辑成功' : '添加成功',
              center: true,
              offset: 50
            })
            this.list()
            this.xzcbl = false
          }
        }
      })
    }
  },
  watch: {
    xzcbl: {
      handler(news, old) {
        if(news == false) {
          this.$refs.ruleForm.resetFields()
          this.ruleForm.name = ""
          this.ruleForm.url = ""
          this.ruleForm.position = ""
          this.ruleForm.sort = ""
          this.ruleForm.status = ""
          this.ruleForm.parent = ""
          this.ruleForm.level = ""
          this.ruleForm.keywords = ""
          this.ruleForm.description = ""
          this.id = null
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  padding: 10px;
  background-color: #fff;
}
.camera_outer {
  position: relative;
  overflow: hidden;
  background: url("../assets/img/zp.png") no-repeat center;
  background-size: 100%;
  video,
  canvas,
  .tx_img {
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  .btn_camera {
    position: absolute;
    bottom: 4px;
    left: 0;
    right: 0;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.3);
    line-height: 50px;
    text-align: center;
    color: #ffffff;
  }
  .bg_r_img {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }
  .img_bg_camera {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    img {
      width: 100%;
      height: 100%;
    }
    .img_btn_camera {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 50px;
      line-height: 50px;
      text-align: center;
      background-color: rgba(0, 0, 0, 0.3);
      color: #ffffff;
      .loding_img {
        width: 50px;
        height: 50px;
      }
    }
  }
}
</style>