const crypto = require('crypto');

export default {
    aesEncrypt(data, key){  //加密
        try{
            var salt =  key.toString() + 'manbanzhen';    // 定义盐值
            const cipher = crypto.createCipher('aes192', salt);   // 采用aes192加密方式
            var crypted = cipher.update(data, 'utf8', 'hex');     // 加密
            crypted += cipher.final('hex');
            return crypted;
        }catch (err) {
            console.log('加密失败')
        }
    },

  aesDecrypt(encrypted, key) {  //解密
        try{
            var salt =  key.toString() + 'manbanzhen';    // 定义盐值
            const decipher = crypto.createDecipher('aes192', salt);   // 解密 aes192
            var decrypted = decipher.update(encrypted, 'hex', 'utf8');    //解密
            decrypted += decipher.final('utf8');
            return decrypted;
        }catch (err) {
            console.log('解密错误')
        }
    }
}