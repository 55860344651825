import Vue from 'vue'
import {
    Button,
    MessageBox,
    Message,
    Container,
    Header,
    Aside,
    Main,
    Menu,
    MenuItem,
    MenuItemGroup,
    Submenu,
    Radio,
    Select,
    Option,
    Input,
    Table,
    TableColumn,
    Pagination,
    Form,
    FormItem,
    Dialog,
    Upload,
    Tabs,
    TabPane,
    DatePicker,
    Loading,
    Popconfirm,
    Tree,
    RadioGroup,
    Switch,
    ColorPicker,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    Cascader,
    Row,
    Col,
    Avatar
} from 'element-ui'
Vue.use(Button)
Vue.use(Container)
Vue.use(Header)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Submenu)
Vue.use(Radio)
Vue.use(Select)
Vue.use(Option)
Vue.use(Input)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Pagination)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Dialog)
Vue.use(Upload)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(DatePicker)
Vue.use(Loading)
Vue.use(Popconfirm)
Vue.use(Tree)
Vue.use(RadioGroup)
Vue.use(Switch)
Vue.use(ColorPicker)
Vue.use(Dropdown)
Vue.use(DropdownItem)
Vue.use(DropdownMenu)
Vue.use(Cascader)
Vue.use(Row)
Vue.use(Col)
Vue.use(Avatar)
Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$prompt = MessageBox.prompt;