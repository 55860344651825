<template>
    <div class="sjtb">
        <div class="sjtb_box">
            <ul>
                <li v-for="item in list" :key="item.id" @click="dianji(item.id)">
                    <span>{{ item.name }}</span>
                </li>
            </ul>
        </div>
        <el-dialog
          title="数据同步"
          :visible.sync="sjtb"
          :close-on-click-modal="false"
          width="25%"
          center>
          <div class="box">
              <button @click="addbd">添加至本地</button>
              <button @click="gxbd">更新至本地</button>
              <button @click="qktjbd">清空并添加至本地</button>
          </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
               sjtb: false,
               id: '',
               list: [
                   { id: 1, name: '小区信息' },
                   { id: 2, name: '楼栋信息' },
                   { id: 3, name: '房屋信息' },
                   { id: 4, name: '出入口信息' },
                   { id: 5, name: '杆件管理' },
                   { id: 6, name: '住户库' },
                   { id: 7, name: '车辆信息' },
                   { id: 8, name: '抓拍机' },
                   { id: 9, name: '门禁信息' },
                   { id: 10, name: '车辆道闸' },
               ]
            }
        },
        methods: {
            async dianji(id) {
                this.id = id
                this.sjtb = true
            },
            // 添加至本地
            async addbd() {
                switch (this.id) {
                    case 1:
                        const load = this.$loading({
                            lock: true,
                            text: 'Loading',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });
                        let res = await this.$http.post('/dsc/tbXqxx.do', {
                            type: 1
                        })
                        if(res.data.code == 200) {
                            load.close();
                            let info = res.data.data.info
                            this.$message({
                              type: 'success',
                              message: info,
                              center: true,
                              offset: 50
                            })
                        }
                        break;
                    case 2:
                        const loada = this.$loading({
                            lock: true,
                            text: 'Loading',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });
                        let resa = await this.$http.post('/dsc/tbLdxx.do', {
                            type: 1
                        })
                        if(resa.data.code == 200) {
                            loada.close();
                            let info = resa.data.data.info
                            this.$message({
                              type: 'success',
                              message: info,
                              center: true,
                              offset: 50
                            })
                        }
                        break;
                    case 3:
                        const fw = this.$loading({
                            lock: true,
                            text: 'Loading',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });
                        let resfw = await this.$http.post('/dsc/tbFwxx.do', {
                            type: 1
                        })
                        if(resfw.data.code == 200) {
                            fw.close();
                            let info = resfw.data.data.info
                            this.$message({
                              type: 'success',
                              message: info,
                              center: true,
                              offset: 50
                            })
                        }
                        break;
                    case 4:
                        const crk = this.$loading({
                            lock: true,
                            text: 'Loading',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });
                        let rescrk = await this.$http.post('/dsc/tbCrkxx.do', {
                            type: 1
                        })
                        if(rescrk.data.code == 200) {
                            crk.close();
                            let info = rescrk.data.data.info
                            this.$message({
                              type: 'success',
                              message: info,
                              center: true,
                              offset: 50
                            })
                        }
                        break;
                    case 5:
                        const gjxx = this.$loading({
                            lock: true,
                            text: 'Loading',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });
                        let resgjxx = await this.$http.post('/dsc/tbGjxx.do', {
                            type: 1
                        })
                        if(resgjxx.data.code == 200) {
                            gjxx.close();
                            let info = resgjxx.data.data.info
                            this.$message({
                              type: 'success',
                              message: info,
                              center: true,
                              offset: 50
                            })
                        }
                        break;
                    case 6:
                        const zhxx = this.$loading({
                            lock: true,
                            text: 'Loading',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });
                        let reszhxx = await this.$http.post('/dsc/tbZhxx.do', {
                            type: 1
                        })
                        if(reszhxx.data.code == 200) {
                            zhxx.close();
                            let info = reszhxx.data.data.info
                            this.$message({
                              type: 'success',
                              message: info,
                              center: true,
                              offset: 50
                            })
                        }
                        break;
                    case 7:
                        const clxx = this.$loading({
                            lock: true,
                            text: 'Loading',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });
                        let resclxx = await this.$http.post('/dsc/tbClxx.do', {
                            type: 1
                        })
                        if(resclxx.data.code == 200) {
                            clxx.close();
                            let info = resclxx.data.data.info
                            this.$message({
                              type: 'success',
                              message: info,
                              center: true,
                              offset: 50
                            })
                        }
                        break;
                    case 8:
                        const zpjxx = this.$loading({
                            lock: true,
                            text: 'Loading',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });
                        let reszpjxx = await this.$http.post('/dsc/tbZpjxx.do', {
                            type: 1
                        })
                        if(reszpjxx.data.code == 200) {
                            zpjxx.close();
                            let info = reszpjxx.data.data.info
                            this.$message({
                              type: 'success',
                              message: info,
                              center: true,
                              offset: 50
                            })
                        }
                        break;
                    case 9:
                        const mjxx = this.$loading({
                            lock: true,
                            text: 'Loading',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });
                        let resmjxx = await this.$http.post('/dsc/tbMjxx.do', {
                            type: 1
                        })
                        if(resmjxx.data.code == 200) {
                            mjxx.close();
                            let info = resmjxx.data.data.info
                            this.$message({
                              type: 'success',
                              message: info,
                              center: true,
                              offset: 50
                            })
                        }
                        break;
                    case 10:
                        const dzxx = this.$loading({
                            lock: true,
                            text: 'Loading',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });
                        let resdzxx = await this.$http.post('/dsc/tbDzxx.do', {
                            type: 1
                        })
                        if(resdzxx.data.code == 200) {
                            dzxx.close();
                            let info = resdzxx.data.data.info
                            this.$message({
                              type: 'success',
                              message: info,
                              center: true,
                              offset: 50
                            })
                        }
                        break;
                }
            },
            // 更新至本地
            async gxbd() {
                switch (this.id) {
                    case 1:
                    const loadb = this.$loading({
                        lock: true,
                        text: 'Loading',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                        let res = await this.$http.post('/dsc/tbXqxx.do', {
                            type: 2
                        })
                        if(res.data.code == 200) {
                            loadb.close();
                            let info = res.data.data.info
                            this.$message({
                              type: 'success',
                              message: info,
                              center: true,
                              offset: 50
                            })
                        }
                        break;
                    case 2:
                        const loadc = this.$loading({
                            lock: true,
                            text: 'Loading',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });
                            let resa = await this.$http.post('/dsc/tbLdxx.do', {
                                type: 2
                            })
                            if(resa.data.code == 200) {
                                loadc.close();
                                let info = resa.data.data.info
                                this.$message({
                                  type: 'success',
                                  message: info,
                                  center: true,
                                  offset: 50
                                })
                            }
                        break;
                    case 3:
                        const fw = this.$loading({
                            lock: true,
                            text: 'Loading',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });
                            let fwres = await this.$http.post('/dsc/tbFwxx.do', {
                                type: 2
                            })
                            if(fwres.data.code == 200) {
                                fw.close();
                                let info = fwres.data.data.info
                                this.$message({
                                  type: 'success',
                                  message: info,
                                  center: true,
                                  offset: 50
                                })
                            }
                        break;
                    case 4:
                        const crk = this.$loading({
                            lock: true,
                            text: 'Loading',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });
                            let crkres = await this.$http.post('/dsc/tbCrkxx.do', {
                                type: 2
                            })
                            if(crkres.data.code == 200) {
                                crk.close();
                                let info = crkres.data.data.info
                                this.$message({
                                  type: 'success',
                                  message: info,
                                  center: true,
                                  offset: 50
                                })
                            }
                        break;
                    case 5:
                        const gjxx = this.$loading({
                            lock: true,
                            text: 'Loading',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });
                            let gjxxres = await this.$http.post('/dsc/tbGjxx.do', {
                                type: 2
                            })
                            if(gjxxres.data.code == 200) {
                                gjxx.close();
                                let info = gjxxres.data.data.info
                                this.$message({
                                  type: 'success',
                                  message: info,
                                  center: true,
                                  offset: 50
                                })
                            }
                        break;
                    case 6:
                        const zhxx = this.$loading({
                            lock: true,
                            text: 'Loading',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });
                            let zhxxres = await this.$http.post('/dsc/tbZhxx.do', {
                                type: 2
                            })
                            if(zhxxres.data.code == 200) {
                                zhxx.close();
                                let info = zhxxres.data.data.info
                                this.$message({
                                  type: 'success',
                                  message: info,
                                  center: true,
                                  offset: 50
                                })
                            }
                        break;
                    case 7:
                        const clxx = this.$loading({
                            lock: true,
                            text: 'Loading',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });
                            let resclxx = await this.$http.post('/dsc/tbClxx.do', {
                                type: 2
                            })
                            if(resclxx.data.code == 200) {
                                clxx.close();
                                let info = resclxx.data.data.info
                                this.$message({
                                  type: 'success',
                                  message: info,
                                  center: true,
                                  offset: 50
                                })
                            }
                        break;
                    case 8:
                        const zpjxx = this.$loading({
                            lock: true,
                            text: 'Loading',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });
                            let reszpjxx = await this.$http.post('/dsc/tbZpjxx.do', {
                                type: 2
                            })
                            if(reszpjxx.data.code == 200) {
                                zpjxx.close();
                                let info = reszpjxx.data.data.info
                                this.$message({
                                  type: 'success',
                                  message: info,
                                  center: true,
                                  offset: 50
                                })
                            }
                        break;
                    case 9:
                        const mjxx = this.$loading({
                            lock: true,
                            text: 'Loading',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });
                            let resmjxx = await this.$http.post('/dsc/tbMjxx.do', {
                                type: 2
                            })
                            if(resmjxx.data.code == 200) {
                                mjxx.close();
                                let info = resmjxx.data.data.info
                                this.$message({
                                  type: 'success',
                                  message: info,
                                  center: true,
                                  offset: 50
                                })
                            }
                        break;
                    case 10:
                        const dzxx = this.$loading({
                            lock: true,
                            text: 'Loading',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });
                            let resdzxx = await this.$http.post('/dsc/tbDzxx.do', {
                                type: 2
                            })
                            if(resdzxx.data.code == 200) {
                                dzxx.close();
                                let info = resdzxx.data.data.info
                                this.$message({
                                  type: 'success',
                                  message: info,
                                  center: true,
                                  offset: 50
                                })
                            }
                        break;
                }
            },
            // 清空并添加至本地
            async qktjbd() {
                switch (this.id) {
                    case 1:
                    const loadd = this.$loading({
                        lock: true,
                        text: 'Loading',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                        let res = await this.$http.post('/dsc/tbXqxx.do', {
                            type: 3
                        })
                        if(res.data.code == 200) {
                           loadd.close();
                           let info = res.data.data.info
                           this.$message({
                             type: 'success',
                             message: info,
                             center: true,
                             offset: 50
                           })
                        }
                        break;
                    case 2:
                        const loade = this.$loading({
                            lock: true,
                            text: 'Loading',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });
                            let resa = await this.$http.post('/dsc/tbLdxx.do', {
                                type: 3
                            })
                            if(resa.data.code == 200) {
                               loade.close();
                               let info = resa.data.data.info
                               this.$message({
                                 type: 'success',
                                 message: info,
                                 center: true,
                                 offset: 50
                               })
                            }
                        break;
                    case 3:
                        const fw = this.$loading({
                            lock: true,
                            text: 'Loading',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });
                            let fwres = await this.$http.post('/dsc/tbFwxx.do', {
                                type: 3
                            })
                            if(fwres.data.code == 200) {
                               fw.close();
                               let info = fwres.data.data.info
                               this.$message({
                                 type: 'success',
                                 message: info,
                                 center: true,
                                 offset: 50
                               })
                            }
                        break;
                    case 4:
                        const crk = this.$loading({
                            lock: true,
                            text: 'Loading',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });
                            let crkres = await this.$http.post('/dsc/tbCrkxx.do', {
                                type: 3
                            })
                            if(crkres.data.code == 200) {
                               crk.close();
                               let info = crkres.data.data.info
                               this.$message({
                                 type: 'success',
                                 message: info,
                                 center: true,
                                 offset: 50
                               })
                            }
                        break;
                    case 5:
                        const gjxx = this.$loading({
                            lock: true,
                            text: 'Loading',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });
                            let gjxxres = await this.$http.post('/dsc/tbGjxx.do', {
                                type: 3
                            })
                            if(gjxxres.data.code == 200) {
                               gjxx.close();
                               let info = gjxxres.data.data.info
                               this.$message({
                                 type: 'success',
                                 message: info,
                                 center: true,
                                 offset: 50
                               })
                            }
                        break;
                    case 6:
                        const zhxx = this.$loading({
                            lock: true,
                            text: 'Loading',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });
                            let zhxxres = await this.$http.post('/dsc/tbZhxx.do', {
                                type: 3
                            })
                            if(zhxxres.data.code == 200) {
                               zhxx.close();
                               let info = zhxxres.data.data.info
                               this.$message({
                                 type: 'success',
                                 message: info,
                                 center: true,
                                 offset: 50
                               })
                            }
                        break;
                    case 7:
                        const clxx = this.$loading({
                            lock: true,
                            text: 'Loading',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });
                            let clxxres = await this.$http.post('/dsc/tbClxx.do', {
                                type: 3
                            })
                            if(clxxres.data.code == 200) {
                               clxx.close();
                               let info = clxxres.data.data.info
                               this.$message({
                                 type: 'success',
                                 message: info,
                                 center: true,
                                 offset: 50
                               })
                            }
                        break;
                    case 8:
                        const zpjxx = this.$loading({
                            lock: true,
                            text: 'Loading',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });
                            let reszpjxx = await this.$http.post('/dsc/tbZpjxx.do', {
                                type: 3
                            })
                            if(reszpjxx.data.code == 200) {
                               zpjxx.close();
                               let info = reszpjxx.data.data.info
                               this.$message({
                                 type: 'success',
                                 message: info,
                                 center: true,
                                 offset: 50
                               })
                            }
                        break;
                    case 9:
                        const mjxx = this.$loading({
                            lock: true,
                            text: 'Loading',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });
                            let resmjxx = await this.$http.post('/dsc/tbMjxx.do', {
                                type: 3
                            })
                            if(resmjxx.data.code == 200) {
                               mjxx.close();
                               let info = resmjxx.data.data.info
                               this.$message({
                                 type: 'success',
                                 message: info,
                                 center: true,
                                 offset: 50
                               })
                            }
                        break;
                    case 10:
                        const dzxx = this.$loading({
                            lock: true,
                            text: 'Loading',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });
                            let resdzxx = await this.$http.post('/dsc/tbDzxx.do', {
                                type: 3
                            })
                            if(resdzxx.data.code == 200) {
                               dzxx.close();
                               let info = resdzxx.data.data.info
                               this.$message({
                                 type: 'success',
                                 message: info,
                                 center: true,
                                 offset: 50
                               })
                            }
                        break;
                }
            }
        }
    }
</script>

<style scoped>
    .sjtb {
        width: 100%;
    }
    .sjtb_box {
        width: 95%;
        margin: 40px auto;
    }
    .sjtb_box ul{
        padding: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .sjtb_box ul li{
        width: 19%;
        height: 150px;
        margin-right: 13px;
        margin-bottom: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 5px;
        transition: .5s;
        border: 1px solid #bcbcbc;
    }
    .sjtb_box ul li:hover {
        transition: .5s;
        box-shadow: 1px 1px 7px #BCBCBC;
    }
    .sjtb_box ul li:nth-child(1){
        margin-left: 0;
    }
    .sjtb_box ul li span{
        font-size: 20px;
        color: #5a5a5a;
    }
    .box {
        margin: 20px 0;
        display: flex;
        justify-content: space-around;
    }
    .box button {
        padding: 10px;
        border: none;
        cursor: pointer;
        color: #fff;
        outline: none;
        font-size: 14px;
        border-radius: 5px;
        background-color: #409eff;
    }
</style>
