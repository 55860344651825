<template>
    <div class="box">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm">
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="新闻标题" prop="title">
                        <el-input v-model="ruleForm.title"></el-input>
                    </el-form-item>
                    <el-form-item label="关键字" prop="keyWord">
                        <el-input v-model="ruleForm.keyWord"></el-input>
                    </el-form-item>
                    <el-form-item label="类别" prop="type">
                        <el-select v-model="ruleForm.type" class="width" placeholder="">
                            <el-option
                                v-for="item in options"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="摘要" prop="outline">
                        <el-input type="textarea" v-model="ruleForm.outline" class="widthd"></el-input>
                    </el-form-item>
                    <el-form-item label="浏览量" prop="views">
                        <el-input v-model="ruleForm.views" class="width"></el-input>
                    </el-form-item>
                    <el-form-item label="浏览量" prop="radio">
                        <el-radio v-model="ruleForm.radio" label="0">不推荐</el-radio>
                        <el-radio v-model="ruleForm.radio" label="1">推荐</el-radio>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-form-item label="小图" prop="smallPic">
                <publicpic @picUrl="smallurl" :propC="ruleForm.smallPic"></publicpic>
            </el-form-item>
            <el-form-item label="页面内容" prop="content">
                <editor v-model="ruleForm.content"></editor>
            </el-form-item>
        </el-form>
        <div class="footer">
            <el-button @click="$router.push({ name: 'Journalism' })">返回</el-button>
            <el-button type="primary" @click="update">提交</el-button>
        </div>
    </div>
</template>

<script>
import publicpic from '../../components/publicpic.vue'
import Editor from '../../components/imcoder-tinymce.vue'

export default {
    data() {
        return {
            id: this.$route.query.id ? this.$route.query.id : null,
            options: [],
            ruleForm: {
                title: '',
                type: '',
                outline: '',
                views: '0',
                smallPic: '',
                content: '',
                radio: '0',
                keyWord: ''
            },
            rules: {
                title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
                type: [{ required: true, message: '请选择类别', trigger: 'change' }],
                outline: [{ required: true, message: '请输入摘要', trigger: 'blur' }],
                content: [{ required: true, message: '请输入页面内容', trigger: 'blur' }],
                smallPic: [{ required: true, message: '请上传小图', trigger: 'blur' }],
                keyWord: [{ required: true, message: '请输入关键字', trigger: 'blur' }]
            },
            fileList: [],
            pic: ''
        }
    },
    components: {
        Editor,
        publicpic
    },
    created() {
        this.leibie()
    },
    methods: {
        smallurl(url) {
            this.ruleForm.smallPic = url
        },
        async leibie() {
            let res = await this.$http.post('/dict/getDict', {
                type: 'sys_news'
            })
            if(res.data.code == 200) {
                this.options = res.data.data
            }
        },
        update() {
            this.$refs.ruleForm.validate(async (valid) => {
                if (valid) {
                    let res = await this.$http.post('/news/saveOrUpdate', {
                        id: this.id ? this.id : null,
                        type: this.ruleForm.type,
                        content: this.ruleForm.content,
                        outline: this.ruleForm.outline,
                        smallPic: this.ruleForm.smallPic,
                        title: this.ruleForm.title,
                        views: this.ruleForm.views,
                        isRecommend: Number(this.ruleForm.radio),
                        keyWord: this.ruleForm.keyWord
                    })
                    if(res.data.code == 200) {
                        this.$message({
                            type: 'success',
                            message: this.id ? '编辑成功' : '添加成功',
                            center: true,
                            offset: 50
                        })
                        this.$router.push({ name: 'Journalism' })
                    }
                } else {
                    return false;
                }
            })
        }
    },
    watch: {
        id: {
            immediate: true,
            async handler(news, old) {
                if(news) {
                    let res = await this.$http.post('/news/list', {
                        id: news
                    })
                    if(res.data.code == 200) {
                        this.ruleForm.title = res.data.data[0].title
                        this.ruleForm.type = res.data.data[0].type
                        this.ruleForm.outline = res.data.data[0].outline
                        this.ruleForm.views = res.data.data[0].views
                        this.ruleForm.smallPic = res.data.data[0].smallPic
                        this.ruleForm.content = res.data.data[0].content
                        this.ruleForm.keyWord = res.data.data[0].keyWord
                        this.ruleForm.radio = res.data.data[0].isRecommend.toString()
                    }
                }
            }
        }
    }
}
</script>

<style scoped>
.box{
  padding: 10px;
  width: 100%;
  background-color: #fff;
}
.box>>>.ql-editor{
    height: 500px !important;
}
.width {
    width: 300px !important;
}
.footer {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
}
</style>