<template>
  <div class="box">
    <div class="search">
      <el-button type="primary" class="left" @click="feedback = true">增加</el-button>
    </div>
    <div class="table">
      <el-table
        ref="multipleTable"
        :data="tableData"
        border
        v-loading="loading"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="{ color: '#1c1f26', background: '#f6f6f6' }">
        <el-table-column prop="id" label="序号" width="80"></el-table-column>
        <el-table-column prop="websiteName" label="网站名称"></el-table-column>
        <el-table-column prop="sort" label="排序"></el-table-column>
        <el-table-column prop="website" label="网址" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="createTime" label="添加时间" show-overflow-tooltip> </el-table-column>
        <el-table-column label="操作">
            <template slot-scope="scope">
                <el-button size="mini" @click="handleEdit(scope.row.id, scope.row.websiteName, scope.row.website, scope.row.sort)">编辑</el-button>
                <el-button class="left" size="mini" type="danger" @click="del(scope.row.id)">删除</el-button>
            </template>
        </el-table-column>
      </el-table>
      <div class="fy">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          background
          layout="prev, pager, next"
          :total="total">
        </el-pagination>
      </div>
    </div>
    <!-- 增加类型 -->
    <el-dialog
      title="增加"
      center
      :close-on-click-modal="false"
      :show-close="false"
      :visible.sync="feedback"
      width="25%">
      <el-form ref="form" :model="form" :rules="rules" label-width="90px">
        <el-form-item label="网站名称" prop="websiteName">
          <el-input v-model="form.websiteName" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input v-model="form.sort" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="网址" prop="website">
          <el-input v-model="form.website" placeholder=""></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="feedback = false">取 消</el-button>
        <el-button type="primary" @click="feedbackAdd">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
        form: {
          websiteName: '',
          website: '',
          sort: ''
        },
        rules: {
          websiteName: [
            { required: true, message: '请输入网站名称', trigger: 'blur' }
          ],
          website: [
            { required: true, message: '请输入网址', trigger: 'blur' }
          ],
          sort: [
            { required: true, message: '请输排序', trigger: 'blur' }
          ]
        },
        loading: false,
        tableData: [],
        currentPage: 1,
        total: 0,
        Select: [],
        value: "",
        options: [],
        id: '',
        feedback: false,
        xqlist: []
    };
  },
  created() {
    this.list()
  },
  methods: {
    async list() {
      this.loading = true;
      let res = await this.$http.post("/links/list", {
        current: this.currentPage,
        size: 10
      });
      if (res.data.code == 200) {
        this.total = res.data.count
        this.tableData = res.data.data
      }
      this.loading = false;
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.list();
    },
    async handleEdit(id, name, link, sort) {
        this.id = id
        this.form.websiteName = name
        this.form.website = link
        this.form.sort = sort
        this.feedback = true
    },
    async del(id) {
        this.$confirm('是否删除此条数据？', '提醒', {
            distinguishCancelAndClose: true,
            confirmButtonText: '确认',
            cancelButtonText: '取消'
        }).then(async () => {
            let res = await this.$http.post('/links/delete', {
                ids: id
            })
            if(res.data.code == 200) {
                this.$message({
                    type: 'success',
                    message: '删除成功',
                    center: true,
                    offset: 50
                })
                this.list()
            }
        })
        .catch(action => {
            return false;
        })
    },
    feedbackAdd() {
        this.$refs.form.validate(async(valid) => {
            if (valid) {
                let res = await this.$http.post('/links/saveOrUpdate', {
                    id: this.id ? this.id : null,
                    websiteName: this.form.websiteName,
                    website: this.form.website,
                    sort: this.form.sort
                })
                if(res.data.code == 200) {
                    this.$message({
                        type: 'success',
                        message: this.id ? '编辑成功' : '添加成功',
                        center: true,
                        offset: 50
                    })
                    this.feedback = false
                    this.list()
                }
            } else {
                return false;
            }
        });
    }
  },
  watch: {
    feedback: {
      handler() {
        if(this.feedback == false) {
          this.$refs.form.resetFields()
          this.form.websiteName = ''
          this.form.website = ''
          this.form.sort = ''
          this.id = ''
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  padding: 10px;
  background-color: #fff;
}
</style>