<template>
  <div class="box">
    <div class="search">
      <el-input v-model="Switch.falbmc" class="width" clearable placeholder="方案类别名称"></el-input>
      <el-button type="primary" class="left" @click="list">查询</el-button>
      <el-button type="primary" class="left" @click="newsadd = true">增加</el-button>
    </div>
    <div class="table">
      <el-table
        :data="tableData"
        v-loading="loading"
        style="width: 100%;margin-bottom: 20px;"
        row-key="id"
        border
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
        <el-table-column prop="id" label="序号" width="80"></el-table-column>
        <el-table-column prop="scName" label="类别名称" show-overflow-tooltip></el-table-column>
        <el-table-column prop="sort" label="排序" show-overflow-tooltip></el-table-column>
        <el-table-column prop="createTime" label="创建时间" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.row.id)">编辑</el-button>
            <el-button class="left" size="mini" type="danger" @click="del(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="fy">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          background
          layout="prev, pager, next"
          :total="total">
        </el-pagination>
      </div>
    </div>
    <el-dialog
      :title="id ? '编辑' : '新增'"
      :show-close="false"
      :visible.sync="newsadd"
      :close-on-click-modal="false"
      width="25%"
      center>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="方案名称" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input v-model="ruleForm.sort"></el-input>
        </el-form-item>
        <el-form-item label="级别" prop="leve" style="display: none;">
          <el-select v-model="ruleForm.leve" class="widthd" placeholder="">
            <el-option label="父级" value="1"></el-option>
            <el-option label="子级" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="方案类别" prop="falb" v-if="ruleForm.leve == '2'">
          <el-select v-model="ruleForm.falb" class="widthd" placeholder="">
            <el-option v-for="item in faList" :key="item.id" :label="item.scName" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="newsadd = false">取 消</el-button>
        <el-button type="primary" @click="add">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      Switch: {
        falbmc: ''
      },
      ruleForm: {
        name: '',
        sort: '',
        leve: '',
        falb: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入方案名称', trigger: 'blur' }
        ],
        sort: [
          { required: true, message: '请输入排序', trigger: 'blur' }
        ],
        // leve: [
        //   { required: true, message: '请选择级别', trigger: 'change' }
        // ],
        falb: [
          { required: true, message: '请选择方案类别', trigger: 'change' }
        ]
      },
      id: null,
      loading: false,
      tableData: [],
      currentPage: 1,
      total: 0,
      faList: [],
      newsadd: false
    }
  },
  created() {
    this.list()
    this.falbc()
  },
  methods: {
    // 列表数据
    async list() {
      this.loading = true
      let res = await this.$http.post('/schemeCategory/getSchemeCategory', {
        scName: this.Switch.falbmc ? this.Switch.falbmc : null,
        current: this.currentPage,
        size: 10
      })
      this.loading = false
      if(res.data.code == 200) {
        this.tableData = res.data.data
        this.total = res.data.count
      }
    },
    // 方案类别数据
    async falbc() {
      let res = await this.$http.post('/schemeCategory/showSchemeCategory')
      if(res.data.code == 200) {
        this.faList = res.data.data
      }
    },
    async del(id) {
      this.$confirm('是否删除此条数据？', '提醒', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确认',
        cancelButtonText: '取消'
      }).then(async () => {
        let res = await this.$http.post('/schemeCategory/delete', {
          id: id
        })
        if(res.data.code == 200) {
          this.$message({
            type: 'success',
            message: '删除成功',
            center: true,
            offset: 50
          })
          this.list()
        }
      })
      .catch(action => {
        return
      })
    },
    async handleEdit(id) {
      this.id = id
      let res = await this.$http.post('/schemeCategory/getSchemeCategoryById', {
        id: id
      })
      if(res.data.code == 200) {
        this.ruleForm.name = res.data.data.scName
        this.ruleForm.sort = res.data.data.sort
        if(res.data.data.parentId) {
          this.ruleForm.leve = "2"
          this.ruleForm.falb = res.data.data.parentId
        }else{
          this.ruleForm.leve = "1"
          this.ruleForm.falb = null
        }
        this.newsadd = true
      }
    },
    add() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let res = await this.$http.post('/schemeCategory/saveOrUpdate', {
            id: this.id ? this.id : null,
            scName: this.ruleForm.name,
            parentId: this.ruleForm.falb ? this.ruleForm.falb : null,
            sort: this.ruleForm.sort
          })
          if(res.data.code == 200) {
            this.$message({
              type: 'success',
              message: this.id ? '编辑成功' : '添加成功',
              center: true,
              offset: 50
            })
            this.list()
            this.newsadd = false;
          }
        } else {
          return false;
        }
      })
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage
      this.list()
    }
  },
  watch: {
    newsadd: {
      handler(news, old) {
        if(news == false) {
          this.$refs.ruleForm.resetFields()
          this.ruleForm.name = ''
          this.ruleForm.sort = ''
          this.ruleForm.leve = ''
          this.ruleForm.falb = ''
          this.id = null
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.box{
  padding: 10px;
  background-color: #fff;
}
</style>