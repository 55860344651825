<template>
  <div class="box">
    <div class="search">
        <el-button type="primary" @click="neir = true">新增</el-button>
    </div>
    <div class="table">
      <el-table
        :data="tableData"
        v-loading="loading"
        border>
        <el-table-column prop="serialNumber" label="序号" width="90" show-overflow-tooltip></el-table-column>
        <el-table-column prop="title" label="图片名称备注" show-overflow-tooltip></el-table-column>
        <el-table-column prop="picturePath" label="图片路径" show-overflow-tooltip></el-table-column>
        <el-table-column prop="link" label="跳转链接" show-overflow-tooltip></el-table-column>
        <el-table-column prop="explain" label="图片alt说明" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.row.id)">编辑</el-button>
             <el-button class="left" size="mini" type="danger" @click="del(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="fy">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          background
          layout="prev, pager, next"
          :total="total">
        </el-pagination>
      </div>
    </div>
    <el-dialog
        :title="id ? '编辑' : '新增'"
        :show-close="false"
        :visible.sync="neir"
        :close-on-click-modal="false"
        width="25%"
        center>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm">
            <el-form-item label="图片名称" prop="title">
                <el-input v-model="ruleForm.title"></el-input>
            </el-form-item>
            <el-form-item label="序号" prop="serialNumber">
                <el-input v-model="ruleForm.serialNumber"></el-input>
            </el-form-item>
            <el-form-item label="跳转连接" prop="link">
                <el-input v-model="ruleForm.link"></el-input>
            </el-form-item>
            <el-form-item label="alt说明" prop="explain">
                <el-input v-model="ruleForm.explain"></el-input>
            </el-form-item>
            <el-form-item label="上传图片" prop="pic">
                <el-upload
                    action=""
                    :auto-upload="false"
                    ref="imgs"
                    v-model="ruleForm.pic"
                    list-type="picture-card"
                    :file-list="fileList"
                    :on-change="getFile"
                    :on-remove="handleRemove"
                    :limit="1">
                    <i class="el-icon-plus"></i>
                </el-upload>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="neir = false">取 消</el-button>
            <el-button type="primary" @click="update">确 定</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      Switch: {
        serialNumber: ''
      },
      ruleForm: {
        title: '',
        serialNumber: '',
        link: '',
        explain: '',
        pic: ''
      },
      rules: {
        title: [{ required: true, message: '请输入图片名称备注', trigger: 'blur' }],
        serialNumber: [{ required: true, message: '请输入序号', trigger: 'blur' }],
        // link: [{ required: true, message: '请输入跳转连接', trigger: 'blur' }],
        explain: [{ required: true, message: '请输入图片alt说明', trigger: 'blur' }],
        pic: [{ required: true, message: '请上传图片', trigger: 'blur' }]
      },
      khlist: [],
      fileList: [],
      id: null,
      neir: false,
      loading: false,
      tableData: [],
      currentPage: 1,
      total: 0
    }
  },
  created() {
    this.list()
  },
  methods: {
    // 列表数据
    async list() {
      this.loading = true
      let res = await this.$http.post('/banner/list', {
        current: this.currentPage,
        size: 10
      })
      this.loading = false
      if(res.data.code == 200) {
        this.tableData = res.data.data
        this.total = res.data.count
      }
    },
    getFile(file) {
        this.getBase64(file.raw).then((res) => {
            const params = res.split(',')
            this.ruleForm.pic = params[1]
            if (params.length > 0) {
                this.strimagebase64 = params[1]
            }
        })
    },
    handleRemove() {
        this.ruleForm.pic = ''
    },
    getBase64(file) {   // 公共方法 -> 获取图片转base64
      return new Promise(function (resolve, reject) {
        const reader = new FileReader()
        let imgResult = ''
        reader.readAsDataURL(file)
        reader.onload = function () {
          imgResult = reader.result
        }
        reader.onerror = function (error) {
          reject(error)
        }
        reader.onloadend = function () {
          resolve(imgResult)
        }
      })
    },
    async del(id) {
        this.$confirm('是否删除此条数据？', '提醒', {
            distinguishCancelAndClose: true,
            confirmButtonText: '确认',
            cancelButtonText: '取消'
        }).then(async () => {
            let res = await this.$http.post('/banner/delete', {
                id: id
            })
            if(res.data.code == 200) {
                this.$message({
                    type: 'success',
                    message: '删除成功',
                    center: true,
                    offset: 50
                })
                this.list()
            }
        })
        .catch(action => {
            return false;
        })
    },
    update() {
        this.$refs.ruleForm.validate(async (valid) => {
            if (valid) {
                let res = await this.$http.post('/banner/saveOrUpdate', {
                    id: this.id ? this.id : null,
                    explain: this.ruleForm.explain,
                    link: this.ruleForm.link,
                    picturePath: this.ruleForm.pic == 'S' ? null : this.ruleForm.pic,
                    serialNumber: this.ruleForm.serialNumber,
                    title: this.ruleForm.title
                })
                if(res.data.code == 200) {
                    this.$message({
                        type: 'success',
                        message: this.id ? '编辑成功' : '添加成功',
                        center: true,
                        offset: 50
                    })
                    this.list()
                    this.neir = false
                }
            } else {
                return false;
            }
        })
    },
    async handleEdit(id) {
        this.id = id
        this.fileList = []
        let res = await this.$http.post('/banner/list', {
            id: id,
            current: 1,
            size: 10
        })
        if(res.data.code == 200) {
            this.ruleForm.title = res.data.data[0].title
            this.ruleForm.serialNumber = res.data.data[0].serialNumber
            this.ruleForm.link = res.data.data[0].link
            this.ruleForm.explain = res.data.data[0].explain
            this.ruleForm.pic = 'S'
            const obj = {
                url: res.data.data[0].picturePath,
                name: 'img'
            }
            this.fileList.push(obj)
            this.neir = true
        }
    },
    add() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let res = await this.$http.post('/productCategory/saveOrUpdate', {
            id: this.id ? this.id : null,
            pcName: this.ruleForm.name,
            parentId: this.ruleForm.falb ? this.ruleForm.falb : null,
            outline: this.ruleForm.outline,
            sort: this.ruleForm.sort
          })
          if(res.data.code == 200) {
            this.$message({
              type: 'success',
              message: this.id ? '编辑成功' : '添加成功',
              center: true,
              offset: 50
            })
            this.list()
            this.newsadd = false;
          }
        } else {
          return false;
        }
      })
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage
      this.list()
    }
  },
  watch: {
    neir: {
      handler(news, old) {
        if(news == false) {
            this.$refs.imgs.clearFiles()
            this.$refs.ruleForm.resetFields()
            this.ruleForm.title = ''
            this.ruleForm.serialNumber = ''
            this.ruleForm.link = ''
            this.ruleForm.explain = ''
            this.ruleForm.pic = ''
            this.id = null
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.box{
  padding: 10px;
  background-color: #fff;
}
</style>