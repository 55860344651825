<template>
    <div class="box">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm">
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="产品名称" prop="productName">
                        <el-input v-model="ruleForm.productName"></el-input>
                    </el-form-item>
                    <el-form-item label="类别" prop="type">
                        <el-select v-model="ruleForm.type" placeholder="请选择类别">
                            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="关键字" prop="keyword">
                        <el-input v-model="ruleForm.keyword" placeholder="多个关键字请使用英文逗号隔开"></el-input>
                    </el-form-item>
                    <el-form-item label="摘要" prop="outline">
                        <el-input type="textarea" v-model="ruleForm.outline" class="widthd"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-form-item label="大图" prop="bigPic">
                <publicpic @picUrl="bigurl" :propC="ruleForm.bigPic"></publicpic>
            </el-form-item>
            <el-form-item label="小图" prop="smallPic">
                <publicpic @picUrl="smallurl" :propC="ruleForm.smallPic"></publicpic>
            </el-form-item>
            <el-form-item label="产品简介" prop="intro">
                <editor v-model="ruleForm.intro"></editor>
            </el-form-item>
        </el-form>
        <div class="footer">
            <el-button @click="$router.push({ name: 'product' })">返回</el-button>
            <el-button type="primary" @click="update">提交</el-button>
        </div>
    </div>
</template>

<script>
import publicpic from '../../components/publicpic.vue'
import Editor from '../../components/imcoder-tinymce.vue'

export default {
    data() {
        return {
            id: this.$route.query.id ? this.$route.query.id : null,
            options: [],
            ruleForm: {
                productName: '',
                type: '',
                outline: '',
                bigPic: '',
                smallPic: '',
                intro: '',
                keyword: ''
            },
            rules: {
                productName: [{ required: true, message: '请输入产品名称', trigger: 'blur' }],
                type: [{ required: true, message: '请选择产品类别', trigger: 'change' }],
                outline: [{ required: true, message: '请输入摘要', trigger: 'blur' }],
                bigPic: [{ required: true, message: '请选择大图', trigger: 'blur' }],
                smallPic: [{ required: true, message: '请选择小图', trigger: 'blur' }],
                intro: [{ required: true, message: '请输入产品简介', trigger: 'blur' }],
                keyword: [{ required: true, message: '请输入关键字', trigger: 'blur' }]
            },
            fileList: [],
            pic: ''
        }
    },
    components: {
        Editor,         //注册富文本组件
        publicpic
    },
    created() {
        this.leibie()
    },
    methods: {
        smallurl(url) {
            this.ruleForm.smallPic = url
        },
        bigurl(url) {
            this.ruleForm.bigPic = url
        },
        async leibie() {
            let res = await this.$http.get('/productCategory/showAllProductCategory')
            if(res.data.code == 200) {
                this.options = res.data.data
            }
        },
        update() {
            this.$refs.ruleForm.validate(async (valid) => {
                if (valid) {
                    let res = await this.$http.post('/product/saveOrUpdate', {
                        id: this.id ? this.id : null,
                        productName: this.ruleForm.productName,
                        type: this.ruleForm.type,
                        bigPic: this.ruleForm.bigPic,
                        smallPic: this.ruleForm.smallPic,
                        outline: this.ruleForm.outline,
                        intro: this.ruleForm.intro,
                        keyWord: this.ruleForm.keyword
                    })
                    if(res.data.code == 200) {
                        this.$message({
                            type: 'success',
                            message: this.id ? '编辑成功' : '添加成功',
                            center: true,
                            offset: 50
                        })
                        this.$router.push({ name: 'product' })
                    }
                } else {
                    return false;
                }
            })
        }
    },
    watch: {
        id: {
            immediate: true,
            async handler(news, old) {
                if(news) {
                    let res = await this.$http.post('/product/getProductById', {
                        id: news
                    })
                    if(res.data.code == 200) {
                        this.ruleForm.productName = res.data.data.productName
                        this.ruleForm.type = res.data.data.type
                        this.ruleForm.outline = res.data.data.outline
                        this.ruleForm.bigPic = res.data.data.bigPic
                        this.ruleForm.smallPic = res.data.data.smallPic
                        this.ruleForm.intro = res.data.data.intro
                        this.ruleForm.keyword = res.data.data.keyWord
                    }
                }
            }
        }
    }
}
</script>

<style scoped>
.box{
  padding: 10px;
  width: 100%;
  background-color: #fff;
}
.box>>>.ql-editor{
    height: 500px !important;
}
.width {
    width: 300px !important;
}
.footer {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
}
</style>