<template>
  <div class="box">
    <div class="search">
        <el-select v-model="Switch.id" clearable placeholder="请选择客户ID">
            <el-option
            v-for="item in khlist"
            :key="item.id"
            :label="item.name"
            :value="item.id">
            </el-option>
        </el-select>
        <el-date-picker
            v-model="Switch.time"
            class="left"
            type="daterange"
            format="yyyy年MM月dd日"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>
        <el-button type="primary" class="left" @click="list">查询</el-button>
        <el-button type="primary" class="left" @click="neir = true">新增</el-button>
    </div>
    <div class="table">
      <el-table
        :data="tableData"
        v-loading="loading"
        border>
        <el-table-column prop="id" label="序号" width="80"></el-table-column>
        <el-table-column prop="equipmentId" label="设备ID" show-overflow-tooltip></el-table-column>
        <el-table-column prop="equipmentModel" label="设备型号" show-overflow-tooltip></el-table-column>
        <el-table-column prop="signTime" label="签收时间" show-overflow-tooltip></el-table-column>
        <el-table-column prop="status" label="维修状态" show-overflow-tooltip>
            <template slot-scope="scope">
                <span v-if="scope.row.status == '0'">正常</span>
                <span v-else-if="scope.row.status == '1'">在维修</span>
            </template>
        </el-table-column>
        <el-table-column label="操作" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.row.id)">编辑</el-button>
             <el-button class="left" size="mini" type="danger" @click="del(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="fy">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          background
          layout="prev, pager, next"
          :total="total">
        </el-pagination>
      </div>
    </div>
    <el-dialog
        :title="id ? '编辑' : '新增'"
        :show-close="false"
        :visible.sync="neir"
        :close-on-click-modal="false"
        width="25%"
        center>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm">
            <el-form-item label="客户ID" prop="id">
                <el-select v-model="ruleForm.id" class="widthd" placeholder="">
                    <el-option
                    v-for="item in khlist"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="设备ID" prop="sbid">
                <el-input v-model="ruleForm.sbid"></el-input>
            </el-form-item>
            <el-form-item label="设备型号" prop="sbxh">
                <el-input v-model="ruleForm.sbxh"></el-input>
            </el-form-item>
            <el-form-item label="签收时间" prop="time">
                <el-date-picker v-model="ruleForm.time" class="widthd" type="date" placeholder="" format="yyyy年MM月dd日" value-format="yyyy-MM-dd">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="维修状态" prop="wxzd">
                <el-radio v-model="ruleForm.wxzd" label="0">正常</el-radio>
                <el-radio v-model="ruleForm.wxzd" label="1">在维修</el-radio>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="neir = false">取 消</el-button>
            <el-button type="primary" @click="update">确 定</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      Switch: {
        id: '',
        time: ''
      },
      ruleForm: {
        time: '',
        id: '',
        sbid: '',
        wxzd: '0',
        sbxh: ''
      },
      rules: {
        id: [{ required: true, message: '请选择客户ID', trigger: 'change' }],
        time: [{ required: true, message: '请选择签收时间', trigger: 'change' }],
        sbid: [{ required: true, message: '请输入设备ID', trigger: 'blur' }],
        sbxh: [{ required: true, message: '请输入设备ID', trigger: 'blur' }]
      },
      khlist: [],
      fileList: [],
      id: null,
      neir: false,
      loading: false,
      tableData: [],
      currentPage: 1,
      total: 0
    }
  },
  created() {
    this.list()
    this.keid()
  },
  methods: {
    // 列表数据
    async list() {
      this.loading = true
      let res = await this.$http.post('/equipment/getEquipment', {
        uId: this.Switch.id ? this.Switch.id : null,
        startTime: this.Switch.time ? this.Switch.time[0] : null,
        endTime: this.Switch.time ? this.Switch.time[1] : null,
        current: this.currentPage,
        size: 10
      })
      this.loading = false
      if(res.data.code == 200) {
        this.tableData = res.data.data
        this.total = res.data.count
      }
    },
    async keid() {
        let res = await this.$http.get('/customer/showCustomer')
        if(res.data.code == 200) {
            this.khlist = res.data.data
        }
    },
    getBase64(file) {   // 公共方法 -> 获取图片转base64
      return new Promise(function (resolve, reject) {
        const reader = new FileReader()
        let imgResult = ''
        reader.readAsDataURL(file)
        reader.onload = function () {
          imgResult = reader.result
        }
        reader.onerror = function (error) {
          reject(error)
        }
        reader.onloadend = function () {
          resolve(imgResult)
        }
      })
    },
    getFile(file) {
      this.getBase64(file.raw).then((res) => {
        const params = res.split(',')
        this.ruleForm.pic = params[1]
        if (params.length > 0) {
          this.strimagebase64 = params[1]
        }
      })
    },
    remove() {
      this.ruleForm.pic = ''
    },
    async del(id) {
        this.$confirm('是否删除此条数据？', '提醒', {
            distinguishCancelAndClose: true,
            confirmButtonText: '确认',
            cancelButtonText: '取消'
        }).then(async () => {
            let res = await this.$http.post('/equipment/delete', {
                id: id
            })
            if(res.data.code == 200) {
                this.$message({
                    type: 'success',
                    message: '删除成功',
                    center: true,
                    offset: 50
                })
                this.list()
            }
        })
        .catch(action => {
            return false;
        })
    },
    update() {
        this.$refs.ruleForm.validate(async (valid) => {
            if (valid) {
                let res = await this.$http.post('/equipment/saveOrUpdate', {
                    id: this.id ? this.id : null,
                    uId: this.ruleForm.id,
                    equipmentId: this.ruleForm.sbid,
                    equipmentModel: this.ruleForm.sbxh,
                    signTime: this.ruleForm.time,
                    status: this.ruleForm.wxzd
                })
                if(res.data.code == 200) {
                    this.$message({
                        type: 'success',
                        message: this.id ? '编辑成功' : '添加成功',
                        center: true,
                        offset: 50
                    })
                    this.list()
                    this.neir = false
                }
            } else {
                return false;
            }
        })
    },
    async handleEdit(id) {
        this.id = id
        this.fileList = []
        let res = await this.$http.post('/equipment/getEquipmentById', {
            id: id
        })
        if(res.data.code == 200) {
            this.ruleForm.time = res.data.data.signTime
            this.ruleForm.id = res.data.data.uid
            this.ruleForm.sbid = res.data.data.equipmentId
            this.ruleForm.wxzd = String(res.data.data.status)
            this.ruleForm.sbxh = res.data.data.equipmentModel
            this.neir = true
        }
    },
    add() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let res = await this.$http.post('/productCategory/saveOrUpdate', {
            id: this.id ? this.id : null,
            pcName: this.ruleForm.name,
            parentId: this.ruleForm.falb ? this.ruleForm.falb : null,
            outline: this.ruleForm.outline,
            sort: this.ruleForm.sort
          })
          if(res.data.code == 200) {
            this.$message({
              type: 'success',
              message: this.id ? '编辑成功' : '添加成功',
              center: true,
              offset: 50
            })
            this.list()
            this.newsadd = false;
          }
        } else {
          return false;
        }
      })
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage
      this.list()
    }
  },
  watch: {
    neir: {
      handler(news, old) {
        if(news == false) {
            this.$refs.ruleForm.resetFields()
            this.ruleForm.time = ''
            this.ruleForm.id = ''
            this.ruleForm.sbid = ''
            this.ruleForm.wxzd = '0'
            this.ruleForm.sbxh = ''
            this.id = null
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.box{
  padding: 10px;
  background-color: #fff;
}
</style>