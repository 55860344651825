<template>
  <div class="box">
    <div class="search">
      <el-select v-model="Switch.lx" class="width" clearable placeholder="类型">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
      <el-input v-model="Switch.title" class="width left" clearable placeholder="文件名"></el-input>
      <el-button type="primary" class="left" @click="list">查询</el-button>
      <el-button type="primary" class="left" @click="neir = true">新增</el-button>
      <el-button type="danger" class="left" @click="del">删除</el-button>
    </div>
    <div class="table">
      <el-table
        :data="tableData"
        v-loading="loading"
        @selection-change="handleSelectionChange"
        border>
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="id" label="序号" width="80"></el-table-column>
        <el-table-column prop="fileName" label="文件名" show-overflow-tooltip></el-table-column>
        <el-table-column prop="filePath" label="存储地址" show-overflow-tooltip></el-table-column>
        <el-table-column prop="type" label="类型" show-overflow-tooltip>
            <template slot-scope="scope">
                <span v-if="scope.row.type == 0">产品手册</span>
                <span v-if="scope.row.type == 1">用户操作手册</span>
                <span v-if="scope.row.type == 2">工具软件</span>
            </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" show-overflow-tooltip></el-table-column>
      </el-table>
      <div class="fy">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          background
          layout="prev, pager, next"
          :total="total">
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="文件上传"
      :visible.sync="neir"
      :close-on-click-modal="false"
      width="30%">
      <div class="boxs">
          <div class="update">
            <el-select v-model="value" class="bottom" placeholder="请选择分类">
                <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
            <el-upload
                class="upload-demo"
                drag
                ref="sc"
                action=""
                :on-exceed="overflow"
                :limit="1"
                :on-change="FileList"
                :auto-upload="false"
                multiple>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            </el-upload>
            <el-button type="primary" class="btn" @click="update">提交</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      Switch: {
        title: '',
        lx: ''
      },
      options: [
        {
            label: '产品手册',
            value: '0'
        },
        {
            label: '用户操作手册',
            value: '1'
        },
        {
            label: '工具软件',
            value: '2'
        }
      ],
      value: '',
      neir: false,
      centent: '',
      Select: [],
      loading: false,
      tableData: [],
      currentPage: 1,
      total: 0,
      file: {}
    }
  },
  created() {
    this.list()
  },
  methods: {
    async list() {  // 列表数据
      this.loading = true
      let res = await this.$http.post('/uploadFile/list', {
        type: this.Switch.lx.toString() ? this.Switch.lx.toString() : null,
        fileName: this.Switch.title ? this.Switch.title : null,
        current: this.currentPage,
        size: 10
      })
      this.loading = false
      if(res.data.code == 200) {
        this.tableData = res.data.data
        this.total = res.data.count
      }
    },
    overflow() {
        this.$message({
            type: 'warning',
            message: '一次最多只能上传一个文件',
            center: true,
            offset: 50
        })
    },
    async FileList(file, fileList) {
        this.file = file
    },
    async update() {
        if(this.file && this.value) {
            var formData = new FormData()
            formData.append("file", this.file.raw)
            formData.append("type", this.value)
            let res = await this.$http.post("/uploadFile/save", formData, {'Content-Type':'multipart/form-data'})
            if(res.data.code == 200) {
                this.$message({
                    type: 'success',
                    message: '上传成功',
                    center: true,
                    offset: 50
                })
                this.list()
                this.neir = false
            }
        }else{
            this.$message({
                type: 'warning',
                message: '请填写完整后再提交',
                center: true,
                offset: 50
            })
        }
    },
    handleSelectionChange(news) {
      var arr = []
      var newData
      var newData = news.map(item => {
        return item.id += arr
      })
      arr = newData;
      this.Select = arr;
    },
    open(centent) {
      this.centent = centent
      this.neir = true
    },
    async del() {
      if(this.Select.length > 0) {
        this.$confirm('是否删除此条数据？', '提醒', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确认',
          cancelButtonText: '取消'
        }).then(async () => {
          let res = await this.$http.get(`/uploadFile/delete?ids=${this.Select.join(',')}`)
          if(res.data.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功',
              center: true,
              offset: 50
            })
            this.list()
          }
        })
        .catch(action => {
          return
        })
      } else {
        this.$message({
          type: 'warning',
          message: '至少选择一项内容',
          center: true,
          offset: 50
        })
      }
    },
    add() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let res = await this.$http.post('/productCategory/saveOrUpdate', {
            id: this.id ? this.id : null,
            pcName: this.ruleForm.name,
            parentId: this.ruleForm.falb ? this.ruleForm.falb : null,
            outline: this.ruleForm.outline,
            sort: this.ruleForm.sort
          })
          if(res.data.code == 200) {
            this.$message({
              type: 'success',
              message: this.id ? '编辑成功' : '添加成功',
              center: true,
              offset: 50
            })
            this.list()
            this.newsadd = false;
          }
        } else {
          return false;
        }
      })
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage
      this.list()
    }
  },
  watch: {
    neir: {
        handler(news, old) {
            if(news == false) {
                this.$refs.sc.clearFiles()
                this.value = ''
                this.file = {}
            }
        }
    }
  }
}
</script>

<style lang="less" scoped>
.box{
  padding: 10px;
  background-color: #fff;
}
.boxs {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.update {
    width: 70%;
    text-align: center;
}
.btn {
    margin-top: 20px;
}
.bottom {
    width: 360px;
    margin-bottom: 20px;
}
</style>