<template>
  <div class="box">
    <div class="search">
        <el-input v-model="wygsmc" clearable class="width left" placeholder="物业/公司名称"></el-input>
        <el-button type="primary" class="left" @click="list">查询</el-button>
        <el-button type="primary" @click="xzcbl = true">新增部门</el-button>
    </div>
    <div class="table">
      <el-table
        ref="multipleTable"
        :data="tableData"
        border
        v-loading="loading"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="{ color: '#1c1f26', background: '#f6f6f6' }">
        <el-table-column
          label="编号">
          <template slot-scope="scope">{{ scope.row.dId }}</template>
        </el-table-column>
        <el-table-column
          prop="gsMc"
          label="公司名称">
        </el-table-column>
        <el-table-column
          prop="wyMc"
          label="小区物业处"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="dName"
          label="部门名称">
        </el-table-column>
        <el-table-column
          prop="addtime"
          label="创建日期">
        </el-table-column>
        <el-table-column
          label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="handleEdit(scope.row.dId)">编辑</el-button>
              <el-popconfirm title="确定删除吗？" @confirm="dele(scope.row.dId)">
                <el-button class="left" size="mini" type="danger" slot="reference">删除</el-button>
              </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <div class="fy">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          background
          layout="prev, pager, next"
          :total="total">
        </el-pagination>
      </div>
    </div>
    <!-- 新增部门 -->
    <el-dialog
      title="新增部门"
      :visible.sync="xzcbl"
      :close-on-click-modal="false"
      width="25%"
      center>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="公司名称" prop="xzgs">
          <el-select v-model="ruleForm.xzgs" class="widthd" @change="wysj" placeholder="">
            <el-option
              v-for="item in gsList"
              :key="item.wyId"
              :label="item.wyMc"
              :value="item.wyId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="物业名称" prop="xqwy">
          <el-select v-model="ruleForm.xqwy" class="widthd" placeholder="">
            <el-option
              v-for="item in wyList"
              :key="item.wyId"
              :label="item.wyMc"
              :value="item.wyId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="部门名称" class="widthd" prop="bmmc">
          <el-input v-model="ruleForm.bmmc"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="xzcbl = false">取 消</el-button>
        <el-button type="primary" @click="queding">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑部门 -->
    <el-dialog
      title="编辑部门"
      :visible.sync="bjbm"
      :close-on-click-modal="false"
      width="25%"
      center>
      <el-form :model="bjForm" :rules="bjrules" ref="bjForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="部门名称" class="widthd" prop="bmmc">
          <el-input v-model="bjForm.bmmc"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="bjbm = false">取 消</el-button>
        <el-button type="primary" @click="bjup">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
        loading: false,
        bjbm: false,
        gsList: [],
        wyList: [],
        did: '',
          wygsmc: '',
          tableData: [],
          currentPage: 1,
          pageSize: 10,
          total: 0,
          value: '',
          options: [],
          xzcbl: false,
          bjForm: { //编辑部门表单
            bmmc: ''
          },
          bjrules: {  //编辑表单验证
            bmmc: [
              { required: true, message: '请输入部门名称', trigger: 'blur' }
            ]
          },
          ruleForm: { //新增部门表单
            xzgs: '',
            xqwy: '',
            bmmc: ''
          },
          rules: {  //新增表单验证
            xzgs: [
              { required: true, message: '请选择小区', trigger: 'blur' }
            ],
            xqwy: [
              { required: true, message: '请选择小区物业', trigger: 'blur' }
            ],
            bmmc: [
              { required: true, message: '请输入部门名称', trigger: 'blur' }
            ]
          }
    }
  },
  created() {
    this.list()
    this.gswylist()
  },
  methods: {
    // 列表数据
    async list() {
        this.loading = true
        let res = await this.$http.post('/dc/getDepartment.do', {
            wyGsName: this.wygsmc,
            currPageNo: this.currentPage,
            pageSize: this.pageSize
        })
        if(res.data.code == 200) {
            this.tableData = res.data.data.dptInfo
            this.total = res.data.data.totalCount
        }
        this.loading = false
    },
    // 公司物业数据
    async gswylist() {
        let res = await this.$http.post('/dc/getGsWyInfo.do', {
            wyId: null
        })
        if(res.data.code == 200) {
            this.gsList = res.data.data
        }
    },
    // 新增物业数据
    async wysj() {
        this.ruleForm.xqwy = ''
        let res = await this.$http.post('/dc/getGsWyInfo.do', {
            gsId: this.ruleForm.xzgs == '' ? null : this.ruleForm.xzgs
        })
        if(res.data.code == 200) {
            this.wyList = res.data.data
        }
    },
    // 编辑物业数据
    async bjwysj() {
        this.bjForm.xqwy = ''
        let res = await this.$http.post('/dc/getGsWyInfo.do', {
            gsId: this.bjForm.xzgs == '' ? null : this.bjForm.xzgs
        })
        if(res.data.code == 200) {
            this.wyList = res.data.data
        }
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize
      this.list()
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage
      this.list()
    },
    // 新增提交
    queding() {
      this.$refs.ruleForm.validate(async(valid) => {
        if (valid) {
            let res = await this.$http.post('/dc/addDepartment.do', {
                dName: this.ruleForm.bmmc,
                wyId: this.ruleForm.xqwy
            })
            if(res.data.code == 200) {
                this.$message({
                    type: 'success',
                    message: '新增成功',
                    center: true,
                    offset: 50
                })
                this.xzcbl = false
                this.list()
            }
        }
      })
    },
    // 编辑
    async handleEdit(did) {
        this.did = did
        this.bjbm = true
        let res = await this.$http.post('/dc/getDepartment.do', {
            dId: did,
            currPageNo: 1,
            pageSize: 10
        })
        if(res.data.code == 200) {
            this.bjForm.bmmc = res.data.data.dptInfo[0].dName
        }
    },
    // 编辑提交
    bjup() {
        this.$refs.bjForm.validate(async(valid) => {
            if (valid) {
                let res = await this.$http.post('/dc/updDepartment.do', {
                    dId: this.did,
                    dName: this.bjForm.bmmc
                })
                if(res.data.code == 200) {
                    this.$message({
                        type: 'success',
                        message: '编辑成功',
                        center: true,
                        offset: 50
                    })
                    this.list()
                    this.bjbm = false
                }
            }
        })
    },
    // 删除
    async dele(did) {
        let res = await this.$http.post('/dc/delDepartment.do', {
            dId: did
        })
        if(res.data.code == 200) {
            this.$message({
                type: 'success',
                message: '删除成功',
                center: true,
                offset: 50
            })
            this.list()
        }
    }
  },
  watch: {
    xzcbl: {
      handler() {
        if(this.xzcbl == false) {
          this.$refs.ruleForm.resetFields()
        }
      }
    },
    bjbm:{
        handler() {
            if(this.bjbm == false) {
                this.$refs.bjForm.resetFields()
            }
        }
    }
  }
}
</script>

<style lang="less" scoped>
.box{
  padding: 10px;
}
</style>