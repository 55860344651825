<template>
  <div class="box">
    <div class="search">
        <el-input v-model="Switch.name" class="width" clearable placeholder="方案名称"></el-input>
        <el-button type="primary" class="left" @click="list">查询</el-button>
        <el-button type="primary" class="left" @click="$router.push({ name: 'jjfaadd' })">增加</el-button>
    </div>
    <div class="table">
      <el-table
        ref="multipleTable"
        :data="tableData"
        border
        v-loading="loading"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="{ color: '#1c1f26', background: '#f6f6f6' }">
        <el-table-column prop="id" label="序号" width="80"></el-table-column>
        <el-table-column prop="solutionName" label="解决方案名称"></el-table-column>
        <el-table-column prop="typeName" label="类别" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="outline" label="摘要" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="keyWord" label="关键字" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="isRecommend" label="是否推荐" show-overflow-tooltip>
            <template slot-scope="scope">
                <span v-if="scope.row.isRecommend == 0 && scope.row.isRecommend == ''">不推荐</span>
                <span v-else-if="scope.row.isRecommend == 1">推荐</span>
            </template>
        </el-table-column>
        <el-table-column label="操作">
            <template slot-scope="scope">
                <el-button size="mini" @click="handleEdit(scope.row.id)">编辑</el-button>
                <el-button class="left" size="mini" type="danger" @click="del(scope.row.id)">删除</el-button>
            </template>
        </el-table-column>
      </el-table>
      <div class="fy">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          background
          layout="prev, pager, next"
          :total="total">
        </el-pagination>
      </div>
    </div>
    <!-- 增加类型 -->
    <el-dialog
        title="增加"
        center
        :close-on-click-modal="false"
        :show-close="false"
        :visible.sync="feedback"
        width="25%">
        <el-form ref="form" :model="form" :rules="rules" label-width="90px">
            <el-form-item label="网站名称" prop="websiteName">
                <el-input v-model="form.websiteName" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="网址" prop="website">
                <el-input v-model="form.website" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="位置" prop="position">
                <el-select v-model="form.position" class="widthd" placeholder="">
                    <el-option label="首页" value="0"></el-option>
                    <el-option label="内页" value="1"></el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="feedback = false">取 消</el-button>
            <el-button type="primary" @click="feedbackAdd">确 定</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
        form: {
            websiteName: '',
            website: '',
            position: ''
        },
        rules: {
            websiteName: [
                { required: true, message: '请输入网站名称', trigger: 'blur' }
            ],
            website: [
                { required: true, message: '请输入网址', trigger: 'blur' }
            ],
            position: [
                { required: true, message: '请选择位置', trigger: 'change' }
            ]
        },
        Switch: {
            name: ''
        },
        loading: false,
        tableData: [],
        currentPage: 1,
        total: 0,
        Select: [],
        value: "",
        options: [],
        id: '',
        feedback: false,
        xqlist: []
    };
  },
  created() {
    this.list()
  },
  methods: {
    async list() {
      this.loading = true;
      let res = await this.$http.post("/scheme/list", {
        solutionName: this.Switch.name,
        current: this.currentPage,
        size: 10
      });
      if (res.data.code == 200) {
        this.total = res.data.count
        this.tableData = res.data.data
      }
      this.loading = false;
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.list();
    },
    handleEdit(id) {
        this.$router.push({ name: 'jjfaadd', query: { id: id } })
    },
    async del(id) {
        this.$confirm('是否删除此条数据？', '提醒', {
            distinguishCancelAndClose: true,
            confirmButtonText: '确认',
            cancelButtonText: '取消'
        }).then(async () => {
            let res = await this.$http.post('/scheme/delete', {
                ids: id
            })
            if(res.data.code == 200) {
                this.$message({
                    type: 'success',
                    message: '删除成功',
                    center: true,
                    offset: 50
                })
                this.list()
            }
        })
        .catch(action => {
            return false;
        })
    },
    feedbackAdd() {
        this.$refs.form.validate(async(valid) => {
            if (valid) {
                let res = await this.$http.post('/links/saveOrUpdate', {
                    id: this.id ? this.id : null,
                    websiteName: this.form.websiteName,
                    website: this.form.website,
                    position: this.form.position
                })
                if(res.data.code == 200) {
                    this.$message({
                        type: 'success',
                        message: this.id ? '编辑成功' : '添加成功',
                        center: true,
                        offset: 50
                    })
                    this.feedback = false
                    this.list()
                }
            } else {
                return false;
            }
        });
    }
  },
  watch: {
    feedback: {
        handler() {
            if(this.feedback == false) {
                this.$refs.form.resetFields()
                this.form.websiteName = ''
                this.form.website = ''
                this.form.position = ''
                this.id = ''
            }
        }
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  padding: 10px;
  background-color: #fff;
}
.width {
    width: 230px !important;
}
</style>