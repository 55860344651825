<template>
  <div class="box">
    <div class="search">
        <el-input placeholder="产品名称" class="width" v-model="Switch.productName"></el-input>
        <el-select v-model="Switch.type" clearable class="left" placeholder="产品类别">
          <el-option v-for="item in leibie" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-select v-model="Switch.sort" class="left" clearable placeholder="排序方式">
            <el-option label="按添加时间排序" value="1"></el-option>
            <el-option label="按点击数的升序排序" value="2"></el-option>
            <el-option label="按点击数的降序排序" value="3"></el-option>
        </el-select>
        <el-button type="primary" class="left" @click="list">查询</el-button>
        <el-button type="primary" @click="$router.push({ name: 'productadd' })" class="left">新增</el-button>
    </div>
    <div class="table">
      <el-table
        :data="tableData"
        v-loading="loading"
        border>
        <el-table-column prop="id" label="序号" width="80"></el-table-column>
        <el-table-column prop="productName" label="产品名称" show-overflow-tooltip></el-table-column>
        <el-table-column prop="pcName" label="类别" show-overflow-tooltip></el-table-column>
        <el-table-column prop="outline" label="摘要" show-overflow-tooltip></el-table-column>
        <el-table-column prop="point" label="点击数" show-overflow-tooltip></el-table-column>
        <el-table-column prop="keyWord" label="关键字" show-overflow-tooltip></el-table-column>
        <el-table-column prop="updateTime" label="更新时间" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" show-overflow-tooltip>
            <template slot-scope="scope">
                <el-button size="mini" @click="handleEdit(scope.row.id)">编辑</el-button>
                <el-button class="left" size="mini" type="danger" @click="del(scope.row.id)">删除</el-button>
            </template>
        </el-table-column>
      </el-table>
      <div class="fy">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          background
          layout="prev, pager, next"
          :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      Switch: {
        productName: '',
        type: '',
        sort: ''
      },
      leibie: [],
      loading: false,
      tableData: [],
      currentPage: 1,
      total: 0
    }
  },
  created() {
    this.list()
    this.leibies()
  },
  methods: {
    // 列表数据
    async list() {
      this.loading = true
      let res = await this.$http.post('/product/getProduct', {
        productName: this.Switch.productName ? this.Switch.productName : null,
        type: this.Switch.type ? this.Switch.type : null,
        sort: this.Switch.sort ? this.Switch.sort : null,
        current: this.currentPage,
        size: 10
      })
      this.loading = false
      if(res.data.code == 200) {
        this.tableData = res.data.data
        this.total = res.data.count
      }
    },
    async leibies() {
        let res = await this.$http.get('/productCategory/showAllProductCategory')
        if(res.data.code == 200) {
            this.leibie = res.data.data
        }
    },
    async del(id) {
        this.$confirm('是否删除此条数据？', '提醒', {
            distinguishCancelAndClose: true,
            confirmButtonText: '确认',
            cancelButtonText: '取消'
        }).then(async () => {
            let res = await this.$http.post('/product/delete', {
              id: id
            })
            if(res.data.code == 200) {
                this.$message({
                    type: 'success',
                    message: '删除成功',
                    center: true,
                    offset: 50
                })
                this.list()
            }
        })
        .catch(action => {
            return false;
        })
    },
    async handleEdit(id) {
        this.$router.push({ name: 'productadd', query: { id: id } })
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage
      this.list()
    }
  }
}
</script>

<style lang="less" scoped>
.box{
  padding: 10px;
  background-color: #fff;
}
.width {
    width: 250px !important;
}
</style>