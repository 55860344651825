<template>
  <div class="box">
    <div class="search">
        <el-input v-model="bmmc" class="width" clearable placeholder="部门名称"></el-input>
        <el-input v-model="gwmc" class="width left" clearable placeholder="岗位名称"></el-input>
        <el-button type="primary" class="left" @click="list">查询</el-button>
        <el-button type="primary" @click="xzcbl = true">新增岗位</el-button>
    </div>
    <div class="table">
      <el-table
        ref="multipleTable"
        :data="tableData"
        border
        v-loading="loading"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="{ color: '#1c1f26', background: '#f6f6f6' }">
        <el-table-column label="编号">
          <template slot-scope="scope">{{ scope.row.gwId }}</template>
        </el-table-column>
        <el-table-column prop="gwbm" label="岗位编码"></el-table-column>
        <el-table-column prop="gwmc" label="岗位名称" show-overflow-tooltip></el-table-column>
        <el-table-column label="物业 / 部门" show-overflow-tooltip>
            <template slot-scope="scope">
                {{ scope.row.wyMc }} / {{ scope.row.dName }}
            </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="handleEdit(scope.row.gwId)">编辑</el-button>
              <el-popconfirm title="确定删除吗？" @confirm="dele(scope.row.gwId)">
                <el-button class="left" size="mini" type="danger" slot="reference">删除</el-button>
              </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <div class="fy">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          background
          layout="prev, pager, next"
          :total="total">
        </el-pagination>
      </div>
    </div>
    <!-- 新增岗位 -->
    <el-dialog
      title="新增岗位"
      :visible.sync="xzcbl"
      :close-on-click-modal="false"
      width="25%"
      center>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="公司名称" prop="xzgs">
          <el-select v-model="ruleForm.xzgs" @change="wysj" class="widthd" placeholder="">
            <el-option
              v-for="item in gsList"
              :key="item.wyId"
              :label="item.wyMc"
              :value="item.wyId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="物业名称" prop="xqwy">
          <el-select v-model="ruleForm.xqwy" @change="bmsj" class="widthd" placeholder="">
            <el-option
              v-for="item in wyList"
              :key="item.wyId"
              :label="item.wyMc"
              :value="item.wyId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="部门名称" class="widthd" prop="bm">
          <el-select v-model="ruleForm.bm" class="widthd" placeholder="">
            <el-option
              v-for="item in bmList"
              :key="item.dId"
              :label="item.dName"
              :value="item.dId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="岗位编码" prop="gwbm">
          <el-input disabled v-model="ruleForm.gwbm"></el-input>
        </el-form-item>
        <el-form-item label="岗位名称" prop="gwmc">
          <el-input v-model="ruleForm.gwmc"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="xzcbl = false">取 消</el-button>
        <el-button type="primary" @click="queding">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑岗位 -->
    <el-dialog
      title="编辑"
      :visible.sync="bjgw"
      :close-on-click-modal="false"
      width="25%"
      center>
      <el-form :model="bjForm" :rules="bjrules" ref="bjForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="岗位名称" prop="gwmc">
          <el-input v-model="bjForm.gwmc"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="bjgw = false">取 消</el-button>
        <el-button type="primary" @click="bjUp">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
        loading: false,
        bjgw: false,
        gsList: [],
        wyList: [],
        bmList: [],
        gwId: '',
      bmmc: '',
      gwmc: '',
      wygsmc: '',
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      value: '',
      options: [],
      xzcbl: false,
      bjForm: {
        gwmc: ''
      },
      bjrules: {
        gwmc: [
          { required: true, message: '请输入岗位名称', trigger: 'blur' }
        ]
      },
      ruleForm: {
        xzgs: '',
        xqwy: '',
        bm: '',
        gwbm: '',
        gwmc: ''
      },
      rules: {
        xzgs: [
          { required: true, message: '请选择小区', trigger: 'blur' }
        ],
        xqwy: [
          { required: true, message: '请选择小区物业', trigger: 'blur' }
        ],
        bm: [
          { required: true, message: '请选择部门', trigger: 'blur' }
        ],
        gwbm: [
          { required: true, message: '请输入岗位编码', trigger: 'blur' }
        ],
        gwmc: [
          { required: true, message: '请输入岗位名称', trigger: 'blur' }
        ],
      }
    }
  },
  created() {
      this.list()
      this.gslist()
  },
  methods: {
    // 列表数据
    async list() {
        this.loading = true
        let res = await this.$http.post('/gc/getGwInfo.do', {
            dName: this.bmmc,
            gwName: this.gwmc,
            currPageNo: this.currentPage,
            pageSize: this.pageSize
        })
        if(res.data.code == 200) {
            this.tableData = res.data.data.gwInfo
            this.total = res.data.data.totalCount
        }
        this.loading = false
    },
    // 公司数据
    async gslist() {
        let res = await this.$http.post('/dc/getGsWyInfo.do', {
            wyId: null
        })
        if(res.data.code == 200) {
            this.gsList = res.data.data
        }
    },
    // 物业数据
    async wysj() {
        this.ruleForm.xqwy = ''
        this.ruleForm.bm = ''
        let res = await this.$http.post('/dc/getGsWyInfo.do', {
            gsId: this.ruleForm.xzgs == '' ? null : this.ruleForm.xzgs
        })
        if(res.data.code == 200) {
            this.wyList = res.data.data
        }
    },
    //部门数据
    async bmsj() {
        this.ruleForm.bm = ''
        let res = await this.$http.post('/dc/getDepartment.do', {
            wyId: this.ruleForm.xqwy
        })
        if(res.data.code == 200) {
            this.bmList = res.data.data.dptInfo
        }
    },
    // 岗位编码
    async gwbmNumber() {
        let res = await this.$http.post('/gc/getGwbm.do')
        if(res.data.code == 200) {
            this.ruleForm.gwbm = res.data.data.gwBm
        }
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize
      this.list()
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage
      this.list()
    },
    // 点击编辑
    async handleEdit(gwid) {
        this.gwId = gwid
        this.bjgw = true
        let res = await this.$http.post('/gc/getGwInfo.do', {
            gwId: gwid
        })
        if(res.data.code == 200) {
            this.bjForm.gwmc = res.data.data.gwInfo[0].gwmc
        }
    },
    // 新增提交
    queding() {
      this.$refs.ruleForm.validate(async(valid) => {
        if (valid) {
            let res = await this.$http.post('/gc/insGw.do', {
                gwbm: this.ruleForm.gwbm,
                gwmc: this.ruleForm.gwmc,
                dId: this.ruleForm.bm
            })
            if(res.data.code == 200) {
                this.$message({
                    type: 'success',
                    message: '新增成功',
                    center: true,
                    offset: 50
                })
                this.list()
                this.xzcbl = false
            }
        }
      })
    },
    // 编辑提交
    bjUp() {
        this.$refs.bjForm.validate(async(valid) => {
              if (valid) {
                  let res = await this.$http.post('/gc/updGw.do', {
                      gwMc: this.bjForm.gwmc,
                      gwId: this.gwId
                  })
                  if(res.data.code == 200) {
                      this.$message({
                          type: 'success',
                          message: '编辑成功',
                          center: true,
                          offset: 50
                      })
                      this.list()
                      this.bjgw = false
                  }
              }
          })
    },
    // 删除
    async dele(gwid) {
        let res = await this.$http.post('/gc/delGw.do', {
            gwId: gwid
        })
        if(res.data.code == 200) {
            this.$message({
                type: 'success',
                message: '删除成功',
                center: true,
                offset: 50
            })
            this.list()
        }
    }
  },
  watch: {
    xzcbl: {
      handler() {
        if(this.xzcbl == false) {
          this.$refs.ruleForm.resetFields()
        }else{
            this.gwbmNumber()
        }
      }
    },
    bjgw: {
        handler() {
              if(this.bjgw == false) {
                this.$refs.bjForm.resetFields()
              }
        }
    }
  }
}
</script>

<style lang="less" scoped>
.box{
  padding: 10px;
}
</style>