<template>
  <div class="box">
    <div class="search">
      <el-input v-model="Switch.name" class="width" clearable placeholder="字典名称"></el-input>
      <el-select v-model="Switch.lx" class="width left" clearable placeholder="字典类型">
        <el-option v-for="item in lxList" :key="item.type" :label="item.name" :value="item.type"></el-option>
      </el-select>
      <el-input v-model="Switch.zdz" class="width left" clearable placeholder="字典值"></el-input>
      <el-button type="primary" class="left" @click="list">查询</el-button>
      <el-button type="primary" class="left" @click="feedback = true">增加</el-button>
    </div>
    <div class="table">
      <el-table
        ref="multipleTable"
        :data="tableData"
        border
        v-loading="loading"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="{ color: '#1c1f26', background: '#f6f6f6' }">
        <el-table-column prop="id" label="序号" width="80"></el-table-column>
        <el-table-column prop="name" label="字典名称"> </el-table-column>
        <el-table-column prop="value" label="字典值"> </el-table-column>
        <el-table-column prop="type" label="字典类型"> </el-table-column>
        <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button size="mini" @click="handleEdit(scope.row.id)">编辑</el-button>
              <el-button class="left" size="mini" type="danger" @click="del(scope.row.id)">删除</el-button>
            </template>
        </el-table-column>
      </el-table>
      <div class="fy">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          background
          layout="prev, pager, next"
          :total="total">
        </el-pagination>
      </div>
    </div>
    <!-- 新增 -->
    <el-dialog
        :title="id ? '编辑' : '增加'"
        center
        :show-close="false"
        :close-on-click-modal="false"
        :visible.sync="feedback"
        width="25%">
        <el-form ref="form" :model="form" :rules="rules" label-width="80px">
          <el-form-item label="字典名称" prop="name">
            <el-input type="text" v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="字典值" prop="zdz">
            <el-input type="text" v-model="form.zdz"></el-input>
          </el-form-item>
          <el-form-item label="类型" prop="lx">
            <el-select v-model="form.lx" class="widthd" placeholder="">
              <el-option v-for="item in lxList" :key="item.type" :label="item.name" :value="item.type"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="feedback = false">取 消</el-button>
          <el-button type="primary" @click="feedbackAdd">确 定</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      Switch: {
        name: '',
        zdlx: '',
        zdz: ''
      },
      form: {
        name: '',
        zdz: '',
        lx: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入字典名称', trigger: 'blur' }
        ],
        // zdz: [
        //   { required: true, message: '请输入字典值', trigger: 'blur' }
        // ],
        lx: [
          { required: true, message: '请选择类型', trigger: 'change' }
        ]
      },
      lxList: [],
      loading: false,
      yhmxm: "",
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      Select: [],
      value: "",
      options: [],
      id: '',
      feedback: false
    };
  },
  created() {
    this.list()
    this.lxsj()
  },
  methods: {
    async list() {
      this.loading = true
      let res = await this.$http.post("/dict/getDict", {
        current: this.currentPage,
        size: 10,
        name: this.Switch.name ? this.Switch.name : null,
        type: this.Switch.zdlx ? this.Switch.zdlx : null,
        value: this.Switch.zdz ? this.Switch.zdz : null
      });
      this.loading = false
      if (res.data.code == 200) {
        this.tableData = res.data.data
        this.total = res.data.count
      }
    },
    async lxsj() {
      let res = await this.$http.get('/dictType/showDictType')
      if(res.data.code == 200) {
        this.lxList = res.data.data
      }
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.list();
    },
    async handleEdit(id) {
      this.id = id
      let res = await this.$http.post('/dict/getDictById', {
        id: id
      })
      if(res.data.code == 200) {
        this.form.name = res.data.data.name
        this.form.zdz = res.data.data.value
        this.form.lx = res.data.data.type
        this.feedback = true
      }
    },
    del(id) {
      this.$confirm('是否删除此条数据？', '提醒', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确认',
        cancelButtonText: '取消'
      }).then(async () => {
        let res = await this.$http.post('/dict/delete', {
          id: id
        })
        if(res.data.code == 200) {
          this.$message({
            type: 'success',
            message: '删除成功',
            center: true,
            offset: 50
          })
          this.list()
        }
      })
      .catch(action => {
        return false;
      })
    },
    feedbackAdd() {
      this.$refs.form.validate(async(valid) => {
        if (valid) {
          let res = await this.$http.post('/dict/saveOrUpdate', {
            id: this.id ? this.id : null,
            name: this.form.name,
            type: this.form.lx,
            value: this.form.zdz ? this.form.zdz : null
          })
          if(res.data.code == 200) {
            this.$message({
              type: 'success',
              message: this.id ? '编辑成功' : '增加成功',
              center: true,
              offset: 50
            })
            this.list()
            this.feedback = false
          }
        } else {
          return false;
        }
      })
    }
  },
  watch: {
    feedback: {
      handler() {
        if(this.feedback == false) {
          this.$refs.form.resetFields();
          this.form.name = ""
          this.form.zdz = ""
          this.form.lx = ""
          this.id = null
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  padding: 10px;
  background-color: #fff;
}
</style>